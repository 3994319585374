import {
  fetchOrganizationProfiles,
  fetchSelectedOrganization,
} from '../../ducks/organizations.duck';
import { storableError } from '../../util/errors';
import config from '../../config';

// ================ Action types ================ //

export const FETCH_ORGANIZATION_PROFILE_COMPLETED_REQUEST =
  'app/GettingStartedPage/FETCH_ORGANIZATION_PROFILE_COMPLETED_REQUEST';
export const FETCH_ORGANIZATION_PROFILE_COMPLETED_SUCCESS =
  'app/GettingStartedPage/FETCH_ORGANIZATION_PROFILE_COMPLETED_SUCCESS';
export const FETCH_ORGANIZATION_PROFILE_COMPLETED_ERROR =
  'app/GettingStartedPage/FETCH_ORGANIZATION_PROFILE_COMPLETED_ERROR';

export const FETCH_LISTING_COMPLETED_REQUEST =
  'app/GettingStartedPage/FETCH_LISTING_COMPLETED_REQUEST';
export const FETCH_LISTING_COMPLETED_SUCCESS =
  'app/GettingStartedPage/FETCH_LISTING_COMPLETED_SUCCESS';
export const FETCH_LISTING_COMPLETED_ERROR = 'app/GettingStartedPage/FETCH_LISTING_COMPLETED_ERROR';

export const FETCH_LIVE_ROOM_COMPLETED_REQUEST =
  'app/GettingStartedPage/FETCH_LIVE_ROOM_COMPLETED_REQUEST';
export const FETCH_LIVE_ROOM_COMPLETED_SUCCESS =
  'app/GettingStartedPage/FETCH_LIVE_ROOM_COMPLETED_SUCCESS';
export const FETCH_LIVE_ROOM_COMPLETED_ERROR =
  'app/GettingStartedPage/FETCH_LIVE_ROOM_COMPLETED_ERROR';

// ================ Reducer ================ //

const initialState = {
  tasks: {
    organizationProfileTask: {
      completed: false,
      inProgress: false,
      error: null,
    },
    showPageTask: {
      completed: false,
      inProgress: false,
      error: null,
    },
    liveRoomTask: {
      completed: false,
      inProgress: false,
      error: null,
    },
  },
};

export default function gettingStartedPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORGANIZATION_PROFILE_COMPLETED_REQUEST:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          organizationProfileTask: {
            completed: false,
            inProgress: true,
            error: null,
          },
        },
      };
    case FETCH_ORGANIZATION_PROFILE_COMPLETED_SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          organizationProfileTask: {
            completed: payload.completed,
            inProgress: false,
            error: null,
          },
        },
      };
    case FETCH_ORGANIZATION_PROFILE_COMPLETED_ERROR:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          organizationProfileTask: {
            completed: payload.completed,
            inProgress: false,
            error: payload.error,
          },
        },
      };

    case FETCH_LISTING_COMPLETED_REQUEST:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          showPageTask: {
            completed: false,
            inProgress: true,
            error: null,
          },
        },
      };
    case FETCH_LISTING_COMPLETED_SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          showPageTask: {
            completed: payload.completed,
            inProgress: false,
            error: null,
          },
        },
      };
    case FETCH_LISTING_COMPLETED_ERROR:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          showPageTask: {
            completed: payload.completed,
            inProgress: false,
            error: payload.error,
          },
        },
      };

    case FETCH_LIVE_ROOM_COMPLETED_REQUEST:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          liveRoomTask: {
            completed: false,
            inProgress: true,
            error: null,
          },
        },
      };
    case FETCH_LIVE_ROOM_COMPLETED_SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          liveRoomTask: {
            completed: payload.completed,
            inProgress: false,
            error: null,
          },
        },
      };
    case FETCH_LIVE_ROOM_COMPLETED_ERROR:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          liveRoomTask: {
            completed: payload.completed,
            inProgress: false,
            error: payload.error,
          },
        },
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchOrganizationProfileCompletedRequest = () => ({
  type: FETCH_ORGANIZATION_PROFILE_COMPLETED_REQUEST,
});
export const fetchOrganizationProfileCompletedSuccess = isCompleted => ({
  type: FETCH_ORGANIZATION_PROFILE_COMPLETED_SUCCESS,
  payload: {
    completed: isCompleted,
  },
});
export const fetchOrganizationProfileCompletedError = e => ({
  type: FETCH_ORGANIZATION_PROFILE_COMPLETED_ERROR,
  error: true,
  payload: e,
});

export const fetchListingCompletedRequest = () => ({
  type: FETCH_LISTING_COMPLETED_REQUEST,
});
export const fetchListingCompletedSuccess = isCompleted => ({
  type: FETCH_LISTING_COMPLETED_SUCCESS,
  payload: {
    completed: isCompleted,
  },
});
export const fetchListingCompletedError = e => ({
  type: FETCH_LISTING_COMPLETED_ERROR,
  error: true,
  payload: e,
});

export const fetchLiveRoomCompletedRequest = () => ({
  type: FETCH_LIVE_ROOM_COMPLETED_REQUEST,
});
export const fetchLiveRoomCompletedSuccess = isCompleted => ({
  type: FETCH_LIVE_ROOM_COMPLETED_SUCCESS,
  payload: {
    completed: isCompleted,
  },
});
export const fetchLiveRoomCompletedError = e => ({
  type: FETCH_LIVE_ROOM_COMPLETED_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const isOrganizationProfileEdited = profile => {
  // Check if the profile only has `id` and `name` attributes
  const allowedKeys = ['id', 'name'];
  const keys = Object.keys(profile);

  const hasExtraFields = keys.some(key => !allowedKeys.includes(key));
  const isDefaultName = profile.name === 'New project';

  // Return true if extra fields are found or the name is not "New project"
  return hasExtraFields || !isDefaultName;
};

export const fetchOrganizationProfileCompleted = () => async (dispatch, getState, sdk) => {
  try {
    const organizationProfiles = await dispatch(fetchOrganizationProfiles());

    const isCompleted = (() => {
      if (!organizationProfiles || organizationProfiles.length === 0) {
        return false;
      }

      if (organizationProfiles.length === 1) {
        const profile = organizationProfiles[0];
        const allowedKeys = ['id', 'name'];
        const hasExtraFields = Object.keys(profile).some(key => !allowedKeys.includes(key));
        const isDefaultName = profile.name === 'New project';
        return hasExtraFields || !isDefaultName;
      }

      return true;
    })();

    return dispatch(fetchOrganizationProfileCompletedSuccess(isCompleted));
  } catch (e) {
    dispatch(fetchOrganizationProfileCompletedError(storableError(e)));
  }
};

export const fetchListingCompleted = selectedOrganization => async (dispatch, getState, sdk) => {
  try {
    const listingsResponse = await sdk.listings.query({
      pub_type: config.listingTypes['show'],
      pub_organizationId: selectedOrganization ? selectedOrganization.id : '',
    });
    const listings = listingsResponse.data.data;
    const isCompleted = listings?.length > 0;

    return dispatch(fetchListingCompletedSuccess(isCompleted));
  } catch (e) {
    dispatch(fetchListingCompletedError(storableError(e)));
  }
};

export const fetchLiveRoomCompleted = selectedOrganization => async (dispatch, getState, sdk) => {
  try {
    const listingsResponse = await sdk.listings.query({
      pub_type: config.listingTypes['experience'],
      pub_organizationId: selectedOrganization ? selectedOrganization.id : '',
    });
    const listings = listingsResponse.data.data;
    const isCompleted = listings?.length > 0;

    return dispatch(fetchLiveRoomCompletedSuccess(isCompleted));
  } catch (e) {
    dispatch(fetchLiveRoomCompletedError(storableError(e)));
  }
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  Promise.all([
    dispatch(fetchOrganizationProfileCompletedRequest()),
    dispatch(fetchListingCompletedRequest()),
    dispatch(fetchLiveRoomCompletedRequest()),
  ]);

  const selectedOrganization = await dispatch(fetchSelectedOrganization());

  return Promise.all([
    dispatch(fetchOrganizationProfileCompleted()),
    dispatch(fetchListingCompleted(selectedOrganization)),
    dispatch(fetchLiveRoomCompleted(selectedOrganization)),
  ]);
};
