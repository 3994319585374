import pick from 'lodash/pick';
import config from '../../config';
import { types as sdkTypes, util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { integrationAPI } from '../../util/api';
import { denormalisedResponseEntities, isUserInvitedToOrganization } from '../../util/data';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { showOrganizationProfile } from '../OrganizationProfilePage/OrganizationProfilePage.duck';
import { fetchOrganizationProfiles } from '../../ducks/organizations.duck';
import { queryRecentlyViewedListings, saveRecentlyViewedListingId } from '../../ducks/user.duck';
import { fetchReviews } from '../ReviewsPage/ReviewsPage.duck';

const { UUID } = sdkTypes;

export const listingsIncludeParams = {
  include: ['author', 'images'],
  'fields.listing': [
    'title',
    'description',
    'geolocation',
    'price',
    'state',
    'publicData',
    'metadata',
  ],
  'fields.image': [
    // Listing page
    'variants.landscape-crop',
    'variants.landscape-crop2x',
    'variants.landscape-crop4x',
    'variants.landscape-crop6x',
    'variants.hero-scale',
    'variants.hero-crop',
    'variants.portrait-crop',
    'variants.portrait-crop2x',
    'variants.portrait-crop4x',

    // Social media
    'variants.facebook',
    'variants.twitter',

    // Image carousel
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',

    // Avatars
    'variants.square-small',
    'variants.square-small2x',
  ],
  'imageVariant.hero-scale': 'w:1440;h:400;fit:scale',
  'imageVariant.hero-crop': 'w:1440;h:400;fit:crop',
  'imageVariant.portrait-crop': sdkUtil.objectQueryString({
    w: 250,
    h: 300,
    fit: 'crop',
  }),
  'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
    w: 280,
    h: 392,
    fit: 'crop',
  }),
  'imageVariant.portrait-crop4x': sdkUtil.objectQueryString({
    w: 560,
    h: 784,
    fit: 'crop',
  }),
};

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const QUERY_USERS_REQUEST = 'app/ListingPage/QUERY_USERS_REQUEST';
export const QUERY_USERS_SUCCESS = 'app/ListingPage/QUERY_USERS_SUCCESS';
export const QUERY_USERS_ERROR = 'app/ListingPage/QUERY_USERS_ERROR';

export const QUERY_SHOW_EXPERIENCES_REQUEST = 'app/ListingPage/QUERY_SHOW_EXPERIENCES_REQUEST';
export const QUERY_SHOW_EXPERIENCES_SUCCESS = 'app/ListingPage/QUERY_SHOW_EXPERIENCES_SUCCESS';
export const QUERY_SHOW_EXPERIENCES_ERROR = 'app/ListingPage/QUERY_SHOW_EXPERIENCES_ERROR';

export const QUERY_SIMILAR_SHOWS_REQUEST = 'app/ListingPage/QUERY_SIMILAR_SHOWS_REQUEST';
export const QUERY_SIMILAR_SHOWS_SUCCESS = 'app/ListingPage/QUERY_SIMILAR_SHOWS_SUCCESS';
export const QUERY_SIMILAR_SHOWS_ERROR = 'app/ListingPage/QUERY_SIMILAR_SHOWS_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingInProgress: false,
  showListingError: null,
  userIds: [],
  queryUsersInProgress: false,
  queryUsersError: null,
  hasShowExperience: false,
  showExperiencesIds: [],
  queryShowExperiencesInProgress: false,
  queryShowExperiencesError: null,
  similarShowIds: [],
  querySimilarShowsInProgress: false,
  querySimilarShowsError: null,
};

const resultIds = data => data.data.map(d => d.id);

export const getListing = (id, state) => {
  const ref = { id, type: 'listing' };
  const listings = getMarketplaceEntities(state, [ref]);
  return listings.length === 1 ? listings[0] : null;
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingInProgress: true, showListingError: null };
    case SHOW_LISTING_SUCCESS:
      return { ...state, showListingInProgress: false, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingInProgress: false, showListingError: payload };

    case QUERY_USERS_REQUEST:
      return {
        ...state,
        userIds: [],
        queryUsersInProgress: true,
        queryUsersError: null,
      };
    case QUERY_USERS_SUCCESS:
      return {
        ...state,
        userIds: resultIds(payload).filter(i => payload.queryIds.includes(i.uuid)),
        queryUsersInProgress: false,
        queryUsersError: null,
      };
    case QUERY_USERS_ERROR:
      return {
        ...state,
        userIds: [],
        queryUsersInProgress: false,
        queryUsersError: payload,
      };

    case QUERY_SHOW_EXPERIENCES_REQUEST:
      return {
        ...state,
        queryShowExperiencesInProgress: true,
        queryShowExperiencesError: null,
      };
    case QUERY_SHOW_EXPERIENCES_SUCCESS:
      const experienceIds = resultIds(payload.data);
      const hasShowExperience = experienceIds.length > 0;
      return {
        ...state,
        showExperiencesIds: experienceIds,
        queryShowExperiencesInProgress: false,
        queryShowExperiencesError: null,
        hasShowExperience,
      };
    case QUERY_SHOW_EXPERIENCES_ERROR:
      return {
        ...state,
        showExperiencesIds: [],
        queryShowExperiencesInProgress: true,
        queryShowExperiencesError: null,
      };

    case QUERY_SIMILAR_SHOWS_REQUEST:
      return {
        ...state,
        similarShowIds: [],
        querySimilarShowsInProgress: true,
        querySimilarShowsError: null,
      };
    case QUERY_SIMILAR_SHOWS_SUCCESS:
      const similarShowIds = resultIds(payload.data);
      return {
        ...state,
        querySimilarShowsInProgress: false,
        querySimilarShowsError: null,
        similarShowIds,
      };
    case QUERY_SIMILAR_SHOWS_ERROR:
      return {
        ...state,
        similarShowIds: [],
        querySimilarShowsInProgress: true,
        querySimilarShowsError: null,
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingSuccess = () => ({ type: SHOW_LISTING_SUCCESS });
export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryUsersRequest = () => ({
  type: QUERY_USERS_REQUEST,
});
export const queryUsersSuccess = (response, queryIds) => ({
  type: QUERY_USERS_SUCCESS,
  payload: { data: response.data.data, queryIds },
});
export const queryUsersError = e => ({
  type: QUERY_USERS_ERROR,
  error: true,
  payload: e,
});

export const queryShowExperiencesRequest = () => ({
  type: QUERY_SHOW_EXPERIENCES_REQUEST,
});
export const queryShowExperiencesSuccess = response => ({
  type: QUERY_SHOW_EXPERIENCES_SUCCESS,
  payload: { data: response.data },
});
export const queryShowExperiencesError = e => ({
  type: QUERY_SHOW_EXPERIENCES_ERROR,
  error: true,
  payload: e,
});

export const querySimilarShowsRequest = () => ({
  type: QUERY_SIMILAR_SHOWS_REQUEST,
});
export const querySimilarShowsSuccess = response => ({
  type: QUERY_SIMILAR_SHOWS_SUCCESS,
  payload: { data: response.data },
});
export const querySimilarShowsError = e => ({
  type: QUERY_SIMILAR_SHOWS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const fetchListingTeamIds = (listing, dispatch) => {
  const teamFromPublicData = listing.attributes.publicData.team || [];
  const teamIds = teamFromPublicData.filter(i => !i.pending).map(i => i.id);

  return dispatch(queryUsers(teamIds));
};

export const showListing = (listingId, isOwn = false, useIntegration = false) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(showListingRequest(listingId));

  const params = {
    id: listingId,
    ...listingsIncludeParams,
  };

  const show = isOwn
    ? useIntegration
      ? integrationAPI.listings.show({
          ...params,
          id: listingId.uuid,
        })
      : sdk.ownListings.show(params)
    : sdk.listings.show(params);

  return show
    .then(response => {
      const data = isOwn && useIntegration ? response.data : response;
      const listing = denormalisedResponseEntities(data)?.[0];

      const organizationProfileId = listing.attributes.publicData.organizationId;

      dispatch(showListingSuccess(data));
      dispatch(addMarketplaceEntities(data));
      dispatch(showOrganizationProfile(organizationProfileId));
      fetchListingTeamIds(listing, dispatch);

      return listing;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const queryUsers = queryIds => async (dispatch, getState, sdk) => {
  dispatch(queryUsersRequest());

  return integrationAPI.users
    .query({
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response.data));
      dispatch(queryUsersSuccess(response.data, queryIds));

      return response.data;
    })
    .catch(e => dispatch(queryUsersError(storableError(e))));
};

export const queryShowExperiences = (listingId, params) => (dispatch, getState, sdk) => {
  dispatch(queryShowExperiencesRequest());

  return sdk.listings
    .query({
      ...params,
      pub_type: config.listingTypes['experience'],
      pub_showId: listingId.uuid,
      ...listingsIncludeParams,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryShowExperiencesSuccess(response));
      return response;
    })
    .catch(e => dispatch(queryShowExperiencesError(storableError(e))));
};

export const querySimilarShows = category => (dispatch, getState, sdk) => {
  dispatch(querySimilarShowsRequest());

  return sdk.listings
    .query({
      pub_category: category,
      pub_type: config.listingTypes['show'],
      ...listingsIncludeParams,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(querySimilarShowsSuccess(response));
      return response;
    })
    .catch(e => dispatch(querySimilarShowsError(storableError(e))));
};

// loadData helper functions
const getCurrentListing = async listingId => {
  const currentListingResponse = await integrationAPI.listings.show({ id: listingId });
  return currentListingResponse.data.data.data;
};

export const loadData = params => async (dispatch, getState, sdk) => {
  const listingId = new UUID(params.id);

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];

  if (ownListingVariants.includes(params.variant)) {
    const { currentUser } = getState().user;

    const currentListing = await getCurrentListing(listingId.uuid);
    const organizationProfiles = await dispatch(fetchOrganizationProfiles());
    const isCurrentUserInvitedToOrganization = isUserInvitedToOrganization(
      currentListing,
      organizationProfiles
    );
    const isCurrentUserInvitedToTeam = isUserInvitedToOrganization(currentUser, currentListing);
    const useIntegration = isCurrentUserInvitedToOrganization || isCurrentUserInvitedToTeam;

    const listingPromise = dispatch(showListing(listingId, true, useIntegration)).then(listing => {
      const category = listing?.attributes?.publicData?.category;
      dispatch(querySimilarShows(category));
    });
    const queryShowExperiencesPromise = dispatch(queryShowExperiences(listingId));
    const fetchReviewsPromise = dispatch(fetchReviews(listingId));

    return Promise.all([listingPromise, queryShowExperiencesPromise, fetchReviewsPromise]);
  }

  return Promise.all([
    dispatch(showListing(listingId)).then(listing => {
      const category = listing?.attributes?.publicData?.category;

      return Promise.all([
        dispatch(querySimilarShows(category)),
        dispatch(fetchReviews(listingId)),
        dispatch(saveRecentlyViewedListingId(listingId.uuid)).then(() => {
          dispatch(queryRecentlyViewedListings(listingId.uuid));
        }),
      ]);
    }),
    dispatch(queryShowExperiences(listingId)),
    dispatch(fetchOrganizationProfiles()),
  ]);
};
