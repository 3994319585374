import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { notification } from '../../util/notification';
import { ListingLink, InviteTeamMemberModal } from '..';
import { EditListingTeamForm } from '../../forms';
import config from '../../config';

import css from './EditListingTeamPanel.module.css';

const EditListingTeamPanel = props => {
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [sendInviteInProgress, setSendInviteInProgress] = useState(false);
  const {
    className,
    rootClassName,
    intl,
    currentUser,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    teamUsers,
    getInvitesState,
    getInvitesFns,
    organizationProfiles,
    onManageDisableScrolling,
    onGetPrevPathParams,
    creditRolesConfigOptions,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, publicData } = currentListing.attributes;
  const organizationId = publicData.organizationId;

  const currentOrganizationProfile = organizationProfiles?.find(o => o.id === organizationId);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingTeamPanel.title"
      values={{
        listingTitle: (
          <ListingLink className={css.listingLink} listing={listing}>
            {title}
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingTeamPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <EditListingTeamForm
        className={css.form}
        initialValues={{
          team: teamUsers,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { team } = values;

          const hasTeamFromValues = team?.length > 0;

          const teamFromPublicData = publicData.team; // team from publicData
          const teamForPublicData = hasTeamFromValues // team for publicData
            ? team.map(u => {
                const currentUserData = teamFromPublicData.find(i => i.id === u.id.uuid);
                const data = currentUserData
                  ? { ...currentUserData, pending: currentUserData?.pending }
                  : { pending: currentUserData?.pending };
                return {
                  id: u.id.uuid,
                  ...data,
                };
              })
            : null;

          const completedTeamTabMaybe = isPublished
            ? {}
            : {
                completedTeamTab: true,
              };

          const updateValues = {
            publicData: {
              team: teamForPublicData,
              ...completedTeamTabMaybe,
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        onResendInvite={getInvitesFns().onResendInvite}
        onRevokeInvite={getInvitesFns().onRevokeInvite}
        onRemoveInvite={getInvitesFns().onRemoveInvite}
        onGetPrevPathParams={onGetPrevPathParams}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        showInvitesInProgress={getInvitesState().showListingInvitesInProgress}
        showInvitesError={getInvitesState().showListingInvitesError}
        resendInviteInProgress={getInvitesState().resendListingInviteInProgress}
        resendInviteError={getInvitesState().resendListingInviteError}
        revokeInviteInProgress={getInvitesState().revokeListingInviteInProgress}
        revokeInviteError={getInvitesState().revokeListingInviteError}
        removeInviteInProgress={getInvitesState().removeListingInviteInProgress}
        removeInviteError={getInvitesState().removeListingInviteError}
        resendId={getInvitesState().resendListingId}
        revokeId={getInvitesState().revokeListingId}
        removeId={getInvitesState().removeListingId}
        setInviteModalOpen={setInviteModalOpen}
        fetchErrors={errors}
        currentUser={currentUser}
        listing={currentListing}
        panelTitle={panelTitle}
      />
      <InviteTeamMemberModal
        id="InviteTeamMemberModal"
        isOpen={isInviteModalOpen}
        onCloseModal={() => setInviteModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        onEmailValid={getInvitesFns().onEmailValid}
        isEmailValid={getInvitesState().isEmailValid}
        handleSubmit={async (values, form) => {
          const { email, inviteToOrganization, ...formValues } = values;
          const invitedToOrganization = inviteToOrganization?.length > 0;

          setSendInviteInProgress(true);
          await getInvitesFns().onSendInvite(email, formValues, listing.id.uuid);

          if (invitedToOrganization) {
            const organizationIds = [organizationId];
            await getInvitesFns().onSendOrganizationInvite(email, organizationIds);
          }

          setInviteModalOpen(false);
          setSendInviteInProgress(false);
          form.restart();

          notification.success(
            intl.formatMessage({
              id: 'EditListingTeamPanel.successInviteMessage',
            })
          );
        }}
        currentUser={currentUser}
        listing={currentListing}
        team={teamUsers}
        currentOrganizationProfile={currentOrganizationProfile}
        sendInviteInProgress={getInvitesState().sendListingInviteInProgress || sendInviteInProgress}
        sendInviteError={getInvitesState().sendListingInviteError}
        emailValidInProgress={getInvitesState().emailValidInProgress}
        creditRolesConfigOptions={creditRolesConfigOptions}
      />
    </div>
  );
};

EditListingTeamPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  creditRolesConfigOptions: config.custom.creditRolesConfigOptions,
};

EditListingTeamPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  onGetPrevPathParams: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default injectIntl(EditListingTeamPanel);
