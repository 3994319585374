import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { integrationAPI, wherebyAPI } from '../../util/api';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const GET_MEETING_REQUEST = 'app/MeetPage/GET_MEETING_REQUEST';
export const GET_MEETING_SUCCESS = 'app/MeetPage/GET_MEETING_SUCCESS';
export const GET_MEETING_ERROR = 'app/MeetPage/GET_MEETING_ERROR';

export const UPDATE_VIEWS_REQUEST = 'app/MeetPage/UPDATE_VIEWS_REQUEST';
export const UPDATE_VIEWS_SUCCESS = 'app/MeetPage/UPDATE_VIEWS_SUCCESS';
export const UPDATE_VIEWS_ERROR = 'app/MeetPage/UPDATE_VIEWS_ERROR';

export const SHOW_LISTING_REQUEST = 'app/MeetPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/MeetPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/MeetPage/SHOW_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  meeting: null,
  getMeetingInProgress: false,
  getMeetingError: null,
  updateViewsInProgress: false,
  updateViewsError: null,
  showListingInProgress: false,
  showListingError: null,
  listingId: null,
};

export default function meetPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    // GET_MEETING
    case GET_MEETING_REQUEST:
      return {
        ...state,
        getMeetingInProgress: true,
        getMeetingError: null,
      };
    case GET_MEETING_SUCCESS:
      return {
        ...state,
        meeting: payload.meeting,
        getMeetingInProgress: false,
        getMeetingError: null,
      };
    case GET_MEETING_ERROR:
      return {
        ...state,
        getMeetingInProgress: false,
        getMeetingError: payload,
      };

    // UPDATE_VIEWS
    case UPDATE_VIEWS_REQUEST:
      return {
        ...state,
        updateViewsInProgress: true,
        updateViewsError: null,
      };
    case UPDATE_VIEWS_SUCCESS:
      return {
        ...state,
        updateViewsInProgress: false,
        updateViewsError: null,
      };
    case UPDATE_VIEWS_ERROR:
      return {
        ...state,
        updateViewsInProgress: false,
        updateViewsError: null,
      };

    // FETCH_LISTING
    case SHOW_LISTING_REQUEST:
      return {
        ...state,
        listingId: new UUID(payload.id),
        showListingInProgress: true,
        showListingError: null,
      };
    case SHOW_LISTING_SUCCESS:
      return { ...state, showListingInProgress: false, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingInProgress: false, showListingError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const getMeetingRequest = () => ({
  type: GET_MEETING_REQUEST,
});
export const getMeetingSuccess = meeting => ({
  type: GET_MEETING_SUCCESS,
  payload: { meeting },
});
export const getMeetingError = e => ({
  type: GET_MEETING_ERROR,
  error: true,
  payload: e,
});

export const updateViewsRequest = () => ({
  type: UPDATE_VIEWS_REQUEST,
});
export const updateViewsSuccess = () => ({
  type: UPDATE_VIEWS_SUCCESS,
});
export const updateViewsError = e => ({
  type: UPDATE_VIEWS_ERROR,
  error: true,
  payload: e,
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingSuccess = () => ({ type: SHOW_LISTING_SUCCESS });
export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const getMeeting = meetingId => async (dispatch, getState, sdk) => {
  dispatch(getMeetingRequest());

  try {
    const response = await wherebyAPI.getMeeting({ meetingId });
    dispatch(getMeetingSuccess(response.data));
  } catch (e) {
    dispatch(getMeetingError(storableError(e)));
  }
};

export function updateViews(roomName, listingId) {
  return async (dispatch, getState, sdk) => {
    dispatch(updateViewsRequest());

    try {
      const response = await sdk.listings.show({ id: listingId });
      const listing = response.data.data;
      const exceptions = listing.attributes.publicData.exceptions;

      const updatedExceptions = exceptions.map(e => {
        const exceptionRoomName = e.attributes.meeting.roomName.replace('/', '');

        if (exceptionRoomName === roomName) {
          return {
            ...e,
            attributes: {
              ...e.attributes,
              views: e.attributes.views ? e.attributes.views + 1 : 1,
            },
          };
        }

        return e;
      });

      const updateResponse = await integrationAPI.listings.update({
        id: listingId,
        publicData: {
          exceptions: updatedExceptions,
        },
      });

      dispatch(updateViewsSuccess(updateResponse));
    } catch (error) {
      dispatch(updateViewsError(storableError(error)));
    }
  };
}

export const showListing = listingId => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));

  return sdk.listings
    .show({ id: listingId })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showListingSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const queryParams = parse(search);
  const { meetingId, listingId } = queryParams;

  return Promise.all([dispatch(getMeeting(meetingId)), dispatch(showListing(listingId))]);
};
