import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { Modal, PrimaryButton, SecondaryButton } from '../../components';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import classNames from 'classnames';

import css from './ConfirmationModal.module.css';

const ConfirmationModal = props => {
  const {
    className,
    rootClassName,
    intl,
    id = 'ConfirmationModal',
    isOpen,
    onCloseModal,
    onConfirm,
    onCancel,
    inProgress,
    title,
    description,
    useVariationColor,
    onManageDisableScrolling,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const submitButtonClasses = useVariationColor ? css.submitButtonVariationColor : css.submitButton;

  const titleMessage = title
    ? title
    : intl.formatMessage({
        id: 'ConfirmationModal.title',
      });
  const descriptionMessage = description
    ? description
    : intl.formatMessage({
        id: 'ConfirmationModal.description',
      });

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <p className={css.modalTitle}>{titleMessage}</p>
      <p className={css.modalMessage}>{descriptionMessage}</p>
      <div className={css.modalButtons}>
        <SecondaryButton type="button" className={css.cancelButton} onClick={handleCancel}>
          <FormattedMessage id="ConfirmationModal.cancel" />
        </SecondaryButton>
        <PrimaryButton
          type="button"
          className={submitButtonClasses}
          inProgress={inProgress}
          onClick={handleConfirm}
        >
          <FormattedMessage id="ConfirmationModal.confirm" />
        </PrimaryButton>
      </div>
    </Modal>
  );
};

const { bool, string, func } = PropTypes;

ConfirmationModal.defaultProps = {
  className: null,
  rootClassName: null,
  id: null,
  isOpen: false,
  inProgress: false,
  onConfirm: null,
  onCloseModal: null,
  title: null,
  description: null,
  useVariationColor: false,
};

ConfirmationModal.propTypes = {
  className: string,
  rootClassName: string,
  id: string,
  intl: intlShape.isRequired,
  isOpen: bool.isRequired,
  inProgress: bool.isRequired,
  onConfirm: func,
  onCloseModal: func,
  title: string,
  description: string,
  useVariationColor: bool,
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(injectIntl, connect(null, mapDispatchToProps))(ConfirmationModal);
