import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import config from '../../config';

const RESULT_PAGE_SIZE = 10;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/CommunityPage/SET_INITIAL_STATE';

export const SUBMIT_POST_REQUEST = 'app/CommunityPage/SUBMIT_POST_REQUEST';
export const SUBMIT_POST_SUCCESS = 'app/CommunityPage/SUBMIT_POST_SUCCESS';
export const SUBMIT_POST_ERROR = 'app/CommunityPage/SUBMIT_POST_ERROR';

export const SEARCH_POSTS_REQUEST = 'app/CommunityPage/SEARCH_POSTS_REQUEST';
export const SEARCH_POSTS_SUCCESS = 'app/CommunityPage/SEARCH_POSTS_SUCCESS';
export const SEARCH_POSTS_ERROR = 'app/CommunityPage/SEARCH_POSTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchPostsInProgress: false,
  searchPostsError: null,
  currentPageResultIds: [],
  submitPostInProgress: false,
  submitPostError: null,
};

const resultIds = data => data.data.map(l => l.id);

export default function communityPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case SUBMIT_POST_REQUEST:
      return {
        ...state,
        submitPostInProgress: true,
        submitPostError: null,
      };
    case SUBMIT_POST_SUCCESS:
      return {
        ...state,
        submitPostInProgress: false,
        submitPostError: null,
      };
    case SUBMIT_POST_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, submitPostInProgress: false, submitPostError: payload };

    case SEARCH_POSTS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchPostsInProgress: true,
        searchPostsError: null,
      };
    case SEARCH_POSTS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchPostsInProgress: false,
      };
    case SEARCH_POSTS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchPostsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const submitPostRequest = () => ({
  type: SUBMIT_POST_REQUEST,
});
export const submitPostSuccess = () => ({
  type: SUBMIT_POST_SUCCESS,
});
export const submitPostError = e => ({
  type: SUBMIT_POST_ERROR,
  error: true,
  payload: e,
});

export const searchPostsRequest = searchParams => ({
  type: SEARCH_POSTS_REQUEST,
  payload: { searchParams },
});
export const searchPostsSuccess = response => ({
  type: SEARCH_POSTS_SUCCESS,
  payload: { data: response.data },
});
export const searchPostsError = e => ({
  type: SEARCH_POSTS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const searchPosts = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchPostsRequest(searchParams));

  const { perPage, price, dates, ...rest } = searchParams;

  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchPostsSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(searchPostsError(storableError(e)));
      throw e;
    });
};

export const submitPost = (actionPayload, file) => async (dispatch, getState, sdk) => {
  dispatch(submitPostRequest());

  let images;
  if (file) {
    await sdk.images
      .upload(
        {
          image: file,
        },
        {
          expand: true,
        }
      )
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        images = [response.data.data.id];
      });
  }

  const payload = images
    ? {
        ...actionPayload,
        images,
      }
    : actionPayload;

  return sdk.ownListings
    .create(payload)
    .then(response => {
      dispatch(submitPostSuccess());
      dispatch(addMarketplaceEntities(response));

      const postId = response.data.data.id.uuid;
      return postId;
    })
    .catch(e => dispatch(submitPostError(storableError(e))));
};

export const loadData = (params, search) => {
  const queryParams = parse(search);
  const { page = 1, address, origin, ...rest } = queryParams;

  return searchPosts({
    ...rest,
    pub_type: config.listingTypes['community'],
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'author.profileImage'],
    'fields.user': ['profile.displayName', 'profile.metadata'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    'limit.images': 1,
  });
};
