import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { ConfirmationModal } from '../components';
import { extractColors } from 'extract-colors';

const SHAKE_ANIMATION_DELAY = 4000;

export const useShakeAnimation = (containerRef, delay = SHAKE_ANIMATION_DELAY) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const container = containerRef.current;

    function restartAnimation() {
      if (isMounted) {
        setIsAnimating(true);
        void container.offsetWidth;
      }
    }

    function handleAnimationEnd() {
      if (isMounted) {
        setIsAnimating(false);
        setTimeout(restartAnimation, delay);
      }
    }

    container.addEventListener('animationend', handleAnimationEnd);
    restartAnimation();

    return () => {
      isMounted = false;
      container.removeEventListener('animationend', handleAnimationEnd);
    };
  }, [containerRef, delay]);

  return { isAnimating };
};

/**
 * Wait for an element before resolving a promise
 * @param {String} querySelector - Selector of element to wait for
 * @param {Integer} timeout - Milliseconds to wait before timing out, or 0 for no timeout
 */
export const waitForElement = (querySelector, timeout) => {
  return new Promise((resolve, reject) => {
    var timer = false;
    if (document.querySelectorAll(querySelector).length) return resolve();
    const observer = new MutationObserver(() => {
      if (document.querySelectorAll(querySelector).length) {
        observer.disconnect();
        if (timer !== false) clearTimeout(timer);
        return resolve();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
    if (timeout)
      timer = setTimeout(() => {
        observer.disconnect();
        reject();
      }, timeout);
  });
};

/**
 * Custom hook that tracks the width of a DOM element using the ResizeObserver API.
 *
 * @returns {Object} An object containing:
 * - elementRef {React.RefObject}: A ref to be attached to the target DOM element.
 * - elementWidth {number}: The current width of the element.
 */
export const useElementSize = () => {
  const elementRef = useRef(null);
  const [elementWidth, setElementWidth] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setElementWidth(entry.contentRect.width);
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        resizeObserver.unobserve(elementRef.current);
      }
    };
  }, []);

  return { elementRef, elementWidth };
};

/**
 * Custom hook that simulates a loading state for a specified delay.
 *
 * @param {number} [delay=1000] - The delay in milliseconds before loading is set to false (default is 1000ms).
 *
 * @returns {boolean} A boolean indicating whether the element is still in a loading state (true if loading, false otherwise).
 */
export const useElementLoaded = (delay = 1000) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [delay]);
  }, []);

  return loading;
};

/**
 * Custom hook to extract dominant colors from an image URL and store them in memory efficiently.
 *
 * @param {string} url - The URL of the image to extract colors from.
 *
 * @returns {Array} An array of colors extracted from the image.
 */
export const useImageColors = url => {
  const [colors, setColors] = useState([]);

  useMemo(() => {
    let isMounted = true;

    if (url) {
      const options = {
        distance: 0.2,
        colorValidator: (red, green, blue, alpha = 255) => alpha > 250,
        saturationDistance: 0.5,
        lightnessDistance: 0.5,
        hueDistance: 0.5,
      };

      extractColors(url, options).then(apiData => {
        if (isMounted) {
          setColors(apiData);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [url]);

  return colors;
};

/**
 * A simple debounce function to limit how often a function can run.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} delay - The delay in milliseconds.
 * @returns {Function} - The debounced function.
 */
const debounce = (func, delay) => {
  let timeout;
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
};

/**
 * A custom hook that prevents horizontal scrolling on a given element
 * only if the device is not mobile. It also updates based on viewport resizing.
 *
 * @param {React.Ref} ref - A React ref that points to the element to prevent horizontal scrolling on.
 * @param {number} maxWidth - The maximum width for non-mobile devices, defaults to 768.
 */
export const usePreventHorizontalScroll = (ref, maxWidth = 768) => {
  useEffect(() => {
    const element = ref.current;

    const preventHorizontalScroll = event => {
      if (event.deltaX !== 0) {
        event.preventDefault();
      }
    };

    const updateScrollListeners = () => {
      const isMobile = window.innerWidth < maxWidth;

      if (!isMobile && element) {
        element.addEventListener('wheel', preventHorizontalScroll, { passive: false });
        element.addEventListener('touchmove', preventHorizontalScroll, { passive: false });
      } else if (element) {
        element.removeEventListener('wheel', preventHorizontalScroll);
        element.removeEventListener('touchmove', preventHorizontalScroll);
      }
    };

    updateScrollListeners();

    const debouncedResizeListener = debounce(updateScrollListeners, 100);

    window.addEventListener('resize', debouncedResizeListener);

    return () => {
      window.removeEventListener('resize', debouncedResizeListener);
      if (element) {
        element.removeEventListener('wheel', preventHorizontalScroll);
        element.removeEventListener('touchmove', preventHorizontalScroll);
      }
    };
  }, [ref, maxWidth]);
};

export const useConfirmationModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [resolve, setResolve] = useState(null);
  const [modalProps, setModalProps] = useState({
    id: null,
    title: null,
    description: null,
    inProgress: false,
    useVariationColor: false,
  });

  const modalId = modalProps.id || 'ConfirmationModal';

  const open = useCallback(({ id, title, description, inProgress, useVariationColor } = {}) => {
    setModalProps({ id, title, description, inProgress, useVariationColor });
    return new Promise(res => {
      setResolve(() => res);
      setIsOpen(true);
    });
  }, []);

  const handleConfirm = () => {
    if (resolve) resolve(true);
    setIsOpen(false);
  };

  const handleCancel = () => {
    if (resolve) resolve(false);
    setIsOpen(false);
  };

  const modalElement = isOpen && (
    <ConfirmationModal
      id={modalId}
      isOpen={isOpen}
      inProgress={modalProps.inProgress}
      title={modalProps.title}
      description={modalProps.description}
      useVariationColor={modalProps.useVariationColor}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      onCloseModal={() => setIsOpen(false)}
    />
  );

  return {
    open,
    modalElement,
  };
};

/**
 * Custom hook to manage the removal and restoration of the "Getting Started" page using local storage.
 *
 * @param {string} userId - The ID of the current user.
 * @returns {Object} An object containing:
 *   - `isRemoved` {boolean} - Whether the page has been removed for the current user based on local storage.
 *   - `removePage` {function} - A function to mark the page as removed for the current user in local storage.
 *   - `restorePage` {function} - A function to restore the page for the current user by removing the flag from local storage.
 */
export const useGettingStartedRemoval = userId => {
  const [isRemoved, setIsRemoved] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem('gettingStartedRemoved');
    const gettingStartedRemoved = storedData ? JSON.parse(storedData) : [];

    const userEntry = gettingStartedRemoved.find(entry => entry.id === userId);
    if (userEntry) {
      setIsRemoved(userEntry.isRemoved);
    }
  }, [userId]);

  const removePage = () => {
    const storedData = localStorage.getItem('gettingStartedRemoved');
    const gettingStartedRemoved = storedData ? JSON.parse(storedData) : [];

    const userEntry = gettingStartedRemoved.find(entry => entry.id === userId);
    if (userEntry) {
      userEntry.isRemoved = true;
    } else {
      gettingStartedRemoved.push({ id: userId, isRemoved: true });
    }

    localStorage.setItem('gettingStartedRemoved', JSON.stringify(gettingStartedRemoved));
    setIsRemoved(true);
  };

  const restorePage = () => {
    const storedData = localStorage.getItem('gettingStartedRemoved');
    const gettingStartedRemoved = storedData ? JSON.parse(storedData) : [];

    const userEntry = gettingStartedRemoved.find(entry => entry.id === userId);
    if (userEntry) {
      userEntry.isRemoved = false;
      localStorage.setItem('gettingStartedRemoved', JSON.stringify(gettingStartedRemoved));
      setIsRemoved(false);
    }
  };

  return {
    isRemoved,
    removePage,
    restorePage,
  };
};
