import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import { LinkTabNavHorizontal, NotificationBadge } from '../../components';
import classNames from 'classnames';

import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, selectedPageName, customerNotificationCount } = props;
  const classes = classNames(rootClassName || css.root, className);

  const customerHasNotifications = customerNotificationCount > 0;

  const tabs = [
    {
      text: <FormattedMessage id="UserNav.subscriptionsPage" />,
      selected: selectedPageName === 'ManageSubscriptionsPage',
      disabled: false,
      linkProps: {
        name: 'ManageSubscriptionsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.watchlistPage" />,
      selected: selectedPageName === 'WatchlistPage',
      disabled: false,
      linkProps: {
        name: 'WatchlistPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.calendarPage" />,
      selected: selectedPageName === 'CalendarPage',
      disabled: false,
      linkProps: {
        name: 'CalendarPage',
      },
    },
    {
      text: customerHasNotifications ? (
        <span className={css.notification}>
          <FormattedMessage id="UserNav.ordersPage" />
          <NotificationBadge count={customerNotificationCount} />
        </span>
      ) : (
        <FormattedMessage id="UserNav.ordersPage" />
      ),
      selected: selectedPageName === 'OrdersPage',
      disabled: false,
      linkProps: {
        name: 'OrdersPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.orderHistoryPage" />,
      selected: selectedPageName === 'OrderHistoryPage',
      disabled: false,
      linkProps: {
        name: 'OrderHistoryPage',
      },
    },
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
  customerNotificationCount: 0,
};

const { string, number } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
  customerNotificationCount: number,
};

const mapStateToProps = state => {
  return {
    customerNotificationCount: state.user.customerNotificationCount,
  };
};

export default compose(connect(mapStateToProps))(UserNav);
