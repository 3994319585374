import React from 'react';
import { Modal, NamedLink, IconClap } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const SuccessInviteModal = props => {
  const {
    containerClassName,
    contentClassName,
    id,
    isOpen,
    onCloseModal,
    currentUser,
    onManageDisableScrolling,
  } = props;
  const currentUserId = currentUser?.id?.uuid;
  return (
    <Modal
      id={id}
      containerClassName={containerClassName}
      contentClassName={contentClassName}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <IconClap className={css.modalIcon} />
      <h1 className={css.modalTitle}>
        <FormattedMessage id="ListingPage.successInviteModalTitle" />
      </h1>
      <p className={css.modalMessage}>
        <FormattedMessage id="ListingPage.successInviteModalMessage" />
      </p>
      {currentUserId ? (
        <NamedLink
          className={classNames(css.modalLink, css.modalLinkWithMargin)}
          name="ArtistProfilePage"
          params={{
            id: currentUserId,
          }}
        >
          <FormattedMessage id="ListingPage.successInviteModalLinkMessage" />
        </NamedLink>
      ) : null}
    </Modal>
  );
};

export default SuccessInviteModal;
