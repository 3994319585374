import React from 'react';
import { array, string, number } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureListing, getFirstStringPart } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  IconReviewStar,
  NamedLink,
  ButtonWatchlist,
  AspectRatioWrapper,
  ResponsiveImage,
} from '../../components';
import { getAverageReview } from '../../containers/ReviewsPage/ReviewsPage.duck';
import config from '../../config';

import increaseImage from './images/increase-img.svg';
import css from './HeroListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

/**
 * Determines the aspect ratio based on the viewport width.
 *
 * @param {Object} viewport - The viewport dimensions.
 * @param {number} viewport.width - The width of the viewport.
 * @param {number} viewport.height - The height of the viewport (not used in this function).
 * @returns {Object} An object containing the aspect ratio with `width` and `height` properties.
 */
export const getAspectRatio = viewport => {
  const MAX_MOBILE_SCREEN_WIDTH = 768;
  const MAX_TABLET_SCREEN_WIDTH = 1124;

  if (viewport.width === 0) {
    return { width: 4, height: 2 };
  } else if (viewport.width < MAX_MOBILE_SCREEN_WIDTH) {
    return { width: 5, height: 8 };
  } else if (viewport.width < MAX_TABLET_SCREEN_WIDTH) {
    return { width: 2, height: 2 };
  } else {
    return { width: 4, height: 2 };
  }
};

export const HeroListingCardComponent = props => {
  const { className, rootClassName, listing, listingIndex, viewport, filtersConfig } = props;
  const classes = classNames(rootClassName || css.root, className);

  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData, metadata } = currentListing.attributes;
  const slug = createSlug(title);

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const coverImage =
    listing?.images?.find(i => i.id.uuid === publicData?.coverImageId) ?? firstImage;

  const categoryOptions = findOptionsForSelectFilter('category', filtersConfig);
  const category = categoryOptions.find(c => c.key === publicData?.category);

  const statusOptions = findOptionsForSelectFilter('status', filtersConfig);
  const status = statusOptions.find(s => s.key === publicData?.status);

  const organizationProfile = publicData?.organizationProfile;
  const address = publicData?.location?.address;
  const normalizedAddress = getFirstStringPart(address);

  const reviews = metadata?.reviews ? metadata.reviews : [];
  const ratings = metadata?.showRatings ? metadata.ratings : [];
  const reviewsAndRatings = reviews.concat(ratings);
  const hasReviewsOrRatings = reviewsAndRatings.length > 0;
  const averageReview = hasReviewsOrRatings ? getAverageReview(currentListing, reviews) : 0;

  const aspectRatio = getAspectRatio(viewport);

  const listingInfo = (
    <div className={css.listingInfo}>
      <div className={css.imageOverlay} />
      <ButtonWatchlist className={css.watchlistButton} listing={currentListing} />
      <div className={css.info}>
        {organizationProfile ? <h3 className={css.subTitle}>{organizationProfile.name}</h3> : null}
        <h1 className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </h1>
        <div className={css.details}>
          {category ? <span className={css.detail}>{category.label}</span> : null}
          {status ? <span className={css.detail}>{status.label}</span> : null}
          {address ? (
            <span className={css.detail}>
              <FormattedMessage
                id="HeroListingCard.address"
                values={{ address: normalizedAddress }}
              />
            </span>
          ) : null}
        </div>
        <div className={css.extraInfo}>
          <div className={css.reviews}>
            <IconReviewStar className={css.reviewsIcon} />
            <p className={css.reviewsText}>
              <FormattedMessage
                id="HeroListingCard.review"
                values={{
                  reviewLength: reviewsAndRatings.length,
                  averageReview: averageReview,
                }}
              />
            </p>
          </div>
          <p className={css.extraInfoText}>
            <img className={css.extraInfoImg} src={increaseImage} alt="Increase" />
            <FormattedMessage id="HeroListingCard.trending" />
          </p>
        </div>
        <div className={css.listingLinkWrapper}>
          <NamedLink className={css.listingLink} name="ListingPage" params={{ id, slug }}>
            <FormattedMessage id="HeroListingCard.listingLink" />
          </NamedLink>
          {listingIndex ? <span className={css.listingOrder}>{listingIndex}</span> : null}
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes}>
      <AspectRatioWrapper width={aspectRatio.width} height={aspectRatio.height}>
        <ResponsiveImage
          rootClassName={css.rootForImage}
          alt={title}
          image={coverImage}
          variants={['landscape-crop4x', 'landscape-crop6x']}
        />
        {listingInfo}
      </AspectRatioWrapper>
    </div>
  );
};

HeroListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  listingIndex: null,
  filtersConfig: config.custom.filters,
};

HeroListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listingIndex: number,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,
};

const HeroListingCard = compose(
  withRouter,

  injectIntl
)(HeroListingCardComponent);

export default HeroListingCard;
