import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import {
  FieldTextInput,
  EditWizardButton,
  InlineTextButton,
  ImageFromFile,
  Menu,
  MenuContent,
  MenuLabel,
  MenuItem,
  IconMenu,
  IconPhoto,
  NamedLink,
  FieldSelectInput,
  IconEdit,
} from '../../components';
import * as validators from '../../util/validators';
import classNames from 'classnames';

import { ACTION_TYPE_EDIT } from '../../containers/OrganizationSettingsPage/OrganizationSettingsPage';
import css from './OrganizationSettingsForm.module.css';

const ACCEPT_IMAGES = 'image/*';
const USE_ARROW = false;
const CONTENT_POSITION = 'left';
const BIO_MIN_LENGTH = 200;
const BIO_MAX_LENGTH = 700;

const yearsOptions = Array.from({ length: new Date().getFullYear() - 1979 }, (_, i) => ({
  key: (new Date().getFullYear() - i).toString(),
  label: (new Date().getFullYear() - i).toString(),
}));

const IconProfile = props => {
  const { className } = props;
  return (
    <svg className={className} viewBox="0 0 24 24" height="48" width="48" xmlSpace="preserve">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2 19.1c1-2.8 3.7-4.7 6.7-4.7s5.7 1.9 6.7 4.7c-4.1 2.5-9.3 2.5-13.4 0zm16.1-1.9c-.5.5-1.1 1-1.7 1.5a8.15 8.15 0 0 0-7.6-5.2c-3.3 0-6.3 2.1-7.6 5.1-.6-.4-1.1-.9-1.6-1.4l-.8.7C4.8 20.6 8.4 22 12 22s7.2-1.4 10-4.1l-.7-.7zM12 2C9.2 2 7 4.2 7 7s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 9c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z"
      />
    </svg>
  );
};

const ManageProfileMenu = props => {
  const { currentOrganizationProfile, onEditProfile, onDeleteProfile } = props;
  return (
    <Menu className={css.menu} useArrow={USE_ARROW} contentPosition={CONTENT_POSITION}>
      <MenuLabel className={css.menuLabel}>
        <IconMenu />
      </MenuLabel>
      <MenuContent className={css.menuContent}>
        <MenuItem key="Menu.edit" className={css.menuItem}>
          <InlineTextButton className={css.menuItemButton} type="button" onClick={onEditProfile}>
            <FormattedMessage id="OrganizationSettingsForm.edit" />
          </InlineTextButton>
        </MenuItem>
        <MenuItem key="Menu.view" className={css.menuItem}>
          {currentOrganizationProfile?.id ? (
            <NamedLink
              className={css.menuItemButton}
              name="OrganizationProfilePage"
              params={{ id: currentOrganizationProfile.id }}
            >
              <FormattedMessage id="OrganizationSettingsForm.view" />
            </NamedLink>
          ) : (
            <InlineTextButton className={css.menuItemButton} type="button" disabled>
              <FormattedMessage id="OrganizationSettingsForm.view" />
            </InlineTextButton>
          )}
        </MenuItem>
        <MenuItem
          key="Menu.delete"
          className={css.menuItem}
          isHidden={currentOrganizationProfile.invited}
        >
          <InlineTextButton className={css.menuItemButton} type="button" onClick={onDeleteProfile}>
            <FormattedMessage id="OrganizationSettingsForm.delete" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

/**
 * Component that renders the default profile view, that shows the
 * profile informations and button to edit them.
 */
const DefaultProfileView = props => {
  const {
    currentOrganizationProfile,
    selectedOrganization,
    onEditProfile,
    onDeleteProfile,
  } = props;

  if (!currentOrganizationProfile?.name) {
    return null;
  }

  const profileImage = currentOrganizationProfile.profileImage;
  const isSelected = currentOrganizationProfile?.id === selectedOrganization?.id;

  return (
    <div className={css.organizationProfile}>
      {profileImage ? (
        <img
          className={css.organizationProfileImage}
          src={profileImage.link}
          alt={profileImage.name}
        />
      ) : (
        <div className={css.organizationProfileNoImage}>
          <span className={css.organizationProfileNoImageInitials}>
            {currentOrganizationProfile?.name?.[0] || ''}
          </span>
        </div>
      )}
      <div className={css.organizationProfileText}>
        {currentOrganizationProfile?.name ? (
          isSelected ? (
            <div className={css.organizationProfileTitleWrapper}>
              <h4 className={css.organizationProfileTitle}>{currentOrganizationProfile.name}</h4>
              <span className={css.organizationProfileSelectedText}>
                <FormattedMessage id="OrganizationSettingsForm.selected" />
              </span>
            </div>
          ) : (
            <h4 className={css.organizationProfileTitle}>{currentOrganizationProfile.name}</h4>
          )
        ) : null}
        {currentOrganizationProfile?.bio ? (
          <p className={css.organizationProfileBio}>{currentOrganizationProfile.bio}</p>
        ) : null}
      </div>
      <ManageProfileMenu
        currentOrganizationProfile={currentOrganizationProfile}
        onEditProfile={onEditProfile}
        onDeleteProfile={onDeleteProfile}
      />
    </div>
  );
};

/**
 * Component that renders the edit profile view that allows the user
 * to edit his organization profile.
 */
const EditProfileView = props => {
  const {
    intl,
    fieldId,
    form,
    currentOrganizationProfile,
    submitInProgress,
    submitDisabled,
    setActiveOrganizationId,
    setActionType,
  } = props;

  // name
  const nameLabel = intl.formatMessage({
    id: 'OrganizationSettingsForm.nameLabel',
  });
  const namePlaceholder = intl.formatMessage({
    id: 'OrganizationSettingsForm.namePlaceholder',
  });
  const nameRequiredMessage = intl.formatMessage({
    id: 'OrganizationSettingsForm.nameRequired',
  });
  const nameRequired = validators.required(nameRequiredMessage);

  // website
  const websiteLabel = intl.formatMessage({
    id: 'OrganizationSettingsForm.websiteLabel',
  });
  const websitePlaceholder = intl.formatMessage({
    id: 'OrganizationSettingsForm.websitePlaceholder',
  });
  const websiteValidMessage = intl.formatMessage({
    id: 'OrganizationSettingsPage.websiteValid',
  });
  const websiteValid = validators.validURL(websiteValidMessage);

  // bio
  const bioLabel = intl.formatMessage({
    id: 'OrganizationSettingsForm.bioLabel',
  });
  const bioPlaceholder = intl.formatMessage({
    id: 'OrganizationSettingsForm.bioPlaceholder',
  });
  const bioRequiredMessage = intl.formatMessage({
    id: 'OrganizationSettingsForm.bioRequired',
  });
  const bioMinLengthMessage = intl.formatMessage(
    {
      id: 'OrganizationSettingsForm.bioMinLength',
    },
    { maxLength: BIO_MIN_LENGTH }
  );
  const bioMaxLengthMessage = intl.formatMessage(
    {
      id: 'OrganizationSettingsForm.bioMaxLength',
    },
    { maxLength: BIO_MAX_LENGTH }
  );
  const bioRequired = validators.required(bioRequiredMessage);
  const bioMinLength = validators.minLength(bioMinLengthMessage, BIO_MIN_LENGTH);
  const bioMaxLength = validators.maxLength(bioMaxLengthMessage, BIO_MAX_LENGTH);

  // founded
  const foundedLabel = intl.formatMessage({
    id: 'OrganizationSettingsForm.foundedLabel',
  });
  const foundedPlaceholder = intl.formatMessage({
    id: 'OrganizationSettingsForm.foundedPlaceholder',
  });

  // founded
  const founderLabel = intl.formatMessage({
    id: 'OrganizationSettingsForm.founderLabel',
  });
  const founderPlaceholder = intl.formatMessage({
    id: 'OrganizationSettingsForm.founderPlaceholder',
  });

  // instagram
  const instagramLinkMessage = intl.formatMessage({
    id: 'OrganizationSettingsForm.instagramLink',
  });
  const instagramLinkPlaceholderMessage = intl.formatMessage({
    id: 'OrganizationSettingsForm.instagramLinkPlaceholder',
  });
  const instagramLinkValidMessage = intl.formatMessage({
    id: 'OrganizationSettingsForm.instagramLinkValidMessage',
  });

  const profileImage = currentOrganizationProfile?.profileImage;

  const imageFromFile =
    profileImage && profileImage.lastModified ? (
      <ImageFromFile
        id={profileImage.name}
        rootClassName={css.uploadedImage}
        aspectRatioClassName={css.squareAspectRatio}
        file={profileImage}
      />
    ) : null;

  const avatarComponent =
    profileImage && profileImage?.id ? (
      <div className={css.uploadedImage}>
        <div className={css.squareAspectRatio}>
          <img className={css.rootForImage} src={profileImage.link} alt={profileImage.name} />
        </div>
      </div>
    ) : null;

  const chooseAvatarLabel =
    imageFromFile || avatarComponent ? (
      <div className={css.avatarContainer}>
        {imageFromFile}
        {avatarComponent}
        <div className={css.changeAvatar}>
          <IconEdit className={css.changeAvatarIcon} />
          <FormattedMessage id="ProfileImageForm.changeAvatar" />
        </div>
      </div>
    ) : (
      <div className={css.avatarPlaceholder}>
        <div className={css.avatarPlaceholderText}>
          <FormattedMessage id="ProfileImageForm.addYourProfilePicture" />
        </div>
        <div className={css.avatarPlaceholderTextMobile}>
          <FormattedMessage id="ProfileImageForm.addYourProfilePictureMobile" />
        </div>
      </div>
    );

  return (
    <div key={fieldId} className={css.editOrganizationProfile}>
      <div className={css.editOrganizationProfileSection}>
        <IconPhoto className={css.editOrganizationProfileSectionIcon} />
        <div className={css.editOrganizationProfileSectionContent}>
          <h3 className={css.editOrganizationProfileSectionTitle}>
            <FormattedMessage id="OrganizationSettingsForm.organizationImageHeading" />
          </h3>
          <div className={css.editOrganizationProfileFields}>
            <Field
              accept={ACCEPT_IMAGES}
              id={`${fieldId}.profileImage`}
              name={`${fieldId}.profileImage`}
              label={chooseAvatarLabel}
              type="file"
              form={null}
              disabled={false}
            >
              {fieldProps => {
                const { accept, id, input, label, disabled } = fieldProps;
                const { name, type } = input;
                const onChange = async e => {
                  const file = e.target.files[0];

                  if (file) {
                    form.change(`${fieldId}.profileImage`, file);
                    form.blur(`${fieldId}.profileImage`);
                  }
                };

                return (
                  <div className={css.editOrganizationProfileImageWrapper}>
                    <label className={css.label} htmlFor={id}>
                      {label}
                    </label>
                    <input
                      accept={accept}
                      id={id}
                      name={name}
                      className={css.editOrganizationProfileImageInput}
                      disabled={disabled}
                      onChange={onChange}
                      type={type}
                    />
                  </div>
                );
              }}
            </Field>
          </div>
        </div>
      </div>

      <div className={css.editOrganizationProfileSection}>
        <IconProfile className={css.editOrganizationProfileSectionIcon} />
        <div className={css.editOrganizationProfileSectionContent}>
          <h3 className={css.editOrganizationProfileSectionTitle}>
            <FormattedMessage id="OrganizationSettingsForm.aboutOrganizationHeading" />
          </h3>
          <div className={css.editOrganizationProfileFields}>
            <FieldTextInput
              className={css.editOrganizationProfileField}
              type="text"
              id={`${fieldId}.name`}
              name={`${fieldId}.name`}
              label={nameLabel}
              placeholder={namePlaceholder}
              validate={nameRequired}
            />
            <FieldTextInput
              className={css.editOrganizationProfileField}
              type="text"
              id={`${fieldId}.website`}
              name={`${fieldId}.website`}
              label={websiteLabel}
              placeholder={websitePlaceholder}
              validate={websiteValid}
            />
            <FieldSelectInput
              className={css.editOrganizationProfileField}
              id={`${fieldId}.founded`}
              name={`${fieldId}.founded`}
              options={yearsOptions}
              label={foundedLabel}
              placeholder={foundedPlaceholder}
            />
            <FieldTextInput
              className={css.editOrganizationProfileField}
              type="text"
              id={`${fieldId}.founder`}
              name={`${fieldId}.founder`}
              label={founderLabel}
              placeholder={founderPlaceholder}
            />
            <FieldTextInput
              className={css.editOrganizationProfileField}
              type="text"
              id={`${fieldId}.instagramLink`}
              name={`${fieldId}.instagramLink`}
              label={instagramLinkMessage}
              placeholder={instagramLinkPlaceholderMessage}
              validate={validators.validInstagramLink(instagramLinkValidMessage)}
            />
            <FieldTextInput
              className={css.editOrganizationProfileField}
              type="textarea"
              id={`${fieldId}.bio`}
              name={`${fieldId}.bio`}
              label={bioLabel}
              placeholder={bioPlaceholder}
              validate={validators.composeValidators(bioRequired, bioMinLength, bioMaxLength)}
            />
          </div>
          <div className={css.editOrganizationProfileFooter}>
            <div className={css.editOrganizationProfileButtonsWrapper}>
              <EditWizardButton
                className={css.editOrganizationProfileCancelButton}
                type="button"
                onClick={() => {
                  setActiveOrganizationId(null);
                  setActionType(null);
                }}
              >
                <FormattedMessage id="OrganizationSettingsForm.cancel" />
              </EditWizardButton>
              <EditWizardButton
                className={css.editOrganizationProfileSubmitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="OrganizationSettingsForm.saveChanges" />
              </EditWizardButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrganizationProfiles = props => {
  const {
    intl,
    currentUser,
    listings,
    // form
    form,
    submitInProgress,
    submitDisabled,
    // UI
    organizationProfilesFromValues,
    hasOrganizations,
    isSingleOrganizationView,
    activeOrganizationId,
    selectedOrganization,
    // funcs
    setActiveOrganizationId,
    setActionType,
    toggleInvalidDeleteModal,
  } = props;

  if (!hasOrganizations) {
    return null;
  }

  return (
    <FieldArray name="organizationProfiles">
      {({ fields }) => {
        const organizationProfilesClasses = classNames(css.organizationProfiles, {
          [css.editOrganizationProfiles]: isSingleOrganizationView,
        });
        return (
          <div>
            <div className={organizationProfilesClasses}>
              {fields.map((name, index) => {
                const currentOrganizationProfile = organizationProfilesFromValues[index];

                const isProfileInView = activeOrganizationId === index;
                const shouldShowDefaultProfileView = !isSingleOrganizationView;

                const hasListings = !!listings?.find(
                  l => l.attributes.publicData.organizationId === currentOrganizationProfile?.id
                );

                return shouldShowDefaultProfileView ? (
                  <DefaultProfileView
                    key={`${name}.${index}`}
                    currentUser={currentUser}
                    currentOrganizationProfile={currentOrganizationProfile}
                    selectedOrganization={selectedOrganization}
                    onEditProfile={() => {
                      setActiveOrganizationId(index);
                      setActionType(ACTION_TYPE_EDIT);
                    }}
                    onDeleteProfile={async () => {
                      if (
                        hasListings ||
                        selectedOrganization.id === currentOrganizationProfile.id
                      ) {
                        toggleInvalidDeleteModal(true);
                      } else {
                        const deleteFn = async () => {
                          await fields.remove(index);
                        };

                        if (props.onSubmitDeleteProfile) {
                          const filteredProfiles = organizationProfilesFromValues?.filter(
                            (f, itemIndex) => itemIndex !== index
                          );
                          props.onSubmitDeleteProfile(
                            filteredProfiles,
                            currentOrganizationProfile,
                            deleteFn
                          );
                        }
                      }
                    }}
                  />
                ) : isProfileInView ? (
                  <EditProfileView
                    key={`${name}.${index}`}
                    fieldId={name}
                    form={form}
                    currentOrganizationProfile={currentOrganizationProfile}
                    intl={intl}
                    submitInProgress={submitInProgress}
                    submitDisabled={submitDisabled}
                    setActiveOrganizationId={setActiveOrganizationId}
                    setActionType={setActionType}
                  />
                ) : null;
              })}
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
};

export default OrganizationProfiles;
