import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, oneOfType, bool, node, string, number, object, func } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { IconClose, InlineTextButton, NamedLink, NamedRedirect } from '..';
import { ensureCurrentUser } from '../../util/data';
import {
  getSelectedOrganizationProfile,
  getSelectedOrganizationProfileIdFromExtendedData,
} from '../../util/organizations';
import { useConfirmationModal, useGettingStartedRemoval } from '../../util/hooks';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration, { DASHBOARD_PAGES } from '../../routeConfiguration';
import { selectOrganizationProfile } from '../../ducks/organizations.duck';
import classNames from 'classnames';

import GettingStartedIcon from './Icons/GettingStartedIcon';
import ManageListingsIcon from './Icons/ManageListingsIcon';
import StreamIcon from './Icons/StreamIcon';
import InboxIcon from './Icons/InboxIcon';
import AnalyticsIcon from './Icons/AnalyticsIcon';
import OrganizationSettingsIcon from './Icons/OrganizationSettingsIcon';
import OrganizationMenu from './OrganizationMenu/OrganizationMenu';
import css from './DashboardWrapper.module.css';

const INBOX_TAB_PAGES = ['InboxPage', 'QuickRepliesPage'];
const ORGANIZATION_SETTINGS_PAGES = [
  'OrganizationSettingsPage',
  'PaymentHistoryPage',
  'TeamPermissionPage',
];

const Tab = props => {
  const { tab } = props;

  const classes = classNames(css.sideNavItem, {
    [css.sideNavItemActive]: tab.selected,
    [css.sideNavItemDisabled]: tab.disabled,
  });

  const tabProps = {
    key: tab.id,
    className: classes,
  };
  const linkTab = !tab.disabled && !tab.linkDisabled && !tab.selected;

  return tab.hidden ? null : linkTab ? (
    <NamedLink {...tabProps} {...tab.linkProps}>
      {tab.icon}
      {tab.text}
    </NamedLink>
  ) : (
    <span {...tabProps}>
      {tab.icon}
      {tab.text}
    </span>
  );
};

const DashboardWrapperComponent = props => {
  const {
    rootClassName,
    className,
    intl,
    history,
    children,
    currentPage,
    currentUser,
    currentUserLoaded,
    currentUserHasListings,
    providerNotificationCount,
    organizationProfiles,
    onSelectOrganizationProfile,
    selectOrganizationProfileInProgress,
    selectOrganizationProfileError,
    selectedOrganizationProfileId,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const selectedOrganization = getSelectedOrganizationProfile(
    organizationProfiles,
    selectedOrganizationProfileId
  );

  const showOrganizationMenu = organizationProfiles && selectedOrganization;
  const providerHasInboxNotifications = providerNotificationCount > 0;

  const { open: onOpenConfirmationModal, modalElement } = useConfirmationModal();
  const {
    isRemoved: isGettingStartedPageRemoved,
    removePage: removeGettingStartedPage,
  } = useGettingStartedRemoval(currentUser?.id?.uuid);

  const onRemoveGettingStartedPage = async () => {
    const confirm = await onOpenConfirmationModal({
      id: 'GettingStartedPage.removePageModal',
      title: intl.formatMessage({ id: 'GettingStartedPage.removePageModalTitle' }),
      description: intl.formatMessage({ id: 'GettingStartedPage.removePageModalDescription' }),
      useVariationColor: true,
    });

    if (confirm) {
      removeGettingStartedPage();
      history.push(createResourceLocatorString('ManageListingsPage', routeConfiguration()));
    }
  };

  const tabs = [
    {
      text: (
        <span className={css.gettingStartedTabTitle}>
          {intl.formatMessage({ id: 'DashboardWrapper.gettingStartedTabTitle' })}
          {currentPage === 'GettingStartedPage' ? (
            <InlineTextButton
              className={css.closeButton}
              type="button"
              onClick={onRemoveGettingStartedPage}
            >
              <IconClose className={css.closeIcon} />
            </InlineTextButton>
          ) : null}
        </span>
      ),
      selected: currentPage === 'GettingStartedPage',
      hidden: isGettingStartedPageRemoved,
      id: 'GettingStartedPage',
      icon: <GettingStartedIcon />,
      linkProps: {
        name: 'GettingStartedPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.listingsTabTitle' }),
      selected: currentPage === 'ManageListingsPage',
      id: 'ManageListingsPage',
      icon: <ManageListingsIcon />,
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.streamTabTitle' }),
      selected: currentPage === 'StreamPage',
      id: 'StreamPageTab',
      icon: <StreamIcon />,
      linkProps: {
        name: 'StreamPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.inboxTabTitle' }),
      selected: INBOX_TAB_PAGES.includes(currentPage),
      id: 'InboxPageTab',
      icon: <InboxIcon isFilled={providerHasInboxNotifications} />,
      linkProps: {
        name: 'InboxPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.audienceTabTitle' }),
      selected: currentPage === 'AnalyticsPage' || currentPage === 'MembersPage',
      id: 'AnalyticsPageTab',
      icon: <AnalyticsIcon />,
      disabled: false,
      linkProps: {
        name: 'AnalyticsPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.organizationSettingsTabTitle' }),
      selected: ORGANIZATION_SETTINGS_PAGES.includes(currentPage),
      id: 'OrganizationSettingsPageTab',
      icon: <OrganizationSettingsIcon />,
      linkProps: {
        name: 'OrganizationSettingsPage',
      },
      linkDisabled: ORGANIZATION_SETTINGS_PAGES.includes(currentPage),
    },
  ];

  if (currentUserLoaded && !currentUserHasListings && !DASHBOARD_PAGES.includes(currentPage)) {
    return <NamedRedirect name="LandingPage" />;
  }

  return (
    <div className={classes}>
      <div className={css.sideNav}>
        {showOrganizationMenu ? (
          <OrganizationMenu
            currentPage={currentPage}
            organizationProfiles={organizationProfiles}
            selectedOrganization={selectedOrganization}
            onSelectOrganizationProfile={onSelectOrganizationProfile}
            selectOrganizationProfileInProgress={selectOrganizationProfileInProgress}
            selectOrganizationProfileError={selectOrganizationProfileError}
          />
        ) : null}
        <div className={css.sideNavItems}>
          {tabs.map(tab => {
            return <Tab key={tab.id} tab={tab} />;
          })}
        </div>
      </div>
      <div className={css.sideContent}>{children}</div>
      {modalElement}
    </div>
  );
};

DashboardWrapperComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentPage: null,
  currentUserHasListings: false,
  providerNotificationCount: 0,
  selectedOrganization: null,
  organizationProfiles: [],
  selectOrganizationProfileInProgress: false,
  selectOrganizationProfileError: null,
  selectedOrganizationProfileId: null,
};

DashboardWrapperComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentPage: string,
  currentUserHasListings: bool.isRequired,
  providerNotificationCount: number,
  selectedOrganization: oneOfType([propTypes.organization, propTypes.ownOrganization]),
  organizationProfiles: arrayOf(propTypes.organization),
  selectOrganizationProfileInProgress: bool.isRequired,
  selectOrganizationProfileError: propTypes.error,
  selectedOrganizationProfileId: string,
  onSelectOrganizationProfile: func.isRequired,
  history: object.isRequired,
  location: object.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserHasListings, providerNotificationCount } = state.user;
  const {
    organizationProfiles,
    selectOrganizationProfileInProgress,
    selectOrganizationProfileError,
  } = state.organizations;

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!ensuredCurrentUser.id;

  return {
    currentUser,
    currentUserLoaded,
    currentUserHasListings,
    providerNotificationCount,
    organizationProfiles,
    selectOrganizationProfileInProgress,
    selectOrganizationProfileError,
    selectedOrganizationProfileId: getSelectedOrganizationProfileIdFromExtendedData(currentUser),
  };
};

const mapDispatchToProps = dispatch => ({
  onSelectOrganizationProfile: organizationProfileId =>
    dispatch(selectOrganizationProfile(organizationProfileId)),
});

const DashboardWrapper = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(DashboardWrapperComponent);

export default DashboardWrapper;
