import omit from 'lodash/omit';
import { types as sdkTypes, util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchOrganizationProfiles } from '../../ducks/organizations.duck';
import { fetchStripeAccount } from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { searchListings } from '../SearchPage/SearchPage.duck';
import { showListingInvites } from '../../ducks/invites.duck';
import * as log from '../../util/log';
import config from '../../config';
import { googleStorageAPI, integrationAPI } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';

const { UUID } = sdkTypes;

// Helper to get the correct public data property based on imageType
const getPromotePublicDataProperty = imageType => {
  return imageType === 'poster' ? 'posterImageId' : 'coverImageId';
};

// Helper to select the correct SDK based on ownership
const getListingSdk = (isOwnListing, sdk) => {
  return isOwnListing ? sdk.ownListings : integrationAPI.listings;
};

// Return an array of image ids
const imageIds = images => {
  // For newly uploaded image the UUID can be found from "img.imageId"
  // and for existing listing images the id is "img.id"

  return images ? images.map(img => img.imageId || img.id) : null;
};

// After listing creation & update, we want to make sure that uploadedImages state is cleaned
const updateUploadedImagesState = (state, payload) => {
  const { uploadedImages, uploadedImagesOrder } = state;

  // Images attached to listing entity
  const attachedImages = payload?.data?.relationships?.images?.data || [];
  const attachedImageUUIDStrings = attachedImages.map(img => img.id.uuid);

  // Uploaded images (which are propably not yet attached to listing)
  const unattachedImages = Object.values(state.uploadedImages);
  const duplicateImageEntities = unattachedImages.filter(unattachedImg =>
    attachedImageUUIDStrings.includes(unattachedImg.imageId?.uuid)
  );

  return duplicateImageEntities.length > 0
    ? {
        uploadedImages: {},
        uploadedImagesOrder: [],
      }
    : {
        uploadedImages,
        uploadedImagesOrder,
      };
};

const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result.data });
const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const MARK_TAB_UPDATED = 'app/EditListingPage/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/EditListingPage/CLEAR_UPDATED_TAB';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/EditListingPage/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/EditListingPage/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/EditListingPage/CREATE_LISTING_DRAFT_ERROR';

export const PUBLISH_LISTING_REQUEST = 'app/EditListingPage/PUBLISH_LISTING_REQUEST';
export const PUBLISH_LISTING_SUCCESS = 'app/EditListingPage/PUBLISH_LISTING_SUCCESS';
export const PUBLISH_LISTING_ERROR = 'app/EditListingPage/PUBLISH_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/EditListingPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/EditListingPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/EditListingPage/UPDATE_LISTING_ERROR';

export const SHOW_LISTINGS_REQUEST = 'app/EditListingPage/SHOW_LISTINGS_REQUEST';
export const SHOW_LISTINGS_SUCCESS = 'app/EditListingPage/SHOW_LISTINGS_SUCCESS';
export const SHOW_LISTINGS_ERROR = 'app/EditListingPage/SHOW_LISTINGS_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/EditListingPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/EditListingPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/EditListingPage/UPLOAD_IMAGE_ERROR';

export const REMOVE_LISTING_IMAGE = 'app/EditListingPage/REMOVE_LISTING_IMAGE';

export const UPLOAD_VIDEO_REQUEST = 'app/EditListingPage/UPLOAD_VIDEO_REQUEST';
export const UPLOAD_VIDEO_SUCCESS = 'app/EditListingPage/UPLOAD_VIDEO_SUCCESS';
export const UPLOAD_VIDEO_ERROR = 'app/EditListingPage/UPLOAD_VIDEO_ERROR';

export const REMOVE_LISTING_VIDEO = 'app/EditListingPage/REMOVE_LISTING_VIDEO';

export const PROMOTE_IMAGE_REQUEST = 'app/EditListingPage/PROMOTE_IMAGE_REQUEST';
export const PROMOTE_IMAGE_SUCCESS = 'app/EditListingPage/PROMOTE_IMAGE_SUCCESS';
export const PROMOTE_IMAGE_ERROR = 'app/EditListingPage/PROMOTE_IMAGE_ERROR';

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  createListingDraftError: null,
  publishingListing: null,
  publishListingError: null,
  updateListingError: null,
  showListingError: null,
  uploadImageError: null,
  uploadVideoError: null,
  createListingDraftInProgress: false,
  redirectToListing: false,
  uploadedImages: {},
  uploadedImagesOrder: [],
  removedImageIds: [],
  uploadedVideos: {},
  uploadedVideosOrder: [],
  removedVideoIds: [],
  fetchExceptionsError: null,
  fetchExceptionsInProgress: false,
  addExceptionError: null,
  addExceptionInProgress: false,
  deleteExceptionError: null,
  deleteExceptionInProgress: false,
  listingDraft: null,
  updatedTab: null,
  updateInProgress: false,
  promoteImageInProgress: false,
  promoteImageError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateListingError: null };

    case CREATE_LISTING_DRAFT_REQUEST:
      return {
        ...state,
        createListingDraftInProgress: true,
        createListingDraftError: null,
        listingDraft: null,
      };

    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        ...updateUploadedImagesState(state, payload),
        createListingDraftInProgress: false,
        listingDraft: payload.data,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingDraftInProgress: false,
        createListingDraftError: payload,
      };

    case PUBLISH_LISTING_REQUEST:
      return {
        ...state,
        publishingListing: payload.listingId,
        publishListingError: null,
      };
    case PUBLISH_LISTING_SUCCESS:
      return {
        ...state,
        redirectToListing: true,
        publishingListing: null,
        createListingDraftError: null,
        updateListingError: null,
        showListingError: null,
        uploadImageError: null,
        createListingDraftInProgress: false,
        updateInProgress: false,
      };
    case PUBLISH_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        publishingListing: null,
        publishListingError: {
          listingId: state.publishingListing,
          error: payload,
        },
      };
    }

    case UPDATE_LISTING_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateListingError: null,
      };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, ...updateUploadedImagesState(state, payload), updateInProgress: false };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };

    case SHOW_LISTINGS_REQUEST:
      return { ...state, showListingInProgress: true, showListingError: null };
    case SHOW_LISTINGS_SUCCESS:
      return { ...state, showListingInProgress: false, images: {}, imageOrder: [] };

    case SHOW_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        showListingInProgress: false,
        showListingError: payload,
        redirectToListing: false,
      };

    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const uploadedImages = {
        ...state.uploadedImages,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        uploadedImages,
        uploadedImagesOrder: state.uploadedImagesOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id', attributes, type }
      const { id, ...rest } = payload;
      const uploadedImages = { ...state.uploadedImages, [id]: { id, ...rest } };
      return { ...state, uploadedImages };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const uploadedImagesOrder = state.uploadedImagesOrder.filter(i => i !== id);
      const uploadedImages = omit(state.uploadedImages, id);
      return { ...state, uploadedImagesOrder, uploadedImages, uploadImageError: error };
    }

    case REMOVE_LISTING_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.uploadedImages[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const uploadedImages = omit(state.uploadedImages, id);
      const uploadedImagesOrder = state.uploadedImagesOrder.filter(i => i !== id);

      return { ...state, uploadedImages, uploadedImagesOrder, removedImageIds };
    }

    case UPLOAD_VIDEO_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const uploadedVideos = {
        ...state.uploadedVideos,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        uploadedVideos,
        uploadedVideosOrder: state.uploadedVideosOrder.concat([payload.params.id]),
        uploadVideoError: null,
      };
    }
    case UPLOAD_VIDEO_SUCCESS: {
      // payload.params: { id: 'tempId', videoId: 'some-real-id', attributes, type }
      const { id, ...rest } = payload;
      const uploadedVideos = { ...state.uploadedVideos, [id]: { id, ...rest } };
      return { ...state, uploadedVideos };
    }
    case UPLOAD_VIDEO_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const uploadedVideosOrder = state.uploadedVideosOrder.filter(i => i !== id);
      const uploadedVideos = omit(state.uploadedVideos, id);
      return { ...state, uploadedVideosOrder, uploadedVideos, uploadVideoError: error };
    }

    case REMOVE_LISTING_VIDEO: {
      const id = payload.videoId;

      // Only mark the video removed if it hasn't been added to the
      // listing already
      const removedVideoIds = state.uploadedVideos[id]
        ? state.removedVideoIds
        : state.removedVideoIds.concat(id);

      // Always remove from the draft since it might be a new video to
      // an existing listing.
      const uploadedVideos = omit(state.uploadedVideos, id);
      const uploadedVideosOrder = state.uploadedVideosOrder.filter(i => i !== id);

      return { ...state, uploadedVideos, uploadedVideosOrder, removedVideoIds };
    }

    case PROMOTE_IMAGE_REQUEST:
      return { ...state, promoteImageInProgress: true, promoteImageError: null };
    case PROMOTE_IMAGE_SUCCESS:
      return {
        ...state,
        promoteImageInProgress: false,
        promoteImageError: null,
      };
    case PROMOTE_IMAGE_ERROR:
      return { ...state, promoteImageInProgress: false, promoteImageError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

export const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

export const removeListingImage = imageId => ({
  type: REMOVE_LISTING_IMAGE,
  payload: { imageId },
});

export const removeListingVideo = videoId => ({
  type: REMOVE_LISTING_VIDEO,
  payload: { videoId },
});

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: ownListings.create
export const createListingDraft = requestAction(CREATE_LISTING_DRAFT_REQUEST);
export const createListingDraftSuccess = successAction(CREATE_LISTING_DRAFT_SUCCESS);
export const createListingDraftError = errorAction(CREATE_LISTING_DRAFT_ERROR);

// SDK method: ownListings.publish
export const publishListing = requestAction(PUBLISH_LISTING_REQUEST);
export const publishListingSuccess = successAction(PUBLISH_LISTING_SUCCESS);
export const publishListingError = errorAction(PUBLISH_LISTING_ERROR);

// SDK method: ownListings.update
export const updateListing = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

// SDK method: ownListings.show
export const showListing = requestAction(SHOW_LISTINGS_REQUEST);
export const showListingSuccess = successAction(SHOW_LISTINGS_SUCCESS);
export const showListingError = errorAction(SHOW_LISTINGS_ERROR);

// SDK method: images.upload
export const uploadImageRequest = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

// Google Storage API: googleStorageAPI.videos.upload
export const uploadVideoRequest = requestAction(UPLOAD_VIDEO_REQUEST);
export const uploadVideoSuccess = successAction(UPLOAD_VIDEO_SUCCESS);
export const uploadVideoError = errorAction(UPLOAD_VIDEO_ERROR);

export const promoteImageRequest = requestAction(PROMOTE_IMAGE_REQUEST);
export const promoteImageSuccess = successAction(PROMOTE_IMAGE_SUCCESS);
export const promoteImageError = errorAction(PROMOTE_IMAGE_ERROR);

// ================ Thunk ================ //

export function requestShowListing(actionPayload, isOwnListing) {
  return async (dispatch, getState, sdk) => {
    dispatch(showListing(actionPayload));

    const integrationResponse = await integrationAPI.listings.show({
      ...actionPayload,
      id: actionPayload.id.uuid,
    });
    const currentListing = denormalisedResponseEntities(integrationResponse.data)[0];

    const { currentUser } = getState().user;
    const isOwn = isOwnListing || currentUser?.id?.uuid === currentListing.author.id.uuid;

    if (isOwn) {
      return sdk.ownListings
        .show(actionPayload)
        .then(response => {
          // EditListingPage fetches new listing data, which also needs to be added to global data
          dispatch(addMarketplaceEntities(response));
          dispatch(showListingSuccess(response));
          return response;
        })
        .catch(e => dispatch(showListingError(storableError(e))));
    } else {
      dispatch(addMarketplaceEntities(integrationResponse.data));
      dispatch(showListingSuccess(integrationResponse.data));
      return integrationResponse.data;
    }
  };
}

export function requestCreateListingDraft(data) {
  return async (dispatch, getState, sdk) => {
    dispatch(createListingDraft(data));

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': [
        'variants.portrait-crop',
        'variants.portrait-crop2x',
        'variants.portrait-crop4x',
      ],
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 250,
        h: 300,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 280,
        h: 392,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop4x': sdkUtil.objectQueryString({
        w: 560,
        h: 784,
        fit: 'crop',
      }),
    };

    return sdk.ownListings
      .createDraft(data, queryParams)
      .then(response => {
        //const id = response.data.data.id.uuid;

        // Add the created listing to the marketplace data
        dispatch(addMarketplaceEntities(response));

        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingDraftSuccess(response));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: data });
        return dispatch(createListingDraftError(storableError(e)));
      });
  };
}

export const requestPublishListingDraft = listingId => (dispatch, getState, sdk) => {
  dispatch(publishListing(listingId));

  return sdk.ownListings
    .publishDraft({ id: listingId }, { expand: true })
    .then(response => {
      // Add the created listing to the marketplace data
      dispatch(addMarketplaceEntities(response));
      dispatch(publishListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(publishListingError(storableError(e)));
    });
};

export function requestImageUpload(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    const queryParams = {
      expand: true,
      'fields.image': [
        'variants.portrait-crop',
        'variants.portrait-crop2x',
        'variants.portrait-crop4x',
      ],
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 250,
        h: 300,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 280,
        h: 392,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop4x': sdkUtil.objectQueryString({
        w: 560,
        h: 784,
        fit: 'crop',
      }),
    };

    dispatch(uploadImageRequest(actionPayload));
    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then(resp => {
        const img = resp.data.data;
        // Uploaded image has an existing id that refers to file
        // The UUID was created as a consequence of this upload call - it's saved to imageId property
        return dispatch(
          uploadImageSuccess({ data: { ...img, id, imageId: img.id, file: actionPayload.file } })
        );
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export function requestVideoUpload(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;

    dispatch(uploadVideoRequest(actionPayload));

    const formData = new FormData();
    formData.append('video', actionPayload.file);

    return googleStorageAPI.videos
      .upload(formData)
      .then(resp => {
        const video = resp;
        // Uploaded video has an existing id that refers to file
        return dispatch(
          uploadVideoSuccess({
            data: { ...video, id, videoId: video.id, file: actionPayload.file },
          })
        );
      })
      .catch(e => dispatch(uploadVideoError({ id, error: storableError(e) })));
  };
}

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
export function requestUpdateListing(tab, data, isOwnListing) {
  return async (dispatch, getState, sdk) => {
    dispatch(updateListing(data));

    const { images } = data;
    const imageProperty = images ? { images: imageIds(images) } : {};

    const updateValues = { ...data, ...imageProperty };

    const imageVariantParams = {
      'fields.image': [
        'variants.portrait-crop',
        'variants.portrait-crop2x',
        'variants.portrait-crop4x',
      ],
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 250,
        h: 300,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 280,
        h: 392,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop4x': sdkUtil.objectQueryString({
        w: 560,
        h: 784,
        fit: 'crop',
      }),
    };

    const includeParams = {
      expand: true,
      include: ['author', 'images'],
      ...imageVariantParams,
    };

    const api = isOwnListing ? sdk.ownListings : integrationAPI.listings;

    try {
      const updateResponse = await api.update(updateValues, includeParams);

      dispatch(markTabUpdated(tab));
      dispatch(addMarketplaceEntities(updateResponse));
      dispatch(updateListingSuccess(updateResponse));

      return updateResponse;
    } catch (e) {
      log.error(e, 'update-listing-failed', { listingData: data });
      dispatch(updateListingError(storableError(e)));
      throw e;
    }
  };
}

export const promoteImage = (listingId, imageId, imageType, isOwnListing) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    dispatch(promoteImageRequest());

    const publicDataProperty = getPromotePublicDataProperty(imageType);
    const listingSdk = getListingSdk(isOwnListing, sdk);

    const updateResponse = await listingSdk.update(
      {
        id: listingId,
        publicData: {
          [publicDataProperty]: imageId ? imageId.uuid : null,
        },
      },
      { expand: true }
    );

    dispatch(addMarketplaceEntities(updateResponse));
    dispatch(promoteImageSuccess(updateResponse));

    return updateResponse;
  } catch (error) {
    dispatch(promoteImageError(storableError(error)));
  }
};

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  dispatch(clearUpdatedTab());

  return dispatch(fetchOrganizationProfiles()).then(async () => {
    const { id, type } = params;

    if (type === 'new') {
      // No need to listing data when creating a new listing
      try {
        const response = await Promise.all([dispatch(fetchCurrentUser())]);
        const currentUser = getState().user.currentUser;
        if (currentUser && currentUser.stripeAccount) {
          dispatch(fetchStripeAccount());
        }
        return response;
      } catch (error) {
        console.log(error);
      }
    }

    const payload = {
      id: new UUID(id),
      include: ['author', 'images'],
      'fields.image': [
        'variants.portrait-crop',
        'variants.portrait-crop2x',
        'variants.portrait-crop4x',
      ],
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 250,
        h: 300,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 280,
        h: 392,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop4x': sdkUtil.objectQueryString({
        w: 560,
        h: 784,
        fit: 'crop',
      }),
    };

    try {
      const response = await Promise.all([
        dispatch(requestShowListing(payload)),
        dispatch(fetchCurrentUser()),
        dispatch(searchListings({ pub_type: config.listingTypes['show'], perPage: 100 })),
        dispatch(showListingInvites(id)),
      ]);
      const currentUser = getState().user.currentUser;
      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  });
};
