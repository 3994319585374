import toast, { Toaster } from 'react-hot-toast';

const NOTIFICATION_POSITION_TOP_LEFT = 'top-left';
const NOTIFICATION_POSITION_TOP_CENTER = 'top-center';
const NOTIFICATION_POSITION_TOP_RIGHT = 'top-right';
const NOTIFICATION_POSITION_BOTTOM_LEFT = 'bottom-left';
const NOTIFICATION_POSITION_BOTTOM_CENTER = 'bottom-center';
const NOTIFICATION_POSITION_BOTTOM_RIGHT = 'bottom-right';

const NOTIFICATION_DELAY = 5000;

// Read more: https://react-hot-toast.com/docs/styling
const notificationSettings = {
  position: NOTIFICATION_POSITION_BOTTOM_CENTER,
  toastOptions: {
    duration: NOTIFICATION_DELAY,
    style: {
      color: '#0e0f0c', // var(--matterColorAnti);
      border: '1px solid #0e0f0c', // var(--matterColorNegative);
      boxShadow: 'none',
    },
  },
};

export {
  // provider
  Toaster as NotificationProvider,
  // notification alert fn
  toast as notification,
  // notification settings
  notificationSettings,
  // notification positions
  NOTIFICATION_POSITION_TOP_LEFT,
  NOTIFICATION_POSITION_TOP_CENTER,
  NOTIFICATION_POSITION_TOP_RIGHT,
  NOTIFICATION_POSITION_BOTTOM_LEFT,
  NOTIFICATION_POSITION_BOTTOM_CENTER,
  NOTIFICATION_POSITION_BOTTOM_RIGHT,
};
