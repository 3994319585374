import React, { useRef, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { ARRAY_ERROR } from 'final-form';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { nonEmptyArray, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { useConfirmationModal } from '../../util/hooks';

// Import shared components
import {
  Form,
  AspectRatioWrapper,
  NamedLink,
  EditWizardButton,
  PreviewListingButton,
  ListRenderer,
  IconPhoto,
  IconVideo,
} from '../../components';

// Import modules from this directory
import UploadButton from './UploadButton';
import ListingImage from './ListingImage';
import ListingVideo from './ListingVideo';
import css from './EditExperienceContentForm.module.css';

const ACCEPT_IMAGES = 'image/*';
const ACCEPT_VIDEOS = 'video/*';

/**
 * Determines the default tab index based on the active tab in the URL query string.
 *
 * @param {string} search - The search query string (e.g., "?activeTab=videos").
 * @param {Object} options - Configuration object for tab indices and active tab key.
 * @param {string} options.activeTabKey - The key in the query string to identify the active tab (default: "activeTab").
 * @param {string} options.videoTabValue - The query string value representing the video tab (default: "videos").
 * @param {number} options.tabImagesIndex - The default index for the images tab (default: 0).
 * @param {number} options.tabVideosIndex - The default index for the videos tab (default: 1).
 * @returns {number} The default tab index (0 for images, 1 for videos, or configured values).
 */
const getDefaultTabIndex = (
  search,
  {
    activeTabKey = 'activeTab',
    videoTabValue = 'videos',
    tabImagesIndex = 0,
    tabVideosIndex = 1,
  } = {}
) => {
  const queryParams = new URLSearchParams(search);
  const activeTab = queryParams.get(activeTabKey);

  return activeTab === videoTabValue ? tabVideosIndex : tabImagesIndex;
};

const ImageUploadError = props => {
  return props.uploadOverLimit ? (
    <p className={css.error}>
      <FormattedMessage id="EditExperienceContentForm.imageUploadFailed.uploadOverLimit" />
    </p>
  ) : props.uploadImageError ? (
    <p className={css.error}>
      <FormattedMessage id="EditExperienceContentForm.imageUploadFailed.uploadFailed" />
    </p>
  ) : null;
};

const VideoUploadError = props => {
  return props.uploadOverLimit ? (
    <p className={css.error}>
      <FormattedMessage id="EditExperienceContentForm.videoUploadFailed.uploadOverLimit" />
    </p>
  ) : props.uploadVideoError ? (
    <p className={css.error}>
      <FormattedMessage id="EditExperienceContentForm.videoUploadFailed.uploadFailed" />
    </p>
  ) : null;
};

// NOTE: PublishListingError and showListingError are here since Photos panel is the last visible panel
// before creating a new listing. If that order is changed, these should be changed too.
// Create and show listing errors are shown above submit button
const PublishListingError = props => {
  return props.error ? (
    <p className={css.error}>
      <FormattedMessage id="EditExperienceContentForm.publishListingFailed" />
    </p>
  ) : null;
};

const ShowListingError = props => {
  return props.error ? (
    <p className={css.error}>
      <FormattedMessage id="EditExperienceContentForm.showListingFailed" />
    </p>
  ) : null;
};

// Field component that uses file-input to allow user to select images or videos.
export const FieldAddFile = props => {
  const { formApi, onUploadHandler, aspectWidth = 1, aspectHeight = 1, inputRef, ...rest } = props;
  return (
    <Field form={null} {...rest}>
      {fieldprops => {
        const { accept, input, label, disabled: fieldDisabled } = fieldprops;
        const { name, type } = input;
        const onChange = e => {
          const file = e.target.files[0];
          formApi.change(name, file);
          formApi.blur(name);

          onUploadHandler(file);
        };
        const inputProps = { accept, id: name, name, onChange, type, ref: inputRef };
        return (
          <div className={css.addFileWrapper}>
            <AspectRatioWrapper width={aspectWidth} height={aspectHeight}>
              {fieldDisabled ? null : <input {...inputProps} className={css.addFileInput} />}
              <label htmlFor={name} className={css.addFile}>
                {label}
              </label>
            </AspectRatioWrapper>
          </div>
        );
      }}
    </Field>
  );
};

// Component that shows listing images from "images" field array
const FieldListingImage = props => {
  const {
    name,
    intl,
    controlMenuOpen,
    onToggleControlMenu,
    onRemoveImage,
    onPromoteImage,
    promoteImageInProgress,
    promoteImageError,
    listingCoverImageId,
    aspectWidth,
    aspectHeight,
    variantPrefix,
  } = props;
  return (
    <Field name={name}>
      {fieldProps => {
        const { input } = fieldProps;
        const image = input.value;

        const isMenuOpen =
          !!controlMenuOpen &&
          (controlMenuOpen?.id?.uuid === image?.id?.uuid || controlMenuOpen.id?.uuid === image?.id);

        return image ? (
          <ListingImage
            image={image}
            key={image?.id?.uuid || image?.id}
            className={css.thumbnail}
            savedImageAltText={intl.formatMessage({
              id: 'EditExperienceContentForm.savedImageAltText',
            })}
            isMenuOpen={isMenuOpen}
            onToggleMenu={onToggleControlMenu}
            onRemoveImage={() => onRemoveImage(image?.id)}
            onPromoteImage={onPromoteImage}
            promoteImageInProgress={promoteImageInProgress}
            promoteImageError={promoteImageError}
            listingCoverImageId={listingCoverImageId}
            aspectWidth={aspectWidth}
            aspectHeight={aspectHeight}
            variantPrefix={variantPrefix}
          />
        ) : null;
      }}
    </Field>
  );
};

// Component that shows listing videos from "vidos" field array
const FieldListingVideo = props => {
  const {
    name,
    controlMenuOpen,
    onToggleControlMenu,
    onRemoveVideo,
    aspectWidth,
    aspectHeight,
  } = props;

  return (
    <Field name={name}>
      {fieldProps => {
        const { input } = fieldProps;
        const video = input.value;

        const isMenuOpen =
          !!controlMenuOpen &&
          (controlMenuOpen?.id === video.id || controlMenuOpen?.id === video.videoId);

        return video ? (
          <ListingVideo
            video={video}
            key={video.id}
            className={css.thumbnail}
            isMenuOpen={isMenuOpen}
            onToggleMenu={onToggleControlMenu}
            onRemoveVideo={() => onRemoveVideo(video?.id)}
            aspectWidth={aspectWidth}
            aspectHeight={aspectHeight}
          />
        ) : null;
      }}
    </Field>
  );
};

export const EditExperienceContentFormComponent = props => {
  const inputRef = useRef(null);
  const videoRef = useRef(null);

  const [state, setState] = useState({ imageUploadRequested: false, videoUploadRequested: false });
  const [submittedImages, setSubmittedImages] = useState([]);
  const [submittedVideos, setSubmittedVideos] = useState([]);
  const [controlMenuImagesOpen, toggleControlMenuImagesOpen] = useState(null);
  const [controlMenuVideoOpen, toggleControlMenuVideoOpen] = useState(null);

  const { open: onOpenConfirmationModal, modalElement } = useConfirmationModal();

  const onImageUploadHandler = file => {
    const { onImageUpload } = props;
    if (file) {
      setState({ ...state, imageUploadRequested: true });

      onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          setState({ ...state, imageUploadRequested: false });
        })
        .catch(() => {
          setState({ ...state, imageUploadRequested: false });
        });
    }
  };

  const onVideoUploadHandler = file => {
    const { onVideoUpload } = props;
    if (file) {
      setState({ ...state, videoUploadRequested: true });

      onVideoUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          setState({ ...state, videoUploadRequested: false });
        })
        .catch(() => {
          setState({ ...state, videoUploadRequested: false });
        });
    }
  };

  const openFileDialog = fileInputRef => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          form,
          className,
          fetchErrors,
          handleSubmit,
          location,
          intl,
          invalid,
          onRemoveImage,
          onRemoveVideo,
          onPromoteImage,
          onGetPrevPathParams,
          disabled,
          ready,
          saveActionMsg,
          updated,
          updateInProgress,
          promoteImageInProgress,
          promoteImageError,
          touched,
          errors,
          values,
          listing,
          panelTitle,
        } = formRenderProps;

        const images = values.images || [];
        const videos = values.videos || [];

        const coverImageId = listing?.attributes?.publicData?.coverImageId;

        const prevPathParams = onGetPrevPathParams();
        const tabIndex = getDefaultTabIndex(location.search);

        const {
          publishListingError,
          showListingError,
          updateListingError,
          uploadImageError,
          uploadVideoError,
        } = fetchErrors || {};
        const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);
        const uploadVideoOverLimit = isUploadImageOverLimitError(uploadVideoError);

        // imgs can contain added images (with temp ids) and submitted images with uniq ids.
        const arrayOfImgIds = imgs => imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
        const arrayOfVideoIds = videos => videos.map(v => v.id || v.videoId);
        const imageIdsFromProps = arrayOfImgIds(images);
        const imageIdsFromPreviousSubmit = arrayOfImgIds(submittedImages);
        const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
        const videoIdsFromProps = arrayOfVideoIds(videos);
        const videoIdsFromPreviousSubmit = arrayOfVideoIds(submittedVideos);
        const videoArrayHasSameVideos = isEqual(videoIdsFromProps, videoIdsFromPreviousSubmit);
        const hasImages = images.length > 0;
        const hasVideos = videos.length > 0;
        const submittedOnce = submittedImages.length > 0 || submittedVideos.length > 0;
        const pristineSinceLastSubmit =
          submittedOnce && (imageArrayHasSameImages || videoArrayHasSameVideos);

        const submitReady = (updated && pristineSinceLastSubmit) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid ||
          disabled ||
          submitInProgress ||
          state.imageUploadRequested ||
          state.videoUploadRequested ||
          ready ||
          !hasImages;

        const imagesError = touched.images && errors?.images && errors.images[ARRAY_ERROR];
        const videosError = touched.videos && errors?.videos && errors.videos[ARRAY_ERROR];

        const getAspectWidthForImageUpload = hasFiles => (hasFiles ? 3 : 6);
        const getAspectHeightForImageUpload = hasFiles => (hasFiles ? 2 : 4);

        const getAspectWidthForVideoUpload = hasFiles => (hasFiles ? 5 : 6);
        const getAspectHeightForVideoUpload = hasFiles => (hasFiles ? 7 : 4);

        const aspectWidthForImageUpload = getAspectWidthForImageUpload(hasImages);
        const aspectHeightForImageUpload = getAspectHeightForImageUpload(hasImages);

        const aspectWidthForVideoUpload = getAspectWidthForVideoUpload(hasVideos);
        const aspectHeightForVideoUpload = getAspectHeightForVideoUpload(hasVideos);

        const addImageLabel = hasImages ? (
          <span className={classNames(css.chooseFileText, css.chooseFileTextWithFiles)}>
            <IconPhoto className={css.chooseFileIcon} />
            <span className={css.chooseFile}>
              <FormattedMessage id="EditExperienceContentForm.chooseImage" />
            </span>
            <span className={css.fileTypes}>
              <FormattedMessage id="EditExperienceContentForm.imageTypes" />
            </span>
          </span>
        ) : (
          <span className={css.chooseFileText}>
            <IconPhoto className={css.chooseFileIcon} />
            <span className={css.chooseFile}>
              <FormattedMessage id="EditExperienceContentForm.chooseImage" />
            </span>
            <span className={css.fileTypes}>
              <FormattedMessage id="EditExperienceContentForm.imageTypes" />
            </span>
          </span>
        );

        const addVideoLabel = hasVideos ? (
          <span className={classNames(css.chooseFileText, css.chooseFileTextWithFiles)}>
            <IconVideo className={css.chooseFileIcon} />
            <span className={css.chooseFile}>
              <FormattedMessage id="EditExperienceContentForm.chooseVideo" />
            </span>
            <span className={css.fileTypes}>
              <FormattedMessage id="EditExperienceContentForm.videoTypes" />
            </span>
          </span>
        ) : (
          <span className={css.chooseFileText}>
            <IconVideo className={css.chooseFileIcon} />
            <span className={css.chooseFile}>
              <FormattedMessage id="EditExperienceContentForm.chooseVideo" />
            </span>
            <span className={css.fileTypes}>
              <FormattedMessage id="EditExperienceContentForm.videoTypes" />
            </span>
          </span>
        );

        /**
         * Recommended image sizes for the image upload.
         */
        const uploadingImageHelpers = [
          {
            key: 'uploading-images-helper-1',
            text: intl.formatMessage({
              id: 'EditExperienceContentForm.uploadingImagesHelper1',
            }),
          },
          {
            key: 'uploading-images-helper-2',
            text: intl.formatMessage({
              id: 'EditExperienceContentForm.uploadingImagesHelper2',
            }),
          },
          {
            key: 'uploading-images-helper-3',
            text: intl.formatMessage({
              id: 'EditExperienceContentForm.uploadingImagesHelper3',
            }),
          },
        ];

        /**
         * Recommended video sizes for the video upload.
         */
        const uploadingVideoHelpers = [
          {
            key: 'uploading-videos-helper-1',
            text: intl.formatMessage({
              id: 'EditExperienceContentForm.uploadingVideosHelper1',
            }),
          },
          {
            key: 'uploading-videos-helper-2',
            text: intl.formatMessage({
              id: 'EditExperienceContentForm.uploadingVideosHelper2',
            }),
          },
          {
            key: 'uploading-videos-helper-3',
            text: intl.formatMessage({
              id: 'EditExperienceContentForm.uploadingVideosHelper3',
            }),
          },
        ];

        const classes = classNames(css.root, className);
        const imagesFileClasses = classNames(css.filesField, {
          [css.imagesFieldArray]: hasImages,
        });
        const videosFileClasses = classNames(css.filesField, {
          [css.filesFieldArray]: hasVideos,
        });

        return (
          <Form
            className={classes}
            onSubmit={e => {
              setSubmittedImages(images);
              setSubmittedVideos(videos);
              handleSubmit(e);
            }}
          >
            <div className={css.content}>
              <div className={css.contentWrapper}>
                <Tabs defaultIndex={tabIndex}>
                  <TabList>
                    <Tab>
                      <span className={css.tabHeading}>
                        <IconPhoto />
                        <FormattedMessage id="EditExperienceContentForm.tabTitlePhotos" />
                      </span>
                    </Tab>
                    <Tab>
                      <span className={css.tabHeading}>
                        <IconVideo />
                        <FormattedMessage id="EditExperienceContentForm.tabTitleVideos" />
                      </span>
                    </Tab>
                  </TabList>

                  {/* IMAGES TAB */}
                  <TabPanel>
                    <h1 className={css.title}>{panelTitle.images}</h1>
                    <p className={css.subTitle}>
                      <FormattedMessage id="EditExperienceContentForm.subTitle" />
                    </p>

                    <PublishListingError error={publishListingError} />
                    <ShowListingError error={showListingError} />

                    {updateListingError ? (
                      <p className={css.error}>
                        <FormattedMessage id="EditExperienceContentForm.updateFailed" />
                      </p>
                    ) : null}

                    <UploadButton
                      titleMsgId={'EditExperienceContentForm.uploadImagesTitle'}
                      disabled={submitDisabled}
                      onClick={() => openFileDialog(inputRef)}
                    />

                    <div className={imagesFileClasses}>
                      <FieldArray
                        name="images"
                        validate={composeValidators(
                          nonEmptyArray(
                            intl.formatMessage({
                              id: 'EditExperienceContentForm.imageRequired',
                            })
                          )
                        )}
                      >
                        {({ fields }) =>
                          fields.map((name, index) => (
                            <FieldListingImage
                              key={name}
                              name={name}
                              controlMenuOpen={controlMenuImagesOpen}
                              onToggleControlMenu={toggleControlMenuImagesOpen}
                              onRemoveImage={async imageId => {
                                const confirm = await onOpenConfirmationModal({
                                  id: 'EditExperienceContentForm.confirmRemoveImage',
                                  title: intl.formatMessage({
                                    id: 'EditExperienceContentForm.confirmRemoveImageTitle',
                                  }),
                                  description: intl.formatMessage({
                                    id: 'EditExperienceContentForm.confirmRemoveImageDescription',
                                  }),
                                  useVariationColor: true,
                                });

                                if (confirm) {
                                  fields.remove(index);
                                  onRemoveImage(imageId);
                                }
                              }}
                              onPromoteImage={onPromoteImage}
                              promoteImageInProgress={promoteImageInProgress}
                              promoteImageError={promoteImageError}
                              listingCoverImageId={coverImageId}
                              aspectWidth={aspectWidthForImageUpload}
                              aspectHeight={aspectHeightForImageUpload}
                              intl={intl}
                            />
                          ))
                        }
                      </FieldArray>

                      <FieldAddFile
                        id="addImage"
                        name="addImage"
                        accept={ACCEPT_IMAGES}
                        label={addImageLabel}
                        type="file"
                        disabled={state.imageUploadRequested}
                        formApi={form}
                        inputRef={inputRef}
                        aspectWidth={aspectWidthForImageUpload}
                        aspectHeight={aspectHeightForImageUpload}
                        onUploadHandler={onImageUploadHandler}
                      />
                    </div>

                    {imagesError ? <div className={css.arrayError}>{imagesError}</div> : null}

                    <ImageUploadError
                      uploadOverLimit={uploadOverLimit}
                      uploadImageError={uploadImageError}
                    />

                    <ListRenderer
                      className={css.listRenderer}
                      titleMsgId={'EditExperienceContentForm.uploadingImagesTitle'}
                      items={uploadingImageHelpers}
                    />
                  </TabPanel>

                  {/* VIDEOS TAB */}
                  <TabPanel>
                    <h1 className={css.title}>{panelTitle.videos}</h1>
                    <p className={css.subTitle}>
                      <FormattedMessage id="EditExperienceContentForm.videosSubTitle" />
                    </p>

                    <UploadButton
                      titleMsgId={'EditExperienceContentForm.uploadVideosTitle'}
                      disabled={submitDisabled}
                      onClick={() => openFileDialog(videoRef)}
                    />

                    <div className={videosFileClasses}>
                      <FieldArray name="videos">
                        {({ fields }) =>
                          fields.map((name, index) => (
                            <FieldListingVideo
                              key={name}
                              name={name}
                              controlMenuOpen={controlMenuVideoOpen}
                              onToggleControlMenu={toggleControlMenuVideoOpen}
                              onRemoveVideo={async videoId => {
                                const confirm = await onOpenConfirmationModal({
                                  id: 'EditExperienceContentForm.confirmRemoveVideo',
                                  title: intl.formatMessage({
                                    id: 'EditExperienceContentForm.confirmRemoveVideoTitle',
                                  }),
                                  description: intl.formatMessage({
                                    id: 'EditExperienceContentForm.confirmRemoveVideoDescription',
                                  }),
                                  useVariationColor: true,
                                });

                                if (confirm) {
                                  fields.remove(index);
                                  onRemoveVideo(videoId);
                                }
                              }}
                              aspectWidth={aspectWidthForVideoUpload}
                              aspectHeight={aspectHeightForVideoUpload}
                            />
                          ))
                        }
                      </FieldArray>

                      <FieldAddFile
                        id="addVideo"
                        name="addVideo"
                        accept={ACCEPT_VIDEOS}
                        label={addVideoLabel}
                        type="file"
                        disabled={state.videoUploadRequested}
                        formApi={form}
                        inputRef={videoRef}
                        aspectWidth={aspectWidthForVideoUpload}
                        aspectHeight={aspectHeightForVideoUpload}
                        onUploadHandler={onVideoUploadHandler}
                      />
                    </div>

                    {videosError ? <div className={css.arrayError}>{videosError}</div> : null}

                    <VideoUploadError
                      uploadOverLimit={uploadVideoOverLimit}
                      uploadImageError={uploadVideoError}
                    />

                    <ListRenderer
                      className={css.listRenderer}
                      titleMsgId={'EditExperienceContentForm.uploadingVideosTitle'}
                      items={uploadingVideoHelpers}
                    />
                  </TabPanel>
                </Tabs>
              </div>
            </div>

            <div className={css.submitButtonRoot}>
              <NamedLink
                className={css.backButton}
                name="EditExperiencePage"
                params={prevPathParams}
              >
                <FormattedMessage id="EditExperienceContentForm.backButton" />
              </NamedLink>
              <div className={css.submitButtonRootFlex}>
                <PreviewListingButton listing={listing} />
                <EditWizardButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </EditWizardButton>
              </div>
            </div>
            {modalElement}
          </Form>
        );
      }}
    />
  );
};

EditExperienceContentFormComponent.defaultProps = { fetchErrors: null };

EditExperienceContentFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  onRemoveVideo: func.isRequired,

  // from withRouter

  location: shape({
    search: string.isRequired,
  }).isRequired,
};

export default compose(injectIntl, withRouter)(EditExperienceContentFormComponent);
