import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import { pathByRouteName } from '../../util/routes';
import { generateMeetingLink } from '../../util/whereby';
import { ExternalLink } from '..';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';

import css from './BookingBreakdown.module.css';

const findConfigOptionLabels = (configKey, filterConfig, selectedKeys) => {
  const hasSelectedKeys = selectedKeys?.length > 0;
  if (!hasSelectedKeys) {
    return null;
  }

  const options = findOptionsForSelectFilter(configKey, filterConfig);
  const selectedOptions = options.filter(o => selectedKeys.includes(o.key));
  const selectedOptionLabels = selectedOptions.map(o => o.label);
  const joinedOptionLabels = selectedOptionLabels.join(', ');

  return joinedOptionLabels;
};

const LineItemExperienceDetails = props => {
  const { listing, timeslot, transactionRole, hideVideoLink, isDelivered } = props;
  const { title, publicData } = listing.attributes;

  if (!title) {
    return null;
  }

  const isCustomer = transactionRole === 'customer';
  const meetingLink = generateMeetingLink(timeslot, isCustomer, listing);

  const filterConfig = config.custom.filters;
  const languages = findConfigOptionLabels('language', filterConfig, publicData?.language);

  return (
    <div className={css.details}>
      <h4 className={css.detailsTitle}>
        <FormattedMessage id="BookingBreakdown.experienceDetails" />
      </h4>
      <div className={css.lineItems}>
        {listing.id && listing.id.uuid ? (
          <div className={css.lineItem}>
            <span className={css.itemLabel}>
              <FormattedMessage id="BookingBreakdown.experienceName" />
            </span>
            <ExternalLink
              className={css.experienceItemValue}
              href={pathByRouteName('ExperiencePage', routeConfiguration(), {
                id: listing.id.uuid,
                slug: createSlug(title || ''),
              })}
            >
              {title}
            </ExternalLink>
          </div>
        ) : null}
        {timeslot?.name ? (
          <div className={css.lineItem}>
            <span className={css.itemLabel}>
              <FormattedMessage id="BookingBreakdown.timeslotName" />
            </span>
            <span className={css.itemValue}>{timeslot.name}</span>
          </div>
        ) : null}
        {languages ? (
          <div className={css.lineItem}>
            <span className={css.itemLabel}>
              <FormattedMessage id="BookingBreakdown.experienceHosted" />
            </span>
            <span className={css.itemValue}>{languages}</span>
          </div>
        ) : null}
        {!hideVideoLink && !isDelivered && meetingLink ? (
          <div className={css.lineItem}>
            <span className={css.itemLabel}>
              <FormattedMessage id="BookingBreakdown.timeslotMeetingLink" />
            </span>
            <ExternalLink className={css.experienceItemValue} href={meetingLink}>
              {meetingLink.substring(0, 30) + '...'}
            </ExternalLink>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LineItemExperienceDetails;
