import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import {
  InlineTextButton,
  FieldTextInput,
  FieldCurrencyInput,
  FieldSelectInput,
  IconEdit,
  IconTrash,
  FieldCheckboxGroup,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import * as validators from '../../util/validators';
import config from '../../config';
import classNames from 'classnames';

import css from './EditExperienceMembershipForm.module.css';

const MIN_DURATION = 1;

/**
 * Helper function to help with formatting duration input which
 * shouldn't allow negative values.
 */
const durationFieldFormatted = durationNumber => {
  const positiveDurationNumber = durationNumber ? Math.abs(durationNumber) : null;
  return positiveDurationNumber;
};

const EditExperienceMembershipsField = props => {
  const {
    intl,
    push,
    isMembershipView,
    membershipsFromValues,
    activeMembershipIndex,
    setActiveMembershipIndex,
  } = props;

  // name
  const nameMessage = intl.formatMessage({ id: 'EditExperienceMembershipForm.name' });
  const namePlaceholder = intl.formatMessage({
    id: 'EditExperienceMembershipForm.namePlaceholder',
  });
  const nameRequiredMessage = intl.formatMessage({
    id: 'EditExperienceMembershipForm.nameRequired',
  });
  const nameRequired = validators.required(nameRequiredMessage);

  // description
  const descriptionMessage = intl.formatMessage({ id: 'EditExperienceMembershipForm.description' });
  const descriptionPlaceholder = intl.formatMessage({
    id: 'EditExperienceMembershipForm.descriptionPlaceholder',
  });
  const descriptionRequiredMessage = intl.formatMessage({
    id: 'EditExperienceMembershipForm.descriptionRequired',
  });
  const descriptionRequired = validators.required(descriptionRequiredMessage);

  // price
  const priceMessage = intl.formatMessage({ id: 'EditExperienceMembershipForm.price' });
  const pricePlaceholder = intl.formatMessage({
    id: 'EditExperienceMembershipForm.pricePlaceholder',
  });
  const priceRequiredMessage = intl.formatMessage({
    id: 'EditExperienceMembershipForm.priceRequired',
  });
  const priceRequired = validators.required(priceRequiredMessage);

  // duration
  const durationMessage = intl.formatMessage({ id: 'EditExperienceMembershipForm.duration' });
  const durationLengthPlaceholder = intl.formatMessage({
    id: 'EditExperienceMembershipForm.durationLengthPlaceholder',
  });
  const durationLengthRequiredMessage = intl.formatMessage({
    id: 'EditExperienceMembershipForm.durationLengthRequired',
  });
  const durationLengthRequired = validators.required(durationLengthRequiredMessage);

  // perks
  const perksMessage = intl.formatMessage({ id: 'EditExperienceMembershipForm.perks' });

  return (
    <FieldArray name="memberships">
      {({ fields }) => {
        // Layout if there aren't any memberships added
        const membershipsCount = membershipsFromValues ? membershipsFromValues.length : 0;
        if (membershipsCount === 0) {
          return (
            <InlineTextButton
              type="button"
              className={css.noMemberships}
              onClick={() => {
                push('memberships', {
                  perks: ['members-only-live-event'],
                });
                setActiveMembershipIndex(membershipsCount);
              }}
            >
              <h3 className={css.membershipTitle}>
                <FormattedMessage id="EditExperienceMembershipForm.noMemberships" />
              </h3>
            </InlineTextButton>
          );
        }

        return (
          <div className={css.memberships}>
            {fields.map((fieldName, index) => {
              const { name } = membershipsFromValues[index] || {};

              const handleActiveMembershipClick = () => {
                const activeIndex = activeMembershipIndex === index ? null : index;
                setActiveMembershipIndex(activeIndex);
              };

              const activeMembership = activeMembershipIndex === index;
              const headerClasses = classNames(css.membershipHeader, {
                [css.hiddenMembershipHeader]: activeMembership,
              });
              const formClasses = classNames(css.membershipForm, {
                [css.visibleMembershipForm]: activeMembership,
              });

              // Remove all other memberships from layout, if we're inside
              // active membership view (e.g. editing membership).
              if (isMembershipView && !activeMembership) {
                return null;
              }

              const renderName = name ? (
                name
              ) : (
                <FormattedMessage
                  id="EditExperienceMembershipForm.membershipTitle"
                  values={{ index: index + 1 }}
                />
              );

              return (
                <div className={css.membership} key={fieldName}>
                  <div className={headerClasses}>
                    <h3 className={css.membershipTitle}>{renderName}</h3>
                    <div className={css.manage}>
                      <InlineTextButton
                        type="button"
                        className={css.manageButton}
                        onClick={handleActiveMembershipClick}
                      >
                        <IconEdit className={css.manageButtonIcon} />
                      </InlineTextButton>
                      <InlineTextButton
                        type="button"
                        className={css.manageButton}
                        onClick={() => fields.remove(index)}
                      >
                        <IconTrash className={css.manageButtonIcon} />
                      </InlineTextButton>
                    </div>
                  </div>
                  <div className={formClasses}>
                    <FieldTextInput
                      id={`${fieldName}.name`}
                      name={`${fieldName}.name`}
                      type="text"
                      label={nameMessage}
                      placeholder={namePlaceholder}
                      validate={nameRequired}
                    />
                    <FieldTextInput
                      id={`${fieldName}.description`}
                      name={`${fieldName}.description`}
                      className={css.field}
                      type="textarea"
                      label={descriptionMessage}
                      placeholder={descriptionPlaceholder}
                      validate={descriptionRequired}
                    />
                    <FieldCurrencyInput
                      id={`${fieldName}.price`}
                      name={`${fieldName}.price`}
                      className={css.field}
                      label={priceMessage}
                      placeholder={pricePlaceholder}
                      currencyConfig={config.currencyConfig}
                      validate={priceRequired}
                    />
                    <div className={css.field}>
                      <label>{durationMessage}</label>
                      <div className={css.durationFields}>
                        <FieldTextInput
                          id={`${fieldName}.duration.length`}
                          name={`${fieldName}.duration.length`}
                          className={css.durationLengthField}
                          type="number"
                          min={MIN_DURATION}
                          placeholder={durationLengthPlaceholder}
                          format={durationFieldFormatted}
                          validate={durationLengthRequired}
                        />
                        <FieldSelectInput
                          id={`${fieldName}.duration.type`}
                          name={`${fieldName}.duration.type`}
                          className={css.durationTypeField}
                          options={config.custom.durationConfig}
                          defaultValue={config.custom.durationConfig[0].key}
                        />
                      </div>
                    </div>
                    <FieldCheckboxGroup
                      id={`${fieldName}.perks`}
                      name={`${fieldName}.perks`}
                      className={css.field}
                      label={perksMessage}
                      options={config.custom.perksConfig}
                      useVariationColor
                      twoColumns
                    />
                  </div>
                </div>
              );
            })}
            {isMembershipView ? (
              <div className={css.actionButtons}>
                <SecondaryButton
                  className={css.actionButton}
                  type="button"
                  onClick={() => {
                    fields.remove(activeMembershipIndex);
                    setActiveMembershipIndex(null);
                  }}
                >
                  <FormattedMessage id="EditExperienceMembershipForm.deleteButton" />
                </SecondaryButton>
                <PrimaryButton
                  className={classNames(css.actionButton, css.actionButtonSave)}
                  type="button"
                  onClick={() => setActiveMembershipIndex(null)}
                >
                  <FormattedMessage id="EditExperienceMembershipForm.saveButton" />
                </PrimaryButton>
              </div>
            ) : null}
          </div>
        );
      }}
    </FieldArray>
  );
};

export default EditExperienceMembershipsField;
