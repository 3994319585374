import React from 'react';
import { oneOfType, array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import {
  EditExperienceDetailsPanel,
  EditExperienceContentPanel,
  EditExperienceMembershipPanel,
  EditExperienceTeamPanel,
  EditExperienceAvailabilityPanel,
} from '../../components';

import css from './EditExperienceWizard.module.css';

export const DETAILS = 'details';
export const CONTENT = 'content';
export const MEMBERSHIP = 'membership';
export const TEAM = 'team';
export const AVAILABILITY = 'availability';

// EditExperienceWizardTab component supports these tabs
export const SUPPORTED_TABS = [DETAILS, CONTENT, MEMBERSHIP, TEAM, AVAILABILITY];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditExperienceWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history) => {
  const listingUUID = listingId.uuid;
  const routes = routeConfiguration();
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingUUID,
  };

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString(
      'EditExperiencePage',
      routes,
      currentPathParams,
      {}
    );
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString('EditExperiencePage', routes, nextPathParams, {});
  history.push(to);
};

const pathParamsToPreviousTab = (params, tab, marketplaceTabs) => {
  const prevTabIndex = marketplaceTabs.findIndex(s => s === tab) - 1;
  const prevTab =
    prevTabIndex < marketplaceTabs.length ? marketplaceTabs[prevTabIndex] : marketplaceTabs[0];
  return { ...params, tab: prevTab };
};

const getPrevPathParams = (listingId, params, tab, marketplaceTabs) => {
  const currentPathParams = {
    ...params,
    id: listingId,
  };

  const previousPathParams = pathParamsToPreviousTab(currentPathParams, tab, marketplaceTabs);
  return previousPathParams;
};

const EditExperienceWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    currentUser,
    listing,
    payoutEnabled,
    onOpenPayoutDetailsModal,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onAddRepeatingAvailabilityExceptions,
    onDeleteRepeatingAvailabilityExceptions,
    availabilityExceptions,
    fetchExceptionsInProgress,
    addExceptionInProgress,
    addRepeatingExceptionsInProgress,
    deleteExceptionId,
    deleteExceptionInProgress,
    deleteRepeatingExceptionsInProgress,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onVideoUpload,
    onRemoveImage,
    onRemoveVideo,
    onChange,
    onManageDisableScrolling,
    onPromoteImage,
    promoteImageInProgress,
    promoteImageError,
    onCreateMeeting,
    onShowListing,
    updatedTab,
    updateInProgress,
    videos,
    listingShows,
    queryShowsInProgress,
    teamUsers,
    organizationProfiles,
    getInvitesState,
    getInvitesFns,
    intl,
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);

  // New listing flow has automatic redirects to new tab on the wizard
  // and the last panel calls publishListing API endpoint.
  const automaticRedirectsForNewListingFlow = (tab, listingId) => {
    if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
      // Create listing flow: smooth scrolling polyfill to scroll to correct tab
      handleCreateFlowTabScrolling(false);

      // After successful saving of draft data, user should be redirected to next tab
      redirectAfterDraftUpdate(listingId, params, tab, marketplaceTabs, history);
    } else {
      handlePublishListing(listingId);
    }
  };

  const onCompleteEditExperienceWizardTab = (tab, updateValues) => {
    const onUpdateListingOrCreateListingDraft = isNewURI
      ? (tab, values) => onCreateListingDraft(values)
      : (tab, values) => onUpdateListing(tab, values);

    const updateListingValues = isNewURI
      ? updateValues
      : { ...updateValues, id: currentListing.id };

    return onUpdateListingOrCreateListingDraft(tab, updateListingValues)
      .then(r => {
        if (isNewListingFlow) {
          const listingId = r.data.data.id;
          automaticRedirectsForNewListingFlow(tab, listingId);
        }
      })
      .catch(e => {
        // No need for extra actions
        console.log(e);
      });
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      history,
      currentUser,
      listing,
      listingShows,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      onManageDisableScrolling,
      onGetPrevPathParams: () =>
        getPrevPathParams(currentListing.id.uuid, params, tab, marketplaceTabs),
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
    };
  };

  switch (tab) {
    case DETAILS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditExperienceWizard.saveNewDescription'
        : 'EditExperienceWizard.saveEditDescription';
      return (
        <EditExperienceDetailsPanel
          {...panelProps(DETAILS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          queryShowsInProgress={queryShowsInProgress}
          onShowListing={onShowListing}
          onSubmit={values => {
            onCompleteEditExperienceWizardTab(tab, values);
          }}
        />
      );
    }
    case CONTENT: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditExperienceWizard.saveNewPhotos'
        : 'EditExperienceWizard.saveEditPhotos';

      return (
        <EditExperienceContentPanel
          {...panelProps(CONTENT)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          images={images}
          videos={videos}
          onImageUpload={onImageUpload}
          onVideoUpload={onVideoUpload}
          onRemoveImage={onRemoveImage}
          onRemoveVideo={onRemoveVideo}
          onPromoteImage={onPromoteImage}
          onSubmit={values => {
            onCompleteEditExperienceWizardTab(tab, values);
          }}
          promoteImageInProgress={promoteImageInProgress}
          promoteImageError={promoteImageError}
        />
      );
    }
    case MEMBERSHIP: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditExperienceWizard.saveNewMembership'
        : 'EditExperienceWizard.saveEditMembership';
      return (
        <EditExperienceMembershipPanel
          {...panelProps(MEMBERSHIP)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          payoutEnabled={payoutEnabled}
          onOpenPayoutDetailsModal={onOpenPayoutDetailsModal}
          onSubmit={values => {
            onCompleteEditExperienceWizardTab(tab, values);
          }}
        />
      );
    }
    case TEAM: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditExperienceWizard.saveNewTeam'
        : 'EditExperienceWizard.saveEditTeam';
      return (
        <EditExperienceTeamPanel
          {...panelProps(TEAM)}
          teamUsers={teamUsers}
          organizationProfiles={organizationProfiles}
          getInvitesState={getInvitesState}
          getInvitesFns={getInvitesFns}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditExperienceWizardTab(tab, values);
          }}
        />
      );
    }
    case AVAILABILITY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditExperienceWizard.saveNewAvailability'
        : 'EditExperienceWizard.saveEditAvailability';
      return (
        <EditExperienceAvailabilityPanel
          {...panelProps(AVAILABILITY)}
          fetchExceptionsInProgress={fetchExceptionsInProgress}
          addExceptionInProgress={addExceptionInProgress}
          addRepeatingExceptionsInProgress={addRepeatingExceptionsInProgress}
          deleteExceptionId={deleteExceptionId}
          deleteExceptionInProgress={deleteExceptionInProgress}
          deleteRepeatingExceptionsInProgress={deleteRepeatingExceptionsInProgress}
          availabilityExceptions={availabilityExceptions}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onAddAvailabilityException={onAddAvailabilityException}
          onDeleteAvailabilityException={onDeleteAvailabilityException}
          onAddRepeatingAvailabilityExceptions={onAddRepeatingAvailabilityExceptions}
          onDeleteRepeatingAvailabilityExceptions={onDeleteRepeatingAvailabilityExceptions}
          onCreateMeeting={onCreateMeeting}
          onUpdateListing={onUpdateListing}
          onSubmit={values => {
            // We want to return the Promise to the form,
            // so that it doesn't close its modal if an error is thrown.
            return onCompleteEditExperienceWizardTab(tab, values, true);
          }}
          onNextTab={() =>
            redirectAfterDraftUpdate(listing.id.uuid, params, tab, marketplaceTabs, history)
          }
        />
      );
    }
    default:
      return null;
  }
};

EditExperienceWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  availabilityExceptions: [],
  addExceptionInProgress: false,
  listingShows: [],
  promoteImageInProgress: false,
  promoteImageError: null,
};

EditExperienceWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,

  availabilityExceptions: arrayOf(propTypes.availabilityException),
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    fetchExceptionsError: object,
    addExceptionError: object,
    addRepeatingExceptionsError: object,
    deleteExceptionError: object,
    deleteRepeatingExceptionsError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  fetchExceptionsInProgress: bool.isRequired,
  addExceptionInProgress: bool.isRequired,
  addRepeatingExceptionsInProgress: bool.isRequired,
  deleteExceptionId: oneOfType([string, object]),
  deleteExceptionInProgress: bool.isRequired,
  deleteRepeatingExceptionsInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,

  onUpdateListing: func.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onRemoveImage: func.isRequired,
  onRemoveVideo: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,
  onCreateMeeting: func.isRequired,
  listingShows: array.isRequired,
  onPromoteImage: func.isRequired,
  promoteImageInProgress: bool.isRequired,
  promoteImageError: propTypes.error,

  intl: intlShape.isRequired,
};

export default EditExperienceWizardTab;
