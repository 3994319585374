import { pathByRouteName } from '../util/routes';
import routeConfiguration from '../routeConfiguration';

/**
 * Get the meeting ID from the meeting object.
 *
 * @param {Object} meeting
 * @returns {String} meeting roomName
 */
const getMeetingRoomName = meeting => {
  if (!meeting || !meeting.roomName) {
    return null;
  }

  const roomName = meeting.roomName.replace('/', '');
  return roomName;
};

/**
 * Get the meeting URL from the meeting object.
 *
 * @param {Object} meeting
 * @returns {String} meetingUrl
 */
export const getRoomKeyFromUrl = url => {
  if (!url) {
    return null;
  }

  const urlParams = new URLSearchParams(url.split('?')[1]);
  return urlParams.get('roomKey');
};

/**
 * Generates the meeting link based on the timeslot, user type, and listing details.
 *
 * @param {Object} timeslot - The timeslot object containing meeting information.
 * @param {boolean} isCustomer - Determines whether the user is a customer or host.
 * @param {Object} listing - The listing object containing the listing ID.
 * @return {string|null} The generated meeting link, or null if no meeting is available.
 */
export const generateMeetingLink = (timeslot, isCustomer, listing) => {
  if (!timeslot?.meeting) {
    return null;
  }

  const roomName = getMeetingRoomName(timeslot.meeting);
  const roomKey = isCustomer
    ? getRoomKeyFromUrl(timeslot.meeting.viewerRoomUrl)
    : getRoomKeyFromUrl(timeslot.meeting.hostRoomUrl);

  const meetingRoute = pathByRouteName('MeetPage', routeConfiguration(), { roomName });
  return `${process.env.REACT_APP_CANONICAL_ROOT_URL}${meetingRoute}?meetingId=${timeslot.meeting.meetingId}&roomKey=${roomKey}&listingId=${listing.id.uuid}`;
};

/**
 * Decodes a JWT token into its payload.
 * @param {string} token - The JWT token to decode.
 * @returns {object|null} - The decoded payload of the JWT token or null if decoding fails.
 */
const decodeJwt = token => {
  try {
    const payload = token.split('.')[1];
    const decoded = atob(payload);
    return JSON.parse(decoded);
  } catch (err) {
    console.error('Error decoding JWT:', err);
    return null;
  }
};

/**
 * Validates if two host room keys match by comparing their decoded JWT payloads.
 * @param {string} urlHostRoomKey - The host room key extracted from the URL.
 * @param {string} apiHostRoomKey - The host room key fetched from the API.
 * @returns {boolean} - True if both host room keys match, false otherwise or if either key is missing.
 */
export const validateHostRoomKey = (urlHostRoomKey, apiHostRoomKey) => {
  if (!urlHostRoomKey || !apiHostRoomKey) {
    return false;
  }

  // Decode both host room keys
  const urlPayload = decodeJwt(urlHostRoomKey);
  const apiPayload = decodeJwt(apiHostRoomKey);

  if (!urlPayload || !apiPayload) return false;

  // Compare key properties from both payloads and return true if they match
  return (
    urlPayload.meetingId === apiPayload.meetingId &&
    urlPayload.roomReference.roomName === apiPayload.roomReference.roomName &&
    urlPayload.roomReference.organizationId === apiPayload.roomReference.organizationId &&
    urlPayload.roomKeyType === apiPayload.roomKeyType
  );
};

/**
 * Replaces the domain of a Whereby meeting URL with a public domain and preserves the room ID and query parameters.
 *
 * @param {string} url - The original Whereby URL that needs to be updated.
 * @param {string} [publicDomain=process.env.REACT_APP_WHEREBY_MEETING_LOCAL_PAGE_URL] - The new public domain to use, with `/meet` as the base path.
 * @returns {string} - The updated URL with the public domain, room ID, and query parameters.
 */
export const replaceWherebyDomainWithPublicDomain = (
  url,
  publicDomain = process.env.REACT_APP_WHEREBY_MEETING_LOCAL_PAGE_URL
) => {
  const parsedUrl = new URL(url);
  const pathSegments = parsedUrl.pathname.split('/').filter(Boolean);
  const roomId = pathSegments[0];
  const queryString = parsedUrl.search;

  const normalizedDomain = publicDomain.endsWith('/') ? publicDomain.slice(0, -1) : publicDomain;

  return `${normalizedDomain}/${roomId}${queryString}`;
};
