import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { supportedLanguages } from '../../util/translations';
import { manageDisableScrolling, toggleLanguageModal } from '../../ducks/UI.duck';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, IconLanguage, InlineTextButton } from '../../components';
import { NewsletterForm } from '../../forms';
import { subscribeToList } from '../../ducks/mailchimp.duck';
import config from '../../config';
import classNames from 'classnames';

import CanadaIcon from './icons/CanadaIcon';

import css from './Footer.module.css';

const NewsletterSignup = props => {
  const {
    currentUser,
    onSubscribeToList,
    subscribeToListInProgress,
    subscribeToListError,
    subscribeToListSubmitted,
  } = props;

  const firstName = currentUser?.attributes?.profile?.firstName || '';
  const lastName = currentUser?.attributes?.profile?.lastName || '';

  const handleSubmit = (values, form) => {
    const { email } = values;
    const subscribingUser = { email, firstName, lastName };

    onSubscribeToList({
      listId: process.env.REACT_APP_MAILCHIMP_NEWSLETTER_LIST_ID,
      subscribingUser,
    })
      .then(() => {
        form.restart();
      })
      .catch(error => {
        console.error('Subscription error:', error);
        form.restart();
      });
  };

  return (
    <div className={css.newsletter}>
      <h2 className={css.newsletterTitle}>
        <FormattedMessage id="Footer.newsletterTitle" />
      </h2>
      {!subscribeToListSubmitted ? (
        <NewsletterForm
          className={css.emailForm}
          onSubmit={handleSubmit}
          inProgress={subscribeToListInProgress}
        />
      ) : (
        <p className={css.newsletterSuccessMessage}>
          <FormattedMessage id="Footer.newsletterSuccessMessage" />
        </p>
      )}
      {subscribeToListError ? (
        <p className={css.newsletterErrorMessage}>
          <FormattedMessage id="Footer.newsletterErrorMessage" />
        </p>
      ) : null}
    </div>
  );
};

const LinkColumn = props => {
  const { titleMsgId, links } = props;

  return (
    <div className={css.linkColumn}>
      <h3 className={css.linkColumnTitle}>
        <FormattedMessage id={titleMsgId} />
      </h3>
      <ul className={css.linkColumnList}>
        {links.map((link, index) => {
          const linkParamsMaybe = link.params ? { params: link.params } : {};
          return (
            <li key={`${link.name}_${index}`} className={css.linkColumnListItem}>
              <NamedLink
                className={css.linkColumnListItemLink}
                name={link.name}
                {...linkParamsMaybe}
              >
                <FormattedMessage id={link.labelMsgId} />
              </NamedLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const LinkSections = () => (
  <div className={css.linkSections}>
    <LinkColumn
      titleMsgId="Footer.lineColumnTitleFirst"
      links={[
        {
          name: 'ArticlePage',
          params: {
            topicId: 'live-room',
            articleId: 'booking-live-room-episodes',
          },
          labelMsgId: 'Footer.linkToLiveRoom',
        },
        {
          name: 'TopicPage',
          params: {
            topicId: 'becoming-a-creator',
          },
          labelMsgId: 'Footer.linkToBecomingACreator',
        },
        {
          name: 'ArticlePage',
          params: {
            topicId: 'grow-your-community',
            articleId: 'how-to-grow-your-members',
          },
          labelMsgId: 'Footer.linkToGrowYourCommunity',
        },
        {
          name: 'ArticlePage',
          params: {
            topicId: 'profile-and-account-settings',
            articleId: 'user-badge',
          },
          labelMsgId: 'Footer.linkToUserBadge',
        },
      ]}
    />
    <LinkColumn
      titleMsgId="Footer.linkColumnTitleSecond"
      links={[
        { name: 'HelpPage', labelMsgId: 'Footer.linkToHelpPage' },
        { name: 'CommunityPage', labelMsgId: 'Footer.linkToCommunityPage' },
        { name: 'CommunityGuidelinesPage', labelMsgId: 'Footer.linkToCommunityGuidelinesPage' },
      ]}
    />
    <LinkColumn
      titleMsgId="Footer.linkColumnTitleThird"
      links={[
        { name: 'AboutPage', labelMsgId: 'Footer.linkToAboutPage' },
        { name: 'PrivacyPolicyPage', labelMsgId: 'Footer.linkToPrivacyPolicyPage' },
        { name: 'TermsOfServicePage', labelMsgId: 'Footer.linkToTermsOfServicePage' },
      ]}
    />
  </div>
);

const LanguageSelector = props => {
  const { onOpenLanguageModal } = props;

  const currentLanguage = supportedLanguages.find(lang => lang.code === config.locale);
  return (
    <InlineTextButton className={css.languageSelector} type="button" onClick={onOpenLanguageModal}>
      <IconLanguage className={css.languageSelectorIcon} />
      <p className={css.languageSelectorText}>
        <FormattedMessage
          id="Footer.language"
          values={{
            language: currentLanguage.language,
            code: currentLanguage.code.toUpperCase(),
          }}
        />
      </p>
    </InlineTextButton>
  );
};

/**
 * Contains the main content of the footer including newsletter and link sections
 */
const MainContent = props => (
  <div className={css.mainContent}>
    <NewsletterSignup {...props} />
    <LinkSections />
  </div>
);

/**
 * Represents the section showcasing endorsement by external entities
 */
const TrustedBy = () => (
  <div className={css.trustedBy}>
    <h3 className={css.trustedByTitle}>
      <FormattedMessage id="Footer.trustedByTitle" />
    </h3>
    <div className={css.trustedByContent}>
      <div className={css.trustedByTextContent}>
        <p className={css.trustedByText}>
          <FormattedMessage id="Footer.trustedByText" />
        </p>
      </div>
      <CanadaIcon className={css.trustedByIcon} />
    </div>
  </div>
);

/**
 * Footer bottom content including copyright and language selector
 */
const BottomContent = props => {
  const { onOpenLanguageModal } = props;
  return (
    <div className={css.bottomContent}>
      <div className={css.bottomContentWrapper}>
        <div className={css.attractrFooter}>© 2024 Attractr</div>
        <LanguageSelector onOpenLanguageModal={onOpenLanguageModal} />
      </div>
    </div>
  );
};

const Footer = props => {
  const {
    rootClassName,
    className,
    currentUser,
    onSubscribeToList,
    subscribeToListInProgress,
    subscribeToListError,
    subscribeToListSubmitted,
    onToggleLanguageModal,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  return (
    <div className={classes}>
      <div className={css.content}>
        <MainContent
          currentUser={ensuredCurrentUser}
          onSubscribeToList={onSubscribeToList}
          subscribeToListInProgress={subscribeToListInProgress}
          subscribeToListError={subscribeToListError}
          subscribeToListSubmitted={subscribeToListSubmitted}
        />
        <TrustedBy />
      </div>
      <BottomContent onOpenLanguageModal={() => onToggleLanguageModal(true)} />
    </div>
  );
};

Footer.defaultProps = {
  currentUser: null,

  // mailchimp
  onSubscribeToList: null,
  subscribeToListInProgress: false,
  subscribeToListError: null,
  subscribeToListSubmitted: false,

  onToggleLanguageModal: null,
};

Footer.propTypes = {
  currentUser: propTypes.currentUser,

  // mailchimp
  onSubscribeToList: func.isRequired,
  subscribeToListInProgress: bool.isRequired,
  subscribeToListError: propTypes.error,
  subscribeToListSubmitted: bool.isRequired,

  onToggleLanguageModal: func.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    subscribeToListInProgress,
    subscribeToListError,
    subscribeToListSubmitted,
  } = state.mailchimp;
  return {
    currentUser,
    subscribeToListInProgress,
    subscribeToListError,
    subscribeToListSubmitted,
  };
};

const mapDispatchToProps = dispatch => ({
  onSubscribeToList: bodyParams => dispatch(subscribeToList(bodyParams)),
  onToggleLanguageModal: languageModalOpen => dispatch(toggleLanguageModal(languageModalOpen)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Footer);
