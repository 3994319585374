import { wherebyAPI } from '../util/api';
import { storableError } from '../util/errors';

// ================ Action types ================ //

export const GET_MEETING_REQUEST = 'app/GET_MEETING_REQUEST';
export const GET_MEETING_SUCCESS = 'app/GET_MEETING_SUCCESS';
export const GET_MEETING_ERROR = 'app/GET_MEETING_ERROR';

export const CREATE_MEETING_REQUEST = 'app/CREATE_MEETING_REQUEST';
export const CREATE_MEETING_SUCCESS = 'app/CREATE_MEETING_SUCCESS';
export const CREATE_MEETING_ERROR = 'app/CREATE_MEETING_ERROR';

// ================ Reducer ================ //

const initialState = {
  getMeetingInProgress: false,
  getMeetingError: null,
  createMeetingInProgress: false,
  createMeetingError: null,
};

export default function wherebyReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_MEETING_REQUEST:
      return { ...state, getMeetingInProgress: true, getMeetingError: null };
    case GET_MEETING_SUCCESS:
      return {
        ...state,
        getMeetingInProgress: false,
        getMeetingError: null,
      };
    case GET_MEETING_ERROR:
      return { ...state, getMeetingInProgress: true, getMeetingError: payload };

    case CREATE_MEETING_REQUEST:
      return { ...state, createMeetingInProgress: true, createMeetingError: null };
    case CREATE_MEETING_SUCCESS:
      return {
        ...state,
        createMeetingInProgress: false,
        createMeetingError: null,
      };
    case CREATE_MEETING_ERROR:
      return { ...state, createMeetingInProgress: true, createMeetingError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const getMeetingRequest = () => ({ type: GET_MEETING_REQUEST });
export const getMeetingSuccess = () => ({
  type: GET_MEETING_SUCCESS,
});
export const getMeetingError = error => ({
  type: GET_MEETING_ERROR,
  payload: error,
});

export const createMeetingRequest = () => ({ type: CREATE_MEETING_REQUEST });
export const createMeetingSuccess = () => ({
  type: CREATE_MEETING_SUCCESS,
});
export const createMeetingError = error => ({
  type: CREATE_MEETING_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const getMeeting = params => (dispatch, getState, sdk) => {
  dispatch(getMeetingRequest());

  return wherebyAPI
    .getMeeting(params)
    .then(response => {
      console.log(response);
      dispatch(getMeetingSuccess(response));
      return response;
    })
    .catch(e => dispatch(getMeetingError(storableError(e))));
};

export const createMeeting = bodyParams => (dispatch, getState, sdk) => {
  dispatch(createMeetingRequest());

  return wherebyAPI
    .createMeeting(bodyParams)
    .then(response => {
      dispatch(createMeetingSuccess(response));
      return response;
    })
    .catch(e => dispatch(createMeetingError(storableError(e))));
};
