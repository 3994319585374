import { types as sdkTypes, util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { formatFilterForAPI } from '../../util/search';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { queryRecentlyViewedListings } from '../../ducks/user.duck';
import { integrationAPI } from '../../util/api';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_USER_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_USER_REVIEWS_REQUEST';
export const QUERY_USER_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_USER_REVIEWS_SUCCESS';
export const QUERY_USER_REVIEWS_ERROR = 'app/ProfilePage/QUERY_USER_REVIEWS_ERROR';

export const QUERY_USER_COMMENTS_REQUEST = 'app/ProfilePage/QUERY_USER_COMMENTS_REQUEST';
export const QUERY_USER_COMMENTS_SUCCESS = 'app/ProfilePage/QUERY_USER_COMMENTS_SUCCESS';
export const QUERY_USER_COMMENTS_ERROR = 'app/ProfilePage/QUERY_USER_COMMENTS_ERROR';

export const FETCH_SUBSCRIPTION_LISTINGS_REQUEST =
  'app/ProfilePage/FETCH_SUBSCRIPTION_LISTINGS_REQUEST';
export const FETCH_SUBSCRIPTION_LISTINGS_SUCCESS =
  'app/ProfilePage/FETCH_SUBSCRIPTION_LISTINGS_SUCCESS';
export const FETCH_SUBSCRIPTION_LISTINGS_ERROR =
  'app/ProfilePage/FETCH_SUBSCRIPTION_LISTINGS_ERROR';

export const FETCH_FOLLOWING_USERS_REQUEST = 'app/ProfilePage/FETCH_FOLLOWING_USERS_REQUEST';
export const FETCH_FOLLOWING_USERS_SUCCESS = 'app/ProfilePage/FETCH_FOLLOWING_USERS_SUCCESS';
export const FETCH_FOLLOWING_USERS_ERROR = 'app/ProfilePage/FETCH_FOLLOWING_USERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userShowError: null,
  userComments: [],
  queryUserCommentsInProgress: false,
  queryUserCommentsError: null,
  userReviews: [],
  queryUserReviewsInProgress: false,
  queryUserReviewsError: null,
  fetchSubscriptionListingsInProgress: false,
  fetchSubscriptionListingsError: null,
  subscriptionListingIds: [],
  fetchFollowingUsersInProgress: false,
  fetchFollowingUsersError: null,
  followingUserRefs: [],
};

const resultIds = data => data.data.map(l => l.id);

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return {
        ...state,
        userShowError: null,
      };
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_USER_REVIEWS_REQUEST:
      return {
        ...state,
        queryUserReviewsInProgress: true,
        queryUserReviewsError: null,
      };
    case QUERY_USER_REVIEWS_SUCCESS:
      return {
        ...state,
        queryUserReviewsInProgress: false,
        queryUserReviewsError: null,
        userReviews: payload.reviews,
      };
    case QUERY_USER_REVIEWS_ERROR:
      return {
        ...state,
        queryUserReviewsInProgress: false,
        queryUserReviewsError: payload,
      };

    case QUERY_USER_COMMENTS_REQUEST:
      return {
        ...state,
        queryUserCommentsInProgress: true,
        queryUserCommentsError: null,
      };
    case QUERY_USER_COMMENTS_SUCCESS:
      return {
        ...state,
        queryUserCommentsInProgress: false,
        queryUserCommentsError: null,
        userComments: payload.comments,
      };
    case QUERY_USER_COMMENTS_ERROR:
      return {
        ...state,
        queryUserCommentsInProgress: false,
        queryUserCommentsError: payload,
      };

    case FETCH_SUBSCRIPTION_LISTINGS_REQUEST:
      return {
        ...state,
        fetchSubscriptionListingsInProgress: true,
        fetchSubscriptionListingsError: null,
      };
    case FETCH_SUBSCRIPTION_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchSubscriptionListingsInProgress: false,
        fetchSubscriptionListingsError: null,
        subscriptionListingIds: resultIds(payload.data),
      };
    case FETCH_SUBSCRIPTION_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchSubscriptionListingsInProgress: false,
        fetchSubscriptionListingsError: payload,
      };

    case FETCH_FOLLOWING_USERS_REQUEST:
      return { ...state, fetchFollowingUsersInProgress: true, fetchFollowingUsersError: null };
    case FETCH_FOLLOWING_USERS_SUCCESS:
      return {
        ...state,
        fetchFollowingUsersInProgress: false,
        fetchFollowingUsersError: null,
        followingUserRefs: payload.data.map(u => ({ id: u.id, type: u.type })),
      };
    case FETCH_FOLLOWING_USERS_ERROR:
      return { ...state, fetchFollowingUsersInProgress: false, fetchFollowingUsersError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});
export const showUserSuccess = response => ({
  type: SHOW_USER_SUCCESS,
  payload: { data: response.data.data },
});
export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryUserReviewsRequest = () => ({
  type: QUERY_USER_REVIEWS_REQUEST,
});
export const queryUserReviewsSuccess = response => ({
  type: QUERY_USER_REVIEWS_SUCCESS,
  payload: { reviews: response.data },
});
export const queryUserReviewsError = e => ({
  type: QUERY_USER_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const queryUserCommentsRequest = () => ({
  type: QUERY_USER_COMMENTS_REQUEST,
});
export const queryUserCommentsSuccess = response => ({
  type: QUERY_USER_COMMENTS_SUCCESS,
  payload: { comments: response.data },
});
export const queryUserCommentsError = e => ({
  type: QUERY_USER_COMMENTS_ERROR,
  error: true,
  payload: e,
});

export const fetchSubscriptionListingsRequest = () => ({
  type: FETCH_SUBSCRIPTION_LISTINGS_REQUEST,
});
export const fetchSubscriptionListingsSuccess = response => ({
  type: FETCH_SUBSCRIPTION_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const fetchSubscriptionListingsError = e => ({
  type: FETCH_SUBSCRIPTION_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchFollowingUsersRequest = () => ({
  type: FETCH_FOLLOWING_USERS_REQUEST,
});
export const fetchFollowingUsersSuccess = response => ({
  type: FETCH_FOLLOWING_USERS_SUCCESS,
  payload: { data: response.data.data },
});
export const fetchFollowingUsersError = e => ({
  type: FETCH_FOLLOWING_USERS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess(response));
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  dispatch(queryUserReviewsRequest());

  return integrationAPI.reviews
    .query({
      authorId: userId.uuid,
      include: ['listing'],
    })
    .then(response => {
      dispatch(queryUserReviewsSuccess(response));
      return response;
    })
    .catch(e => dispatch(queryUserReviewsError(storableError(e))));
};

export const queryUserComments = userId => (dispatch, getState, sdk) => {
  dispatch(queryUserCommentsRequest());

  return integrationAPI.comments
    .query({
      authorId: userId.uuid,
    })
    .then(response => {
      dispatch(queryUserCommentsSuccess(response));
      return response;
    })
    .catch(e => dispatch(queryUserCommentsError(storableError(e))));
};

export const fetchSubscriptionListings = userId => (dispatch, getState, sdk) => {
  dispatch(fetchSubscriptionListingsRequest());

  return sdk.users
    .show({ id: userId.uuid })
    .then(response => {
      const user = response.data.data;

      const subscriptions = user.attributes.profile.metadata.memberships || [];
      const subscriptionListingIds = subscriptions.map(m => m.listingId);

      return subscriptionListingIds;
    })
    .then(listingIds => {
      return sdk.listings
        .query({
          ids: listingIds,
          include: ['images'],
          'fields.image': [
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.portrait-crop',
            'variants.portrait-crop2x',
            'variants.portrait-crop4x',
          ],
          'imageVariant.portrait-crop': sdkUtil.objectQueryString({
            w: 250,
            h: 300,
            fit: 'crop',
          }),
          'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
            w: 280,
            h: 392,
            fit: 'crop',
          }),
          'imageVariant.portrait-crop4x': sdkUtil.objectQueryString({
            w: 560,
            h: 784,
            fit: 'crop',
          }),
          'limit.images': 1,
        })
        .then(response => {
          dispatch(addMarketplaceEntities(response));
          dispatch(fetchSubscriptionListingsSuccess(response));
          return response;
        })
        .catch(e => dispatch(fetchSubscriptionListingsError(storableError(e))));
    })
    .catch(e => dispatch(fetchSubscriptionListingsError(storableError(e))));
};

export const fetchFollowingUsers = userId => (dispatch, getState, sdk) => {
  dispatch(fetchFollowingUsersRequest());

  return sdk.users
    .show({ id: userId.uuid })
    .then(response => {
      const user = response.data.data;
      const followingIds = user.attributes.profile.publicData.followingIds || [];

      return followingIds;
    })
    .then(userIds => {
      const idFilter =
        userIds.length > 0 ? formatFilterForAPI(userIds, 'pub_id') : { pub_id: [''] };
      return integrationAPI.users
        .query({
          ...idFilter,
          include: ['profileImage'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
        })
        .then(response => {
          dispatch(addMarketplaceEntities(response.data));
          dispatch(fetchFollowingUsersSuccess(response.data));
          return response;
        })
        .catch(e => dispatch(fetchFollowingUsersError(storableError(e))));
    })
    .catch(e => dispatch(fetchFollowingUsersError(storableError(e))));
};

export const loadData = params => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserReviews(userId)),
    dispatch(queryUserComments(userId)),
    dispatch(fetchSubscriptionListings(userId)),
    dispatch(fetchFollowingUsers(userId)),
    dispatch(queryRecentlyViewedListings()),
  ]);
};
