import React, { useMemo } from 'react';
import { arrayOf, string, bool } from 'prop-types';
import { propTypes } from '../../util/types';
import { Slider, HeroListingCard, AspectRatioWrapper } from '../../components';
import classNames from 'classnames';

import { getAspectRatio } from '../HeroListingCard/HeroListingCard';
import css from './SectionHero.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

// slidesToShow
const SLIDES_TO_SHOW_TABLET = 1.5;
const SLIDES_TO_SHOW_DESKTOP = 1.5;
// slidesToScroll
const SLIDES_TO_SCROLL_TABLET = 0.5;
const SLIDES_TO_SCROLL_DESKTOP = 0.5;

const SCROLL_SNAP_ALIGN_START = 'start';
const SCROLL_SNAP_ALIGN_CENTER = 'center';

const sliderSettings = isMobileLayout => ({
  slidesToShow: {
    tablet: SLIDES_TO_SHOW_TABLET,
    desktop: SLIDES_TO_SHOW_DESKTOP,
  },
  slidesToScroll: {
    tablet: SLIDES_TO_SCROLL_TABLET,
    desktop: SLIDES_TO_SCROLL_DESKTOP,
  },
  scrollSnapAlign: isMobileLayout ? SCROLL_SNAP_ALIGN_START : SCROLL_SNAP_ALIGN_CENTER,
  sliderSpacing: true,
  autoplay: !isMobileLayout,
  autoplayDelay: 5000,
});

const SectionHero = props => {
  const { rootClassName, className, intl, viewport, listings, queryListingsInProgress } = props;
  const classes = classNames(rootClassName || css.root, className);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const aspectRatio = getAspectRatio(viewport);

  const filteredListings = useMemo(() => {
    if (listings.length > 0) {
      return listings.filter(l => l.attributes.metadata.isFeatured);
    }
    return [];
  }, [listings]);

  const listingCards = filteredListings.map((l, index) => (
    <HeroListingCard key={l.id.uuid} listing={l} listingIndex={index + 1} viewport={viewport} />
  ));

  const loadingPlaceholders = Array.from({ length: 3 }).map((_, index) => (
    <AspectRatioWrapper key={index} width={aspectRatio.width} height={aspectRatio.height}>
      <div className={css.placeholder} />
    </AspectRatioWrapper>
  ));

  const sliderTitle = intl.formatMessage({
    id: 'SectionHero.title',
  });

  return (
    <div className={classes}>
      <Slider
        id="LandingPage.heroSlider"
        className={css.slider}
        titleClassName={css.sliderTitle}
        title={sliderTitle}
        headerClassName={css.sliderHeader}
        arrowsClassName={css.sliderArrows}
        // slider settings
        {...sliderSettings(isMobileLayout)}
      >
        {queryListingsInProgress ? loadingPlaceholders : listingCards}
      </Slider>
    </div>
  );
};

SectionHero.defaultProps = {
  rootClassName: null,
  className: null,
  queryListingsInProgress: false,
};

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  listings: arrayOf(propTypes.listing).isRequired,
  queryListingsInProgress: bool,
};

export default SectionHero;
