import { util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { queryRecentlyViewedListings } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { creditsAPI } from '../../util/api';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SHOW_USER_REQUEST = 'app/ArtistProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ArtistProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ArtistProfilePage/SHOW_USER_ERROR';

export const FETCH_LISTINGS_REQUEST = 'app/ArtistProfilePage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ArtistProfilePage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ArtistProfilePage/FETCH_LISTINGS_ERROR';

export const QUERY_CREDITS_REQUEST = 'app/ArtistProfilePage/QUERY_CREDITS_REQUEST';
export const QUERY_CREDITS_SUCCESS = 'app/ArtistProfilePage/QUERY_CREDITS_SUCCESS';
export const QUERY_CREDITS_ERROR = 'app/ArtistProfilePage/QUERY_CREDITS_ERROR';

// ================ Reducer ================ //

const initialState = {
  showUserInProgress: false,
  showUserError: null,
  userId: null,
  fetchListingsInProgress: false,
  fetchListingsError: null,
  listingIds: [],
  queryCreditsInProgress: false,
  queryCreditsError: null,
  credits: [],
};

const resultIds = data => data.data.map(d => d.id);

export default function artistProfilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_USER_REQUEST:
      return {
        ...state,
        showUserInProgress: true,
        showUserError: null,
        userId: new UUID(payload.userId),
      };
    case SHOW_USER_SUCCESS:
      return {
        ...state,
        showUserInProgress: false,
        showUserError: null,
      };
    case SHOW_USER_ERROR:
      return {
        ...state,
        showUserInProgress: false,
        showUserError: payload,
      };

    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsError: null,
        listingIds: resultIds(payload.data),
      };
    case FETCH_LISTINGS_ERROR:
      return {
        ...state,
        shfetchListingsProgress: false,
        fetchListingsError: payload,
      };

    case QUERY_CREDITS_REQUEST:
      return {
        ...state,
        queryCreditsInProgress: true,
        queryCreditsError: null,
      };
    case QUERY_CREDITS_SUCCESS:
      return {
        ...state,
        queryCreditsInProgress: false,
        queryCreditsError: null,
        credits: payload.data.data,
      };
    case QUERY_CREDITS_ERROR:
      return {
        ...state,
        queryCreditsInProgress: false,
        queryCreditssError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ===== =========== //

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});
export const showUserSuccess = response => ({
  type: SHOW_USER_SUCCESS,
  payload: { data: response.data },
});
export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const fetchListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});
export const fetchListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryCreditsRequest = () => ({
  type: QUERY_CREDITS_REQUEST,
});
export const queryCreditsSuccess = response => ({
  type: QUERY_CREDITS_SUCCESS,
  payload: { data: response.data },
});
export const queryCreditsError = e => ({
  type: QUERY_CREDITS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));

  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess(response));
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e.response))));
};

export const fetchListings = userId => (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest());

  return sdk.listings
    .query({
      authorId: userId,
      include: ['images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
      'fields.image': [
        'variants.square-small',
        'variants.square-small2x',
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.portrait-crop',
        'variants.portrait-crop2x',
      ],
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 280,
        h: 392,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 560,
        h: 784,
        fit: 'crop',
      }),
      'limit.images': 1,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingsSuccess(response));
      return response;
    })
    .catch(e => dispatch(fetchListingsError(storableError(e))));
};

export const queryCredits = userId => (dispatch, getState, sdk) => {
  dispatch(queryCreditsRequest());

  return creditsAPI
    .query({ userId, include: ['listing'] })
    .then(response => {
      dispatch(queryCreditsSuccess(response));
      return response;
    })
    .catch(e => dispatch(queryCreditsError(storableError(e))));
};

export const loadData = params => (dispatch, getState, sdk) => {
  const userId = params.id;

  return Promise.all([
    dispatch(showUser(userId)),
    dispatch(fetchListings(userId)),
    dispatch(queryCredits(userId)),
    dispatch(queryRecentlyViewedListings()),
  ]);
};
