import React from 'react';
import { arrayOf, array, bool, func, object, oneOf, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import {
  EditListingBasicInfoPanel,
  EditListingTicketsPanel,
  EditListingTeamPanel,
  EditListingContentPanel,
} from '../../components';

import css from './EditListingWizard.module.css';

export const BASIC = 'basic';
export const CONTENT = 'content';
export const TEAM = 'team';
export const TICKETS = 'tickets';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [BASIC, CONTENT, TEAM, TICKETS];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history) => {
  const listingUUID = listingId.uuid;
  const routes = routeConfiguration();
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingUUID,
  };

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  history.push(to);
};

const pathParamsToPreviousTab = (params, tab, marketplaceTabs) => {
  const prevTabIndex = marketplaceTabs.findIndex(s => s === tab) - 1;
  const prevTab =
    prevTabIndex < marketplaceTabs.length ? marketplaceTabs[prevTabIndex] : marketplaceTabs[0];
  return { ...params, tab: prevTab };
};

const getPrevPathParams = (listingId, params, tab, marketplaceTabs, history) => {
  const currentPathParams = {
    ...params,
    id: listingId,
  };

  const previousPathParams = pathParamsToPreviousTab(currentPathParams, tab, marketplaceTabs);
  return previousPathParams;
};

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    newListingPublished,
    history,
    images,
    videos,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onVideoUpload,
    onRemoveImage,
    onRemoveVideo,
    onChange,
    onManageDisableScrolling,
    onPromoteImage,
    promoteImageInProgress,
    promoteImageError,
    updatedTab,
    updateInProgress,
    showListingInProgress,
    currentUser,
    showListings,
    organizationProfiles,
    teamUsers,
    getInvitesState,
    getInvitesFns,
    intl,
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);

  // New listing flow has automatic redirects to new tab on the wizard
  // and the last panel calls publishListing API endpoint.
  const automaticRedirectsForNewListingFlow = (tab, listingId) => {
    if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
      // Create listing flow: smooth scrolling polyfill to scroll to correct tab
      handleCreateFlowTabScrolling(false);

      // After successful saving of draft data, user should be redirected to next tab
      redirectAfterDraftUpdate(listingId, params, tab, marketplaceTabs, history);
    } else {
      handlePublishListing(listingId);
    }
  };

  const onCompleteEditListingWizardTab = (tab, updateValues) => {
    const onUpdateListingOrCreateListingDraft = isNewURI
      ? (tab, values) => onCreateListingDraft(values)
      : (tab, values) => onUpdateListing(tab, values);

    const updateListingValues = isNewURI
      ? updateValues
      : { ...updateValues, id: currentListing.id };

    return onUpdateListingOrCreateListingDraft(tab, updateListingValues)
      .then(r => {
        if (isNewListingFlow) {
          const listingId = r.data.data.id;
          automaticRedirectsForNewListingFlow(tab, listingId);
        }
      })
      .catch(e => {
        // No need for extra actions
        console.log(e);
      });
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      location,
      history,
      listing,
      currentUser,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      onManageDisableScrolling,
      onGetPrevPathParams: () =>
        getPrevPathParams(currentListing.id.uuid, params, tab, marketplaceTabs),
      // newListingPublished is flag for the last wizard tab
      ready: newListingPublished,
      disabled: false,
    };
  };

  switch (tab) {
    case BASIC: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewBasicInfo'
        : 'EditListingWizard.saveEditBasicInfo';
      return (
        <EditListingBasicInfoPanel
          {...panelProps(BASIC)}
          showListings={showListings}
          organizationProfiles={organizationProfiles}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case CONTENT: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewContent'
        : 'EditListingWizard.saveEditContent';

      return (
        <EditListingContentPanel
          {...panelProps(CONTENT)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          images={images}
          videos={videos}
          onImageUpload={onImageUpload}
          onVideoUpload={onVideoUpload}
          onRemoveImage={onRemoveImage}
          onRemoveVideo={onRemoveVideo}
          onPromoteImage={onPromoteImage}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          promoteImageInProgress={promoteImageInProgress}
          promoteImageError={promoteImageError}
          showListingInProgress={showListingInProgress}
        />
      );
    }
    case TEAM: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewTeam'
        : 'EditListingWizard.saveEditTeam';
      return (
        <EditListingTeamPanel
          {...panelProps(TEAM)}
          teamUsers={teamUsers}
          getInvitesState={getInvitesState}
          getInvitesFns={getInvitesFns}
          organizationProfiles={organizationProfiles}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case TICKETS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewTickets'
        : 'EditListingWizard.saveEditTickets';
      return (
        <EditListingTicketsPanel
          {...panelProps(TICKETS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  promoteImageInProgress: false,
  promoteImageError: null,
};

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,

  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    addExceptionError: object,
    deleteExceptionError: object,
  }).isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,
  videos: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,

  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onVideoUpload: func.isRequired,
  onRemoveImage: func.isRequired,
  onRemoveVideo: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,
  onPromoteImage: func.isRequired,
  promoteImageInProgress: bool.isRequired,
  promoteImageError: propTypes.error,
  showListings: arrayOf(propTypes.listing),
  teamUsers: arrayOf(propTypes.user),

  intl: intlShape.isRequired,
};

export default EditListingWizardTab;
