import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { apiBaseUrl } from '../../util/api';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, isWindowDefined } from '../../util/data';
import { isSignupemailValidError } from '../../util/errors';
import { createDefaultOrganizationProfile } from '../../util/organizations';
import { parse } from '../../util/urlHelpers';
import {
  Page,
  NamedLink,
  NamedRedirect,
  SocialLoginButton,
  Logo,
  Modal,
  TermsOfService,
} from '../../components';
import { ConfirmSignupForm, SignupForm } from '../../forms';
import { authenticationInProgress, signup, signupWithIdp } from '../../ducks/Auth.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import {
  updateProfile,
  uploadImage,
} from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

import EmailVerification from './EmailVerification';
import ProfileImage from './ProfileImage';
import { FacebookLogo, GoogleLogo } from './socialLoginLogos';
import css from './SignupPage.module.css';

export const SIGNUP_EMAIL_STEP = 'email';

export class SignupPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tosModalOpen: false,
      authError: Cookies.get('st-autherror')
        ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
        : null,
      authInfo: Cookies.get('st-authinfo')
        ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
        : null,
    };
  }

  componentDidMount() {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    Cookies.remove('st-autherror');
  }

  render() {
    const {
      params,
      authInProgress,
      currentUser,
      intl,
      isAuthenticated,
      history,
      location,
      scrollingDisabled,
      signupError,
      submitSignup,
      confirmError,
      submitSingupWithIdp,
      tab,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onResendVerificationEmail,
      onManageDisableScrolling,
      // Profile image
      image,
      onImageUpload,
      onUpdateProfile,
      uploadInProgress,
      updateInProgress,
      uploadImageError,
      updateProfileError,
    } = this.props;

    const isConfirm = tab === 'confirm';
    const locationFrom = location.state && location.state.from ? location.state.from : null;
    const authinfoFrom =
      this.state.authInfo && this.state.authInfo.from ? this.state.authInfo.from : null;
    const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;

    const fromPagePathWithoutSearch = from?.indexOf('?');
    const fromPagePath = from?.substring(0, fromPagePathWithoutSearch);
    const fromPageRoute = routeConfiguration().find(r => r.path === fromPagePath);

    const { recipientEmail: inviteEmail } = parse(from);
    const isVerificationSignupPage =
      fromPageRoute?.name === 'VerifyOrganizationInvitePageWithSignup' ||
      fromPageRoute?.name === 'VerifyListingInvitePageWithSignup';
    const fillSignupFormEmailWithInviteEmail = isVerificationSignupPage ? inviteEmail : null;

    const user = ensureCurrentUser(currentUser);
    const profileImage = user.profileImage;
    const currentUserLoaded = !!user.id;

    // We only want to show the email verification dialog in the signup
    // tab if the user isn't being redirected somewhere else
    // (i.e. `from` is present). We must also check the `emailVerified`
    // flag only when the current user is fully loaded.
    const showEmailVerification = currentUserLoaded && !user.attributes.emailVerified;

    // Already authenticated, redirect away from auth page
    if (isAuthenticated && from) {
      return <Redirect to={from} />;
    } else if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
      return <NamedRedirect name="LandingPage" />;
    } else if (
      isAuthenticated &&
      currentUserLoaded &&
      showEmailVerification &&
      profileImage &&
      params.step !== SIGNUP_EMAIL_STEP &&
      params.role
    ) {
      return (
        <NamedRedirect name="SignupPage" params={{ role: params.role, step: SIGNUP_EMAIL_STEP }} />
      );
    } else if (!params.role) {
      return <NamedRedirect name="RoleSelectionPage" />;
    }

    const signupErrorMessage = (
      <div className={css.error}>
        {isSignupemailValidError(signupError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    );

    const confirmErrorMessage = confirmError ? (
      <div className={css.error}>
        {isSignupemailValidError(confirmError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    ) : null;

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    const renderSignupError = errorMessage(signupError, signupErrorMessage);

    const handleSubmitSignup = values => {
      const { fname, lname, ...rest } = values;

      const firstName = fname.trim();
      const lastName = lname.trim();

      const recentlyViewedListingIdsFromLocalStorage =
        (isWindowDefined() && JSON.parse(localStorage.getItem('recentlyViewedListingIds'))) || null;
      const restValues = {
        ...rest,
        recentlyViewedListingIds: recentlyViewedListingIdsFromLocalStorage,
      };

      const signupParams = {
        firstName,
        lastName,
        publicData: {
          role: params.role,
          organizationProfiles: createDefaultOrganizationProfile(),
          name: {
            firstName,
            lastName,
            displayName: `${firstName} ${lastName}`,
          },
        },
        ...restValues,
      };
      submitSignup(signupParams);
    };

    const handleSubmitConfirm = values => {
      const { idpToken, email, firstName, lastName, idpId } = this.state.authInfo;
      const {
        email: newEmail,
        firstName: newFirstName,
        lastName: newLastName,
        birthday,
        phoneNumber,
      } = values;

      const recentlyViewedListingIdsFromLocalStorage =
        (isWindowDefined() && JSON.parse(localStorage.getItem('recentlyViewedListingIds'))) || null;
      const recentlyViewedListingIds = recentlyViewedListingIdsFromLocalStorage;

      // Pass email, firstName or lastName to Flex API only if user has edited them
      // sand they can't be fetched directly from idp provider (e.g. Facebook)
      const authParams = {
        ...(newEmail !== email && { email: newEmail }),
        ...(newFirstName !== firstName && { firstName: newFirstName }),
        ...(newLastName !== lastName && { lastName: newLastName }),
      };

      const publicData = {
        role: params.role,
        organizationProfiles: createDefaultOrganizationProfile(),
        name: {
          firstName: newFirstName,
          lastName: newLastName,
          displayName: `${newFirstName} ${newLastName}`,
        },
      };

      const protectedData = {
        birthday,
        email: newEmail,
        phoneNumber,
        recentlyViewedListingIds,
      };

      submitSingupWithIdp({
        idpToken,
        idpId,
        ...authParams,
        publicData,
        protectedData,
      });
    };

    const getDefaultRoutes = () => {
      const routes = routeConfiguration();
      const baseUrl = apiBaseUrl();

      // Route where the user should be returned after authentication
      // This is used e.g. with EditListingPage and ListingPage
      const fromParam = from ? `from=${from}` : '';

      // Default route where user is returned after successfull authentication
      const defaultReturn = pathByRouteName('LandingPage', routes);
      const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

      // Route for confirming user data before creating a new user
      const defaultConfirm = pathByRouteName('ConfirmSignupPage', routes, { role: params.role });
      const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

      return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
    };
    const authWithFacebook = () => {
      const defaultRoutes = getDefaultRoutes();
      const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
      window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    };

    const authWithGoogle = () => {
      const defaultRoutes = getDefaultRoutes();
      const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
      window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    };

    const idp = this.state.authInfo
      ? this.state.authInfo.idpId.replace(/^./, str => str.toUpperCase())
      : null;

    // Form for confirming information frm IdP (e.g. Facebook)
    // before new user is created to Flex
    const confirmForm = (
      <div className={css.content}>
        <h1 className={css.signupWithIdpTitle}>
          <FormattedMessage id="AuthenticationPage.confirmSignupWithIdpTitle" values={{ idp }} />
        </h1>
        <p className={css.confirmInfoText}>
          <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
        </p>
        {confirmErrorMessage}
        <ConfirmSignupForm
          className={css.form}
          onSubmit={handleSubmitConfirm}
          inProgress={authInProgress}
          onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
          authInfo={this.state.authInfo}
          idp={idp}
        />
      </div>
    );

    // Social login buttons
    const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
    const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const showSocialLogins = !isVerificationSignupPage && (showFacebookLogin || showGoogleLogin);

    const facebookButtonText = <FormattedMessage id="AuthenticationPage.signupWithFacebook" />;

    const googleButtonText = <FormattedMessage id="AuthenticationPage.signupWithGoogle" />;
    const socialLoginButtonsMaybe = showSocialLogins ? (
      <div className={css.idpButtons}>
        <div className={css.socialButtonsOr}>
          <span className={css.socialButtonsOrText}>
            <FormattedMessage id="AuthenticationPage.or" />
          </span>
        </div>

        {showFacebookLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={() => authWithFacebook()}>
              <span className={css.buttonIcon}>{FacebookLogo}</span>
              {facebookButtonText}
            </SocialLoginButton>
          </div>
        ) : null}

        {showGoogleLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={() => authWithGoogle()}>
              <span className={css.buttonIcon}>{GoogleLogo}</span>
              {googleButtonText}
            </SocialLoginButton>
          </div>
        ) : null}
      </div>
    ) : null;

    const loginSeparator = (
      <div className={css.loginSeparator}>
        <p className={css.loginText}>
          <FormattedMessage
            id="AuthenticationPage.loginText"
            values={{
              loginLink: (
                <NamedLink className={css.loginLink} name="LoginPage">
                  <FormattedMessage id="AuthenticationPage.loginLink" />
                </NamedLink>
              ),
            }}
          />
        </p>
      </div>
    );

    // SignupForm
    const signupForm = (
      <div className={css.content}>
        {params.role === config.userRoles['audience'] ? (
          <h1 className={css.contentTitle}>
            <FormattedMessage id="AuthenticationPage.signupAsAudienceTitle" />
          </h1>
        ) : (
          <h1 className={css.contentTitle}>
            <FormattedMessage id="AuthenticationPage.signupAsArtistTitle" />
          </h1>
        )}

        {renderSignupError}

        {fillSignupFormEmailWithInviteEmail ? (
          <div className={css.attentionBox}>
            <p className={css.attentionText}>
              <FormattedMessage
                id="AuthenticationPage.inviteSignup"
                values={{ email: <strong>{inviteEmail}</strong> }}
              />
            </p>
          </div>
        ) : null}
        <SignupForm
          className={css.signupForm}
          initialValues={{
            email: fillSignupFormEmailWithInviteEmail,
          }}
          onSubmit={handleSubmitSignup}
          inProgress={authInProgress}
          onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
        />

        {socialLoginButtonsMaybe}
        {loginSeparator}
      </div>
    );

    const formContent = isConfirm ? confirmForm : signupForm;

    const emailVerificationContent = (
      <EmailVerification
        user={user}
        isArtistRole={params.role === config.userRoles['artist']}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
        onResendVerificationEmail={onResendVerificationEmail}
      />
    );

    const profileImageContent = (
      <ProfileImage
        user={user}
        currentRole={params.role}
        history={history}
        image={image}
        onImageUpload={onImageUpload}
        onUpdateProfile={onUpdateProfile}
        uploadInProgress={uploadInProgress}
        updateInProgress={updateInProgress}
        uploadImageError={uploadImageError}
        updateProfileError={updateProfileError}
      />
    );

    const stepContent =
      params.step === SIGNUP_EMAIL_STEP ? emailVerificationContent : profileImageContent;
    const signupFlowContent = showEmailVerification ? stepContent : formContent;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'AuthenticationPage.schemaTitleSignup' },
      { siteTitle }
    );

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        <div className={css.root}>
          <div className={css.logoHeader}>
            <NamedLink className={css.logoLink} name="LandingPage">
              <Logo className={css.logo} showBrandIcon={false} />
            </NamedLink>
          </div>
          {signupFlowContent}
        </div>
        <Modal
          id="AuthenticationPage.tos"
          isOpen={this.state.tosModalOpen}
          onClose={() => this.setState({ tosModalOpen: false })}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.termsWrapper}>
            <h2 className={css.termsHeading}>
              <FormattedMessage id="AuthenticationPage.termsHeading" />
            </h2>
            <TermsOfService />
          </div>
        </Modal>
      </Page>
    );
  }
}

SignupPageComponent.defaultProps = {
  currentUser: null,
  signupError: null,
  confirmError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
  showSocialLoginsForTests: false,
};

const { bool, func, object, oneOf, shape } = PropTypes;

SignupPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  confirmError: propTypes.error,

  submitSignup: func.isRequired,
  tab: oneOf(['signup', 'confirm']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, signupError, confirmError } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitSignup: params => dispatch(signup(params)),
  submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onImageUpload: data => dispatch(uploadImage(data)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SignupPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SignupPageComponent);

export default SignupPage;
