import React from 'react';
import PropTypes from 'prop-types';
import { IconSpinner } from '../../components';
import classNames from 'classnames';

import css from './NotificationBadge.module.css';

const NotificationBadge = props => {
  const { className, rootClassName, fetchInProgress, count } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <span className={classes}>
      {fetchInProgress ? <IconSpinner className={css.spinner} /> : count}
    </span>
  );
};

const { number, string, bool } = PropTypes;

NotificationBadge.defaultProps = {
  className: null,
  rootClassName: null,
  fetchInProgress: false,
};

NotificationBadge.propTypes = {
  count: number.isRequired,
  className: string,
  rootClassName: string,
  fetchInProgress: bool,
};

export default NotificationBadge;
