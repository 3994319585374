import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { integrationAPI } from '../../util/api';
import { fetchSelectedOrganization } from '../../ducks/organizations.duck';
import config from '../../config';

const getSubscriptionUsers = subscriptions =>
  subscriptions.filter(s => s.userId).map(s => s.userId);

// ================ Action types ================ //

export const FETCH_SUBSCRIPTION_USERS_REQUEST = 'app/MembersPage/FETCH_SUBSCRIPTION_USERS_REQUEST';
export const FETCH_SUBSCRIPTION_USERS_SUCCESS = 'app/MembersPage/FETCH_SUBSCRIPTION_USERS_SUCCESS';
export const FETCH_SUBSCRIPTION_USERS_ERROR = 'app/MembersPage/FETCH_SUBSCRIPTION_USERS_ERROR';

export const FETCH_TRANSACTIONS_REQUEST = 'app/MembersPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/MembersPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/MembersPage/FETCH_TRANSACTIONS_ERROR';

export const FETCH_SUBSCRIPTIONS_SUCCESS = 'app/MembersPage/FETCH_SUBSCRIPTIONS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  fetchSubscriptionUsersInProgress: false,
  fetchSubscriptionUsersError: null,
  subscriptionUserIds: [],
  subscriptions: [],
  fetchTransactionsInProgress: false,
  fetchTransactionsError: null,
  transactionIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

export default function membersPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SUBSCRIPTION_USERS_REQUEST:
      return {
        ...state,
        fetchSubscriptionUsersInProgress: true,
        fetchSubscriptionUsersError: null,
      };
    case FETCH_SUBSCRIPTION_USERS_SUCCESS:
      return {
        ...state,
        fetchSubscriptionUsersInProgress: false,
        fetchSubscriptionUsersError: null,
        subscriptionUserIds: resultIds(payload.data).filter(id =>
          payload.userIds.includes(id.uuid)
        ),
      };
    case FETCH_SUBSCRIPTION_USERS_ERROR:
      return {
        ...state,
        fetchSubscriptionUsersInProgress: false,
        fetchSubscriptionUsersError: payload,
      };

    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetchTransactionsInProgress: true,
        fetchTransactionsError: null,
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionIds: entityRefs(payload.transactions),
        fetchTransactionsInProgress: false,
        fetchTransactionsError: null,
      };
    case FETCH_TRANSACTIONS_ERROR:
      return {
        ...state,
        fetchTransactionsInProgress: false,
        fetchTransactionsError: payload,
      };

    case FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: payload.subscriptions,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchSubscriptionUsersRequest = () => ({
  type: FETCH_SUBSCRIPTION_USERS_REQUEST,
});
export const fetchSubscriptionUsersSuccess = (response, userIds) => ({
  type: FETCH_SUBSCRIPTION_USERS_SUCCESS,
  payload: { data: response.data, userIds },
});
export const fetchSubscriptionUsersError = e => ({
  type: FETCH_SUBSCRIPTION_USERS_ERROR,
  error: true,
  payload: e,
});

export const fetchTransactionsRequest = () => ({
  type: FETCH_TRANSACTIONS_REQUEST,
});
export const fetchTransactionsSuccess = transactions => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: { transactions },
});
export const fetchTransactionsError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

export const fetchSubscriptionsSuccess = subscriptions => ({
  type: FETCH_SUBSCRIPTIONS_SUCCESS,
  payload: { subscriptions },
});

// ================ Thunks ================ //

export const fetchTransactions = (userIds, selectedOrganization) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchTransactionsRequest());

  let transactions = [];

  try {
    for (const id of userIds) {
      const invitedToOrganizationAPI = selectedOrganization.invited;

      const response = invitedToOrganizationAPI
        ? await integrationAPI.transactions.query({
            organizationId: selectedOrganization ? selectedOrganization.id : null,
            userId: id,
            include: ['customer'],
          })
        : await sdk.transactions.query({
            only: 'sale',
            userId: id,
            include: ['customer'],
          });

      const transactionsFromResponse = invitedToOrganizationAPI
        ? response.data.data.data
        : response.data.data;

      transactions = [...transactions, ...transactionsFromResponse];

      dispatch(addMarketplaceEntities(invitedToOrganizationAPI ? response.data : response));
    }

    dispatch(fetchTransactionsSuccess(transactions));
  } catch (e) {
    dispatch(fetchTransactionsError(storableError(e)));
  }
};

export const fetchSubscriptionUsers = () => async (dispatch, getState, sdk) => {
  dispatch(fetchSubscriptionUsersRequest());

  const selectedOrganization = await dispatch(fetchSelectedOrganization());

  return sdk.currentUser.show().then(async userResponse => {
    const authorId = userResponse.data.data.id;

    return sdk.listings
      .query({
        authorId: selectedOrganization ? selectedOrganization.creatorId : authorId,
        pub_type: config.listingTypes['experience'],
        pub_organizationId: selectedOrganization ? selectedOrganization.id : '',
      })
      .then(async response => {
        const listings = response.data.data;

        const listingSubscriptions = listings
          .filter(l => l.attributes.metadata.subscriptions)
          .map(l => l.attributes.metadata.subscriptions)
          .flat(1);
        const subscriptionUserIds = Array.from(new Set(getSubscriptionUsers(listingSubscriptions)));

        try {
          const usersResponse = await integrationAPI.users.query({
            include: ['profileImage'],
            'fields.image': [
              // Avatars
              'variants.square-small',
              'variants.square-small2x',
            ],
          });
          dispatch(addMarketplaceEntities(usersResponse.data));
          dispatch(fetchSubscriptionsSuccess(listingSubscriptions));
          dispatch(fetchSubscriptionUsersSuccess(usersResponse.data, subscriptionUserIds));
          dispatch(fetchTransactions(subscriptionUserIds, selectedOrganization));
          return usersResponse;
        } catch (e) {
          return dispatch(fetchSubscriptionUsersError(storableError(e)));
        }
      });
  });
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  return dispatch(fetchSubscriptionUsers());
};
