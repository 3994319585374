import pick from 'lodash/pick';
import { types as sdkTypes, util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { integrationAPI } from '../../util/api';
import {
  calculateAverageReview,
  denormaliseShowRatings,
  denormalisedResponseEntities,
  findFeaturedReview,
  findUserShowRatingByListingId,
  getListingShowRatings,
  getUserShowRatings,
} from '../../util/data';
import { fetchCurrentUserReactionsSuccess } from '../../ducks/user.duck';
import { showOrganizationProfile } from '../OrganizationProfilePage/OrganizationProfilePage.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { v4 as uuidv4 } from 'uuid';
import { querySimilarShows } from '../ListingPage/ListingPage.duck';
import { queryRecentlyViewedListings } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const SEND_REVIEW_REQUEST = 'app/ListingPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/ListingPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/ListingPage/SEND_REVIEW_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const ADD_REVIEW_LIKE_REQUEST = 'app/ListingPage/ADD_REVIEW_LIKE_REQUEST';
export const ADD_REVIEW_LIKE_SUCCESS = 'app/ListingPage/ADD_REVIEW_LIKE_SUCCESS';
export const ADD_REVIEW_LIKE_ERROR = 'app/ListingPage/ADD_REVIEW_LIKE_ERROR';

export const ADD_REVIEW_DISLIKE_REQUEST = 'app/ListingPage/ADD_REVIEW_DISLIKE_REQUEST';
export const ADD_REVIEW_DISLIKE_SUCCESS = 'app/ListingPage/ADD_REVIEW_DISLIKE_SUCCESS';
export const ADD_REVIEW_DISLIKE_ERROR = 'app/ListingPage/ADD_REVIEW_DISLIKE_ERROR';

export const RATE_SHOW_REQUEST = 'app/ListingPage/RATE_SHOW_REQUEST';
export const RATE_SHOW_SUCCESS = 'app/ListingPage/RATE_SHOW_SUCCESS';
export const RATE_SHOW_ERROR = 'app/ListingPage/RATE_SHOW_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingInProgress: false,
  showListingError: null,
  reviews: [],
  averageReview: 0,
  featuredReviewId: null,
  fetchReviewsInProgress: false,
  fetchReviewsError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  addReviewLikeInProgress: false,
  addReviewLikeError: null,
  rateShowInProgress: false,
  rateShowError: null,
};

const getListing = (id, state) => {
  const ref = { id, type: 'listing' };
  const listings = getMarketplaceEntities(state, [ref]);
  return listings.length === 1 ? listings[0] : null;
};

const updateLikedReview = (reviews, payload) =>
  reviews.map(review => {
    if (review.id === payload.reviewId) {
      const userLiked = payload.currentUserReactions.some(
        reaction => reaction.reviewId === payload.reviewId && reaction.type === 'like'
      );
      const userDisliked = payload.currentUserReactions.some(
        reaction => reaction.reviewId === payload.reviewId && reaction.type === 'dislike'
      );

      const { likes, dislikes } = review.attributes;

      let newLikes = likes;
      let newDislikes = dislikes;

      if (userLiked) {
        // If already liked, remove the like
        newLikes = likes - 1;
      } else if (userDisliked) {
        // If disliked and now liking, remove the dislike and increment likes
        newLikes = likes + 1;
        newDislikes = dislikes - 1;
      } else {
        // If neutral and now liking, increment likes
        newLikes = likes + 1;
      }

      return {
        ...review,

        attributes: {
          ...review.attributes,
          likes: newLikes,
          dislikes: newDislikes,
        },
      };
    }

    return review;
  });

const updateDislikedReview = (reviews, payload) =>
  reviews.map(review => {
    if (review.id === payload.reviewId) {
      const userLiked = payload.currentUserReactions.some(
        reaction => reaction.reviewId === payload.reviewId && reaction.type === 'like'
      );
      const userDisliked = payload.currentUserReactions.some(
        reaction => reaction.reviewId === payload.reviewId && reaction.type === 'dislike'
      );

      const { likes, dislikes } = review.attributes;

      let newLikes = likes;
      let newDislikes = dislikes;

      if (userDisliked) {
        // If already disliked, remove the dislike
        newDislikes = dislikes - 1;
      } else if (userLiked) {
        // If liked and now disliking, remove the like and increment dislikes
        newLikes = likes - 1;
        newDislikes = dislikes + 1;
      } else {
        // If neutral and now disliking, increment dislikes
        newDislikes = dislikes + 1;
      }

      return {
        ...review,
        attributes: {
          ...review.attributes,
          likes: newLikes,
          dislikes: newDislikes,
        },
      };
    }

    return review;
  });

const updateLikedReviewReactions = (userReactions, reviewId) => {
  const currentReaction = userReactions.find(reaction => reaction.reviewId === reviewId);

  const hasLiked = userReactions.some(
    reaction => reaction.reviewId === reviewId && reaction.type === 'like'
  );
  const hasDisliked = userReactions.some(
    reaction => reaction.reviewId === reviewId && reaction.type === 'dislike'
  );

  if (hasLiked) {
    return userReactions.filter(reaction => reaction.id !== currentReaction.id);
  } else if (hasDisliked) {
    return userReactions.map(reaction =>
      reaction.id === currentReaction.id ? { ...reaction, type: 'like' } : reaction
    );
  } else {
    const newReaction = { id: uuidv4(), reviewId, type: 'like' };
    return [...userReactions, newReaction];
  }
};

const updateDislikedReviewReactions = (userReactions, reviewId) => {
  const currentReaction = userReactions.find(reaction => reaction.reviewId === reviewId);

  const hasLiked = userReactions.some(
    reaction => reaction.reviewId === reviewId && reaction.type === 'like'
  );
  const hasDisliked = userReactions.some(
    reaction => reaction.reviewId === reviewId && reaction.type === 'dislike'
  );

  if (hasDisliked) {
    return userReactions.filter(reaction => reaction.id !== currentReaction.id);
  } else if (hasLiked) {
    return userReactions.map(reaction =>
      reaction.id === currentReaction.id ? { ...reaction, type: 'dislike' } : reaction
    );
  } else {
    const newReaction = { id: uuidv4(), reviewId, type: 'dislike' };
    return [...userReactions, newReaction];
  }
};

const reviewsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingInProgress: true, showListingError: null };
    case SHOW_LISTING_SUCCESS:
      return { ...state, showListingInProgress: false, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingInProgress: false, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return {
        ...state,
        fetchReviewsInProgress: true,
        fetchReviewsError: null,
        reviews: [],
        averageReview: 0,
      };
    case FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: payload.reviews,
        averageReview: payload.averageReview,
        featuredReviewId: payload.featuredReview ? payload.featuredReview.id : null,
        fetchReviewsInProgress: false,
        fetchReviewsError: null,
      };
    case FETCH_REVIEWS_ERROR:
      return {
        ...state,
        fetchReviewsInProgress: false,
        fetchReviewsError: payload,
      };
    case SEND_REVIEW_REQUEST:
      return {
        ...state,
        sendReviewInProgress: true,
        sendReviewError: null,
      };
    case SEND_REVIEW_SUCCESS:
      return {
        ...state,
        sendReviewInProgress: false,
        sendReviewError: null,
        // reviews: [payload.review, ...state.reviews],
      };
    case SEND_REVIEW_ERROR:
      return {
        ...state,
        sendReviewInProgress: false,
        sendReviewError: payload,
      };

    case ADD_REVIEW_LIKE_REQUEST:
      return {
        ...state,
        addReviewLikeInProgress: true,
        addReviewLikeError: null,
        reviews: updateLikedReview(state.reviews, payload),
      };
    case ADD_REVIEW_LIKE_SUCCESS:
      return {
        ...state,
        addReviewLikeInProgress: false,
        addReviewLikeError: null,
      };
    case ADD_REVIEW_LIKE_ERROR:
      return {
        ...state,
        addReviewLikeInProgress: false,
        addReviewLikeError: payload,
      };

    case ADD_REVIEW_DISLIKE_REQUEST:
      return {
        ...state,
        addReviewDislikeInProgress: true,
        addReviewDislikeError: null,
        reviews: updateDislikedReview(state.reviews, payload),
      };
    case ADD_REVIEW_DISLIKE_SUCCESS:
      return {
        ...state,
        addReviewDislikeInProgress: false,
        addReviewDislikeError: null,
      };
    case ADD_REVIEW_DISLIKE_ERROR:
      return {
        ...state,
        addReviewDislikeInProgress: false,
        addReviewDislikeError: payload,
      };

    case RATE_SHOW_REQUEST:
      return {
        ...state,
        rateShowInProgress: true,
        rateShowError: null,
      };
    case RATE_SHOW_SUCCESS:
      return {
        ...state,
        rateShowInProgress: false,
        rateShowError: null,
      };
    case RATE_SHOW_REQUEST:
      return {
        ...state,
        rateShowInProgress: false,
        rateShowError: payload,
      };

    default:
      return state;
  }
};

export default reviewsPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingSuccess = () => ({ type: SHOW_LISTING_SUCCESS });
export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({
  type: FETCH_REVIEWS_REQUEST,
});
export const fetchReviewsSuccess = (reviews, averageReview, featuredReview) => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload: { reviews, averageReview, featuredReview },
});
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const sendReviewRequest = () => ({
  type: SEND_REVIEW_REQUEST,
});
export const sendReviewSuccess = review => ({
  type: SEND_REVIEW_SUCCESS,
  payload: { review },
});
export const sendReviewError = e => ({
  type: SEND_REVIEW_ERROR,
  error: true,
  payload: e,
});

export const addReviewLikeRequest = (reviewId, currentUserReactions) => ({
  type: ADD_REVIEW_LIKE_REQUEST,
  payload: { reviewId, currentUserReactions },
});

export const addReviewLikeSuccess = reviewId => ({
  type: ADD_REVIEW_LIKE_SUCCESS,
  payload: { reviewId },
});

export const addReviewLikeError = e => ({
  type: ADD_REVIEW_LIKE_ERROR,
  error: true,
  payload: e,
});

export const addReviewDislikeRequest = (reviewId, currentUserReactions) => ({
  type: ADD_REVIEW_DISLIKE_REQUEST,
  payload: { reviewId, currentUserReactions },
});
export const addReviewDislikeSuccess = reviewId => ({
  type: ADD_REVIEW_DISLIKE_SUCCESS,
  payload: { reviewId },
});
export const addReviewDislikeError = e => ({
  type: ADD_REVIEW_DISLIKE_ERROR,
  error: true,
  payload: e,
});

export const rateShowRequest = () => ({
  type: RATE_SHOW_REQUEST,
});
export const rateShowSuccess = () => ({
  type: RATE_SHOW_SUCCESS,
});
export const rateShowError = e => ({
  type: RATE_SHOW_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false, useIntegration = false) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(showListingRequest(listingId));

  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.listing': ['title', 'description', 'geolocation', 'price', 'publicData', 'metadata'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',
      'variants.hero-scale',
      'variants.hero-crop',
      'variants.portrait-crop',
      'variants.portrait-crop2x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    'imageVariant.hero-scale': 'w:1440;h:400;fit:scale',
    'imageVariant.hero-crop': 'w:1440;h:400;fit:crop',
    'imageVariant.portrait-crop': sdkUtil.objectQueryString({
      w: 280,
      h: 392,
      fit: 'crop',
    }),
    'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
      w: 560,
      h: 784,
      fit: 'crop',
    }),
  };

  const show = isOwn
    ? useIntegration
      ? integrationAPI.listings.show({
          ...params,
          id: listingId.uuid,
        })
      : sdk.ownListings.show(params)
    : sdk.listings.show(params);

  return show
    .then(response => {
      const data = isOwn && useIntegration ? response.data : response;
      const listing = denormalisedResponseEntities(data)?.[0];

      const organizationProfileId = listing.attributes.publicData.organizationId;

      dispatch(showListingSuccess(data));
      dispatch(addMarketplaceEntities(data));
      dispatch(showOrganizationProfile(organizationProfileId));

      return listing;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const getAverageReview = (currentListing, reviews) => {
  const listingShowRatings = getListingShowRatings(currentListing);
  const denormalisedShowRatings = denormaliseShowRatings(listingShowRatings);
  const reviewsForAverageReview = reviews.concat(denormalisedShowRatings);
  const averageReview = calculateAverageReview(reviewsForAverageReview);

  const hasReviewsAndRatings = reviewsForAverageReview.length > 0;
  return hasReviewsAndRatings ? averageReview : 0;
};

export const fetchReviews = listingId => async (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());

  try {
    const listing = getListing(listingId, getState());
    const reviews = listing.attributes?.metadata?.reviews || [];

    const usersResponse = await integrationAPI.users.query({
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    });
    const users = denormalisedResponseEntities(usersResponse.data);
    const reviewsWithUsers = reviews.map(r => {
      const { authorId, ...rest } = r;

      return {
        ...rest,
        author: users.find(u => u.id.uuid === r.authorId),
      };
    });

    const averageReview = getAverageReview(listing, reviews);
    const featuredReview = findFeaturedReview(reviews);

    dispatch(fetchReviewsSuccess(reviewsWithUsers, averageReview, featuredReview));
  } catch (e) {
    dispatch(fetchReviewsError(storableError(e)));
  }
};

export const sendReview = (listingId, review) => (dispatch, getState, sdk) => {
  dispatch(sendReviewRequest());

  return integrationAPI.reviews
    .send({ listingId, review })
    .then(newReview => {
      const { authorId, ...rest } = newReview;
      dispatch(
        sendReviewSuccess({
          ...rest,
          author: getState().user.currentUser,
        })
      );
      return newReview;
    })
    .catch(e => dispatch(sendReviewError(storableError(e))));
};

export const addReviewLike = (listingId, reviewId) => (dispatch, getState, sdk) => {
  const { currentUserReactions } = getState().user;

  dispatch(addReviewLikeRequest(reviewId, currentUserReactions));
  dispatch(
    fetchCurrentUserReactionsSuccess(updateLikedReviewReactions(currentUserReactions, reviewId))
  );

  return integrationAPI.reviews
    .addLike({ listingId, reviewId })
    .then(response => {
      dispatch(addReviewLikeSuccess(reviewId));
      return response;
    })
    .catch(e => dispatch(addReviewLikeError(storableError(e))));
};

export const addReviewDislike = (listingId, reviewId) => (dispatch, getState, sdk) => {
  const { currentUserReactions } = getState().user;

  dispatch(addReviewDislikeRequest(reviewId, currentUserReactions));
  dispatch(
    fetchCurrentUserReactionsSuccess(updateDislikedReviewReactions(currentUserReactions, reviewId))
  );

  return integrationAPI.reviews
    .addDislike({ listingId, reviewId })
    .then(response => {
      dispatch(addReviewDislikeSuccess(reviewId));
      return response;
    })
    .catch(e => dispatch(addReviewDislikeError(storableError(e))));
};

const submitShowRating = (rating, listingId) => async (dispatch, getState, sdk) => {
  dispatch(rateShowRequest());

  try {
    const { currentUser } = getState().user;

    const showRatingsFromPublicData = getUserShowRatings(currentUser);
    const showRatings = [...showRatingsFromPublicData, { listingId, rating }];

    await dispatch(
      updateProfile({
        publicData: {
          showRatings,
        },
      })
    );

    const listing = getListing(new UUID(listingId), getState());
    const listingShowRatingsFromMetadata = listing.attributes.metadata.showRatings || [];
    const listingShowRatings = [
      ...listingShowRatingsFromMetadata,
      { authorId: currentUser.id.uuid, rating },
    ];

    const response = await integrationAPI.listings.update({
      id: listingId,
      metadata: {
        showRatings: listingShowRatings,
      },
    });

    dispatch(addMarketplaceEntities(response));
    dispatch(rateShowSuccess(response));
    return response;
  } catch (error) {
    const errorMessage = `Error submitting show rating: ${error.message}`;
    dispatch(rateShowError(storableError(errorMessage)));
    throw new Error(errorMessage);
  }
};

const editShowRating = (rating, listingId) => async (dispatch, getState, sdk) => {
  dispatch(rateShowRequest());

  try {
    const { currentUser } = getState().user;

    const showRatingsFromPublicData = getUserShowRatings(currentUser);
    const showRatings = showRatingsFromPublicData.map(r =>
      r.listingId === listingId ? { ...r, rating } : r
    );

    await dispatch(
      updateProfile({
        publicData: {
          showRatings,
        },
      })
    );

    const listing = getListing(new UUID(listingId), getState());
    const listingShowRatingsFromMetadata = getListingShowRatings(listing);
    const listingShowRatings = listingShowRatingsFromMetadata.map(r =>
      r.authorId === currentUser.id.uuid ? { ...r, rating } : r
    );

    const response = await integrationAPI.listings.update({
      id: listingId,
      metadata: {
        showRatings: listingShowRatings,
      },
    });

    dispatch(addMarketplaceEntities(response));
    dispatch(rateShowSuccess(response));
    return response;
  } catch (error) {
    const errorMessage = `Error editing show rating: ${error.message}`;
    dispatch(rateShowError(storableError(errorMessage)));
    throw new Error(errorMessage);
  }
};

export const rateShow = (rating, listingId) => async (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;

  const showRatings = getUserShowRatings(currentUser);
  const showRating = findUserShowRatingByListingId(showRatings, listingId);

  if (showRating) {
    const editRating = await dispatch(editShowRating(rating, listingId));
    return editRating;
  } else {
    const submitRating = await dispatch(submitShowRating(rating, listingId));
    return submitRating;
  }
};

export const loadData = params => async (dispatch, getState, sdk) => {
  const listingId = new UUID(params.id);

  return dispatch(showListing(listingId)).then(listing => {
    const category = listing?.attributes?.publicData?.category;

    const similarShowsFromState = getState().ListingPage.similarShowIds;
    const recentlyViewedListingsFromState = getState().user.recentlyViewedListingIds;

    const callSimilarShowsPromise = similarShowsFromState.length === 0;
    const callRecentlyViewedListingsPromise = recentlyViewedListingsFromState.length === 0;

    return Promise.all([
      callSimilarShowsPromise ? dispatch(querySimilarShows(category)) : Promise.resolve(),
      dispatch(fetchReviews(listingId)),
      callRecentlyViewedListingsPromise
        ? dispatch(queryRecentlyViewedListings(listingId.uuid))
        : Promise.resolve(),
    ]);
  });
};
