import React from 'react';
import { arrayOf, string, bool } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import classNames from 'classnames';

import css from './SectionJoin.module.css';

const WatchlistIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="-1.5 -1.5 48 48"
      {...props}
    >
      <path
        stroke="#fd1838"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
        d="m35.736 43.322-11.582-8.423a2.813 2.813 0 0 0-3.308 0L9.264 43.322a1.405 1.405 0 0 1-2.233-1.135V4.22a2.813 2.813 0 0 1 2.813-2.813h25.312A2.813 2.813 0 0 1 37.97 4.22v37.968a1.406 1.406 0 0 1-2.233 1.135Z"
      />
      <path
        stroke="#fd1838"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
        d="m23.494 10.858 2.342 4.61h3.988a1.091 1.091 0 0 1 .785 1.874l-3.661 3.604 2.028 4.66a1.103 1.103 0 0 1-1.573 1.381L22.5 24.221l-4.903 2.759a1.104 1.104 0 0 1-1.573-1.382l2.029-4.66-3.662-3.603a1.091 1.091 0 0 1 .785-1.875h3.988l2.342-4.602a1.114 1.114 0 0 1 1.988 0Z"
      />
    </svg>
  );
};

const SectionJoin = props => {
  const { rootClassName, className, currentUser, listings, queryListingsInProgress } = props;
  const classes = classNames(css.root, rootClassName, className);

  const showSection =
    (!queryListingsInProgress && !currentUser) ||
    (!queryListingsInProgress && currentUser && listings.length === 0);

  return showSection ? (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionJoin.title" />
      </h2>
      <div className={css.content}>
        <WatchlistIcon className={css.icon} />
        <h3 className={css.heading}>
          <FormattedMessage id="SectionJoin.heading" />
        </h3>
        <p className={css.subHeading}>
          <FormattedMessage id="SectionJoin.subHeading" />
        </p>
        {currentUser ? null : (
          <NamedLink name="RoleSelectionPage" className={css.button}>
            <FormattedMessage id="SectionJoin.buttonText" />
          </NamedLink>
        )}
      </div>
    </div>
  ) : null;
};

SectionJoin.defaultProps = {
  rootClassName: null,
  className: null,

  queryListingsInProgress: false,
};

SectionJoin.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  listings: arrayOf(propTypes.listing),
  queryListingsInProgress: bool.isRequired,
};

export default SectionJoin;
