import React from 'react';
import { oneOfType, object, bool, func, shape, string, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { propTypes } from '../../util/types';
import { Form, NamedLink, EditWizardButton, PreviewListingButton } from '../../components';

import EditExperienceTeamField from './EditExperienceTeamField/EditExperienceTeamField';
import css from './EditExperienceTeamForm.module.css';

const EditExperienceTeamFormComponent = props => {
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          className,
          intl,
          disabled,
          ready,
          handleSubmit,
          invalid,
          pristine,
          values,
          saveActionMsg,
          updated,
          updateInProgress,
          showInvitesInProgress,
          showInvitesError,
          resendInviteInProgress,
          resendInviteError,
          revokeInviteInProgress,
          revokeInviteError,
          removeInviteInProgress,
          removeInviteError,
          resendId,
          revokeId,
          removeId,
          onResendInvite,
          onRevokeInvite,
          onRemoveInvite,
          onGetPrevPathParams,
          fetchErrors,
          currentUser,
          listing,
          panelTitle,
          setInviteModalOpen,
        } = formRenderProps;

        const prevPathParams = onGetPrevPathParams();

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditExperienceTeamForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditExperienceTeamForm.showListingFailed" />
          </p>
        ) : null;

        const teamFromValues = values.team;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.content}>
              <div className={css.contentWrapper}>
                <h1 className={css.title}>{panelTitle}</h1>
                {errorMessageUpdateListing}
                {errorMessageShowListing}

                <EditExperienceTeamField
                  intl={intl}
                  currentUser={currentUser}
                  listing={listing}
                  teamFromValues={teamFromValues}
                  showInvitesInProgress={showInvitesInProgress}
                  showInvitesError={showInvitesError}
                  resendInviteInProgress={resendInviteInProgress}
                  resendInviteError={resendInviteError}
                  revokeInviteInProgress={revokeInviteInProgress}
                  revokeInviteError={revokeInviteError}
                  removeInviteInProgress={removeInviteInProgress}
                  removeInviteError={removeInviteError}
                  resendId={resendId}
                  revokeId={revokeId}
                  removeId={removeId}
                  setInviteModalOpen={setInviteModalOpen}
                  onResendInvite={onResendInvite}
                  onRevokeInvite={onRevokeInvite}
                  onRemoveInvite={onRemoveInvite}
                />
              </div>
            </div>
            <div className={css.submitButtonRoot}>
              <NamedLink
                className={css.backButton}
                name="EditExperiencePage"
                params={prevPathParams}
              >
                <FormattedMessage id="EditExperienceTeamForm.backButton" />
              </NamedLink>
              <div className={css.submitAndPreviewButton}>
                <PreviewListingButton listing={listing} />
                <EditWizardButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </EditWizardButton>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditExperienceTeamFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  panelTitle: null,
  resendId: null,
  revokeId: null,
  removeId: null,
};

EditExperienceTeamFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onRevokeInvite: func.isRequired,
  onRemoveInvite: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  resendInviteInProgress: bool.isRequired,
  resendInviteError: propTypes.error,
  revokeInviteInProgress: bool.isRequired,
  revokeInviteError: propTypes.error,
  removeInviteInProgress: bool.isRequired,
  removeInviteError: propTypes.error,
  revokeId: string,
  removeId: string,
  panelTitle: oneOfType([object, node]),
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditExperienceTeamFormComponent);
