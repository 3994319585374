import React from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { FieldRadioButton, ValidationError } from '../../components';

import css from './FieldRadioButtonGroup.module.css';

const FieldRadioButtonRenderer = props => {
  const {
    className,
    rootClassName,
    itemClassName,
    checkedClassName,
    label,
    twoColumns,
    threeColumns,
    id,
    input,
    options,
    meta,
  } = props;

  const { name } = input;

  const classes = classNames(rootClassName || css.root, className);
  const listClasses = classNames(css.list, {
    [css.twoColumns]: twoColumns,
    [css.threeColumns]: threeColumns,
  });

  return (
    <fieldset className={classes}>
      {label ? <legend>{label}</legend> : null}
      <ul className={listClasses}>
        {options.map(option => {
          const fieldId = `${id}.${option.key}`;
          const itemClasses = classNames(css.item, itemClassName, {
            [css.itemDisabled]: option.disabled,
          });

          return (
            <li key={fieldId} className={itemClasses}>
              <Field name={name} type="radio" value={option.key}>
                {({ input }) => (
                  <FieldRadioButton
                    {...input}
                    checkedClassName={checkedClassName}
                    id={fieldId}
                    label={option.label}
                    disabled={option.disabled}
                  />
                )}
              </Field>
            </li>
          );
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset>
  );
};

FieldRadioButtonRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  itemClassName: null,
  checkedClassName: null,
  label: null,
  twoColumns: false,
  threeColumns: false,
};

FieldRadioButtonRenderer.propTypes = {
  rootClassName: string,
  className: string,
  itemClassName: string,
  checkedClassName: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
      disabled: bool,
    })
  ).isRequired,
  twoColumns: bool,
  threeColumns: bool,
  meta: shape({}),
};

const FieldRadioButtonGroup = props => <Field component={FieldRadioButtonRenderer} {...props} />;

FieldRadioButtonGroup.propTypes = {
  name: string.isRequired,
};

export default FieldRadioButtonGroup;
