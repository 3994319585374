import React from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';

// Import shared components
import { ListingLink } from '../../components';
import { EditExperienceContentForm } from '../../forms';

// Import modules from this directory
import css from './EditExperienceContentPanel.module.css';

const getInitialValues = params => {
  const { images, videos } = params;
  return { images, videos };
};

// This function denormalizes the video data by removing the 'file' and 'videoId' properties
// and retaining only the attributes that should be saved within a listing.
// The resulting array of videos is then sent to the API to be added to the publicData of a listing.
const denormaliseVideos = videos =>
  videos ? videos.map(({ file, videoId, ...video }) => video) : [];

const EditExperienceContentPanel = props => {
  const {
    className,
    rootClassName,
    errors,
    disabled,
    ready,
    listing,
    onImageUpload,
    onVideoUpload,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    promoteImageInProgress,
    promoteImageError,
    showListingInProgress,
    onSubmit,
    onRemoveImage,
    onRemoveVideo,
    onPromoteImage,
    onGetPrevPathParams,
  } = props;

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);

  const isPublished = listing.id && listing.attributes.state !== LISTING_STATE_DRAFT;

  // images title
  const unpublishedPanelTitleImages = (
    <FormattedMessage id="EditExperienceContentPanel.createListingTitle" />
  );
  const publishedPanelTitleImages = (
    <FormattedMessage
      id="EditExperienceContentPanel.title"
      values={{
        listingTitle: <ListingLink className={css.listingLink} listing={listing} isExperience />,
      }}
    />
  );

  // videos title
  const unpublishedPanelTitleVideos = (
    <FormattedMessage id="EditExperienceContentPanel.createListingVideosTitle" />
  );
  const publishedPanelTitleVideos = (
    <FormattedMessage
      id="EditExperienceContentPanel.videosTitle"
      values={{
        listingTitle: <ListingLink className={css.listingLink} listing={listing} isExperience />,
      }}
    />
  );

  const panelTitleImages = isPublished ? publishedPanelTitleImages : unpublishedPanelTitleImages;
  const panelTitleVideos = isPublished ? publishedPanelTitleVideos : unpublishedPanelTitleVideos;

  const panelTitle = {
    images: panelTitleImages,
    videos: panelTitleVideos,
  };

  return (
    <div className={classes}>
      <EditExperienceContentForm
        className={css.form}
        disabled={disabled}
        ready={ready}
        fetchErrors={errors}
        panelTitle={panelTitle}
        initialValues={getInitialValues(props)}
        onImageUpload={onImageUpload}
        onVideoUpload={onVideoUpload}
        onSubmit={values => {
          const { addImage, addVideo, videos, ...updateValues } = values;
          const updatedValues = {
            ...updateValues,
            publicData: {
              videos: denormaliseVideos(videos),
            },
          };
          onSubmit(updatedValues);
        }}
        onRemoveImage={onRemoveImage}
        onRemoveVideo={onRemoveVideo}
        onPromoteImage={onPromoteImage}
        onGetPrevPathParams={onGetPrevPathParams}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        promoteImageInProgress={promoteImageInProgress}
        promoteImageError={promoteImageError}
        showListingInProgress={showListingInProgress}
        listing={listing}
      />
    </div>
  );
};

EditExperienceContentPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
  promoteImageInProgress: false,
  promoteImageError: null,
  showListingInProgress: false,
};

EditExperienceContentPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,
  updateInProgress: bool.isRequired,
  promoteImageInProgress: bool.isRequired,
  promoteImageError: propTypes.error,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onVideoUpload: func.isRequired,
  onSubmit: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  showListingInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  onRemoveVideo: func.isRequired,
  onPromoteImage: func.isRequired,
  onGetPrevPathParams: func.isRequired,
};

export default EditExperienceContentPanel;
