import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditExperienceMembershipForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';
import { v4 as generateUUID } from 'uuid';
import classNames from 'classnames';

import css from './EditExperienceMembershipPanel.module.css';

const { Money } = sdkTypes;

const EditExperienceMembershipPanel = props => {
  const {
    className,
    rootClassName,
    currentUser,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    payoutEnabled,
    onOpenPayoutDetailsModal,
    onGetPrevPathParams,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, publicData } = currentListing.attributes;

  const isCurrentUserAuthor = currentUser.id.uuid === currentListing.author.id.uuid;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditExperienceMembershipPanel.title"
      values={{
        listingTitle: (
          <ListingLink className={css.listingLink} listing={listing} isExperience>
            {title}
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditExperienceMembershipPanel.createListingTitle" />
  );

  const getMembershipInitialValues = () => {
    const membershipsFromPublicData = publicData?.memberships || [];
    return membershipsFromPublicData.map(m => ({
      ...m,
      price: new Money(m.price.amount, m.price.currency),
      perks: m.perks ? [...m.perks, 'members-only-live-event'] : ['members-only-live-event'],
    }));
  };

  const getMembershipSubmitValues = membershipsFromValues => {
    return membershipsFromValues.map(m => ({
      ...m,
      id: m.id ? m.id : generateUUID(),
      price: { amount: m.price.amount, currency: m.price.currency },
      perks: m.perks ? [...m.perks, 'members-only-live-event'] : ['members-only-live-event'],
    }));
  };

  return (
    <div className={classes}>
      <EditExperienceMembershipForm
        className={css.form}
        initialValues={{
          memberships: getMembershipInitialValues(),
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { memberships } = values;
          const updateValues = {
            publicData: {
              memberships: getMembershipSubmitValues(memberships),
              completedMembershipTab: true,
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        panelTitle={panelTitle}
        listing={currentListing}
        isCurrentUserAuthor={isCurrentUserAuthor}
        payoutEnabled={payoutEnabled}
        onOpenPayoutDetailsModal={onOpenPayoutDetailsModal}
        onGetPrevPathParams={onGetPrevPathParams}
      />
    </div>
  );
};

EditExperienceMembershipPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditExperienceMembershipPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditExperienceMembershipPanel;
