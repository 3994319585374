import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { integrationAPI } from '../../util/api';
import { TRANSITIONS, TRANSITION_CONFIRM_PAYMENT } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchSelectedOrganization } from '../../ducks/organizations.duck';
import { denormalisedResponseEntities } from '../../util/data';

const INBOX_PAGE_SIZE = 10;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchAndFilterTransactionsWithIntegrationAPI = (
  queryParams,
  selectedOrganization
) => async (dispatch, getState, sdk) => {
  try {
    const apiResponse = await integrationAPI.transactions.query({
      organizationId: selectedOrganization.id,
    });
    const totalPages = apiResponse.data.data.meta.totalPages;

    const allDenormalisedTransactions = [];

    // Fetch and filter transactions concurrently using Promise.all
    await Promise.all(
      [...Array(totalPages).keys()].map(async i => {
        const response = await integrationAPI.transactions.query({
          organizationId: selectedOrganization.id,
          include: [
            'provider',
            'provider.profileImage',
            'customer',
            'customer.profileImage',
            'booking',
            'listing',
            'messages',
          ],
          'fields.transaction': [
            'lastTransition',
            'lastTransitionedAt',
            'transitions',
            'payinTotal',
            'payoutTotal',
            'metadata',
          ],
          'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
          page: i + 1,
        });

        dispatch(addMarketplaceEntities(response.data));
        const denormaliseResponseEntities = denormalisedResponseEntities(response.data);

        allDenormalisedTransactions.push(...denormaliseResponseEntities);
      })
    );

    // Filter the transactions based on your criteria
    const filteredDenormalisedTransactions = allDenormalisedTransactions.filter(t =>
      t?.attributes?.lastTransition && queryParams.lastTransitions === TRANSITION_CONFIRM_PAYMENT
        ? t?.listing?.attributes?.publicData?.organizationId === queryParams.organizationId &&
          t?.attributes?.lastTransition === queryParams.lastTransitions
        : t?.listing?.attributes?.publicData?.organizationId === queryParams.organizationId
    );

    // Calculate pagination dynamically based on filtered results
    const perPage = queryParams.perPage || INBOX_PAGE_SIZE;
    const page = queryParams.page || 1;
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;

    const filteredTransactionEntities = filteredDenormalisedTransactions
      .slice(startIndex, endIndex)
      .map(filteredTx => {
        // Find the corresponding transaction entity from the original API response
        return apiResponse.data.data.data.find(tx => tx.id.uuid === filteredTx.id.uuid);
      });

    const apiResponseData = apiResponse.data;
    const filteredResponse = {
      ...apiResponse,
      data: {
        ...apiResponseData,
        data: filteredTransactionEntities,
        meta: {
          totalItems: filteredDenormalisedTransactions.length,
          totalPages: Math.ceil(filteredDenormalisedTransactions.length / perPage),
          page,
          perPage,
        },
      },
    };

    return Promise.resolve(filteredResponse);
  } catch (error) {
    Promise.reject(error);
  }
};

export const fetchAndFilterTransactions = queryParams => async (dispatch, getState, sdk) => {
  try {
    const apiResponse = await sdk.transactions.query();
    const totalPages = apiResponse.data.meta.totalPages;

    const allDenormalisedTransactions = [];

    // Fetch and filter transactions concurrently using Promise.all
    await Promise.all(
      [...Array(totalPages).keys()].map(async i => {
        const response = await sdk.transactions.query({
          only: 'sale',
          lastTransitions: queryParams.lastTransitions,
          include: [
            'provider',
            'provider.profileImage',
            'customer',
            'customer.profileImage',
            'booking',
            'listing',
            'messages',
          ],
          'fields.transaction': [
            'lastTransition',
            'lastTransitionedAt',
            'transitions',
            'payinTotal',
            'payoutTotal',
            'metadata',
          ],
          'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
          page: i + 1,
        });

        dispatch(addMarketplaceEntities(response));
        const denormaliseResponseEntities = denormalisedResponseEntities(response);

        allDenormalisedTransactions.push(...denormaliseResponseEntities);
      })
    );

    // Filter the transactions based on your criteria (replace this with your filtering logic)
    const filteredDenormalisedTransactions = allDenormalisedTransactions.filter(
      t => t?.listing?.attributes?.publicData?.organizationId === queryParams.organizationId
    );

    // Calculate pagination dynamically based on filtered results
    const perPage = queryParams.perPage || 10;
    const page = queryParams.page || 1;
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;

    const filteredTransactionEntities = filteredDenormalisedTransactions
      .slice(startIndex, endIndex)
      .map(filteredTx => {
        // Find the corresponding transaction entity from the original API response
        return apiResponse.data.data.find(tx => tx.id.uuid === filteredTx.id.uuid);
      });

    const filteredResponse = {
      ...apiResponse,
      data: {
        ...apiResponse.data,
        data: filteredTransactionEntities,
        meta: {
          totalItems: filteredDenormalisedTransactions.length,
          totalPages: Math.ceil(filteredDenormalisedTransactions.length / perPage),
          page,
          perPage,
        },
      },
    };

    return Promise.resolve(filteredResponse);
  } catch (error) {
    Promise.reject(error);
  }
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  try {
    const selectedOrganization = await dispatch(fetchSelectedOrganization());
    const selectedOrganizationId = selectedOrganization ? selectedOrganization.id : '';

    const queryParams = {
      organizationId: selectedOrganizationId,
      lastTransitions: TRANSITIONS,
      page,
      perPage: INBOX_PAGE_SIZE,
    };

    const filteredTransactionsResponse = selectedOrganization?.invited
      ? await dispatch(
          fetchAndFilterTransactionsWithIntegrationAPI(queryParams, selectedOrganization)
        )
      : await dispatch(fetchAndFilterTransactions(queryParams));

    dispatch(addMarketplaceEntities(filteredTransactionsResponse));
    dispatch(fetchOrdersOrSalesSuccess(filteredTransactionsResponse));

    return filteredTransactionsResponse;
  } catch (error) {
    dispatch(fetchOrdersOrSalesError(storableError(error)));
  }
};
