import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { util as sdkUtil } from '../../util/sdkLoader';
import { queryRecentlyViewedListings } from '../../ducks/user.duck';
import config from '../../config';
import { integrationAPI } from '../../util/api';

const LANDING_PAGE_LISTINGS_COUNT = 24;
const LANDING_PAGE_EXPERIENCES_COUNT = 9;

// ================ Action types ================ //

export const QUERY_LISTINGS_REQUEST = 'app/LandingPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/LandingPage/QUERY_LISTINGS_ERROR';

export const QUERY_EXPERIENCES_REQUEST = 'app/LandingPage/QUERY_EXPERIENCES_REQUEST';
export const QUERY_EXPERIENCES_SUCCESS = 'app/LandingPage/QUERY_EXPERIENCES_SUCCESS';
export const QUERY_EXPERIENCES_ERROR = 'app/LandingPage/QUERY_EXPERIENCES_ERROR';

export const QUERY_WATCHLIST_LISTINGS_REQUEST = 'app/LandingPage/QUERY_WATCHLIST_LISTINGS_REQUEST';
export const QUERY_WATCHLIST_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_WATCHLIST_LISTINGS_SUCCESS';
export const QUERY_WATCHLIST_LISTINGS_ERROR = 'app/LandingPage/QUERY_WATCHLIST_LISTINGS_ERROR';

export const QUERY_LISTING_VIDEOS_REQUEST = 'app/LandingPage/QUERY_LISTING_VIDEOS_REQUEST';
export const QUERY_LISTING_VIDEOS_SUCCESS = 'app/LandingPage/QUERY_LISTING_VIDEOS_SUCCESS';
export const QUERY_LISTING_VIDEOS_ERROR = 'app/LandingPage/QUERY_LISTING_VIDEOS_ERROR';

// ================ Reducer ================ //

const initialState = {
  listingIds: [],
  queryListingsInProgress: false,
  queryListingsError: null,
  experienceIds: [],
  queryExperiencesInProgress: false,
  queryExperiencesError: null,
  watchlistListingIds: [],
  queryWatchlistListings: false,
  queryWatchlistListingsError: null,
  listingVideos: [],
  queryListingVideosInProgress: false,
  queryListingVideosError: null,
};

const resultIds = data => data.data.map(l => l.id);

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        queryListingsInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        queryListingsInProgress: false,
        queryListingsError: null,
        listingIds: resultIds(payload.data),
      };
    case QUERY_LISTINGS_ERROR:
      return { ...state, queryListingsInProgress: false, queryListingsError: payload };

    case QUERY_EXPERIENCES_REQUEST:
      return {
        ...state,
        queryExperiencesInProgress: true,
        queryExperiencesError: null,
      };
    case QUERY_EXPERIENCES_SUCCESS:
      return {
        ...state,
        queryExperiencesInProgress: false,
        queryExperiencesError: null,
        experienceIds: resultIds(payload.data),
      };
    case QUERY_EXPERIENCES_ERROR:
      return { ...state, queryExperiencesInProgress: false, queryExperiencesError: payload };

    case QUERY_WATCHLIST_LISTINGS_REQUEST:
      return {
        ...state,
        queryWatchlistListingsInProgress: true,
        querWatchlistlistingssError: null,
      };
    case QUERY_WATCHLIST_LISTINGS_SUCCESS:
      return {
        ...state,
        queryWatchlistListingsInProgress: false,
        queryWatchlistListingsError: null,
        watchlistListingIds: resultIds(payload.data),
      };
    case QUERY_WATCHLIST_LISTINGS_ERROR:
      return {
        ...state,
        queryWatchlistListingsInProgress: false,
        queryWatchlistListingsError: payload,
      };

    case QUERY_LISTING_VIDEOS_REQUEST:
      return {
        ...state,
        queryListingVideosInProgress: true,
        queryListingVideosError: null,
      };
    case QUERY_LISTING_VIDEOS_SUCCESS:
      return {
        ...state,
        queryListingVideosInProgress: false,
        queryListingVideosError: null,
        listingVideos: payload.data,
      };
    case QUERY_LISTING_VIDEOS_ERROR:
      return { ...state, queryListingVideosInProgress: false, queryListingVideosError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryListingsRequest = () => ({
  type: QUERY_LISTINGS_REQUEST,
});

export const queryListingsSuccess = response => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryExperiencesRequest = () => ({
  type: QUERY_EXPERIENCES_REQUEST,
});

export const queryExperiencesSuccess = response => ({
  type: QUERY_EXPERIENCES_SUCCESS,
  payload: { data: response.data },
});

export const queryExperiencesError = e => ({
  type: QUERY_EXPERIENCES_ERROR,
  error: true,
  payload: e,
});

export const queryWatchlistListingsRequest = () => ({
  type: QUERY_WATCHLIST_LISTINGS_REQUEST,
});

export const queryWatchlistListingsSuccess = response => ({
  type: QUERY_WATCHLIST_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryWatchlistListingsError = e => ({
  type: QUERY_WATCHLIST_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryListingVideosRequest = () => ({
  type: QUERY_LISTING_VIDEOS_REQUEST,
});

export const queryListingVideosSuccess = response => ({
  type: QUERY_LISTING_VIDEOS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingVideosError = e => ({
  type: QUERY_LISTING_VIDEOS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryListings = () => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());
  return sdk.listings
    .query({
      pub_type: config.listingTypes['show'],
      perPage: LANDING_PAGE_LISTINGS_COUNT,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',
        'variants.hero-scale',
        'variants.hero-crop',
        'variants.scaled-large',

        // custom variants
        'variants.portrait-crop',
        'variants.portrait-crop2x',
        'variants.portrait-crop4x',
      ],
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 280,
        h: 392,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 560,
        h: 784,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop4x': sdkUtil.objectQueryString({
        w: 560,
        h: 784,
        fit: 'crop',
      }),
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(response));

      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryExperiences = () => (dispatch, getState, sdk) => {
  dispatch(queryExperiencesRequest());
  return sdk.listings
    .query({
      pub_type: config.listingTypes['experience'],
      perPage: LANDING_PAGE_EXPERIENCES_COUNT,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',
        'variants.hero-scale',
        'variants.hero-crop',
        'variants.scaled-large',

        // custom variants
        'variants.portrait-crop',
        'variants.portrait-crop2x',
        'variants.portrait-crop4x',
      ],
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 250,
        h: 300,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 280,
        h: 392,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop4x': sdkUtil.objectQueryString({
        w: 560,
        h: 784,
        fit: 'crop',
      }),
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryExperiencesSuccess(response));

      return response;
    })
    .catch(e => dispatch(queryExperiencesError(storableError(e))));
};

export const queryWatchlistListings = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(queryWatchlistListingsRequest());

    let { currentUser } = getState().user;

    if (!currentUser) {
      const currentUserResponse = await sdk.currentUser.show();
      currentUser = currentUserResponse?.data?.data;
    }

    const watchlistIds = currentUser?.attributes?.profile?.publicData?.watchlistIds || [];

    const response = await sdk.listings.query({
      ids: watchlistIds,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',
        'variants.hero-scale',
        'variants.hero-crop',
        'variants.scaled-large',

        // custom variants
        'variants.portrait-crop',
        'variants.portrait-crop2x',
        'variants.portrait-crop4x',
      ],
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 250,
        h: 300,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 280,
        h: 392,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop4x': sdkUtil.objectQueryString({
        w: 560,
        h: 784,
        fit: 'crop',
      }),
    });

    dispatch(addMarketplaceEntities(response));
    dispatch(queryWatchlistListingsSuccess(response));

    return response;
  } catch (error) {
    dispatch(queryWatchlistListingsError(storableError(error)));
  }
};

export const queryListingVideos = () => (dispatch, getState, sdk) => {
  dispatch(queryListingVideosRequest());

  return integrationAPI.listings
    .queryVideos({ include: ['listing', 'author'], sort: 'random' })
    .then(response => {
      dispatch(queryListingVideosSuccess(response));
    })
    .catch(e => dispatch(queryListingVideosError(storableError(e))));
};

export const loadData = () => async (dispatch, getState, sdk) => {
  return Promise.all([
    dispatch(queryListings()),
    dispatch(queryExperiences()),
    dispatch(queryWatchlistListings()),
    dispatch(queryListingVideos()),
    dispatch(queryRecentlyViewedListings()),
  ]);
};
